export const navigationActions = {
  selectArea,
  selectTab,
  selectSection,
  setSelectedSectionIndex,
  setShowNewSectionPicker
};

function selectArea(areaIndex) {
  return dispatch => {
    return dispatch({ type: "SELECT_AREA", payload: areaIndex });
  };
}

function selectTab(tabIndex) {
  return dispatch => {
    return dispatch({ type: "SELECT_TAB", payload: tabIndex });
  };
}

function selectSection(sectionIndex) {
  return dispatch => {
    return dispatch({ type: "SELECT_SECTION", payload: sectionIndex });
  };
}

function setSelectedSectionIndex(feature) {
  return dispatch => {
    return dispatch({ type: "SET_SELECTED_SECTION_INDEX", payload: feature });
  };
}

function setShowNewSectionPicker(value) {
  return dispatch => {
    return dispatch({ type: "SET_SHOW_NEW_SECTION_PICKER", payload: value });
  };
}