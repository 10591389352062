import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 30,
        padding: '0 20px'
    },
    label: {
        marginBottom: 2
    },
    selector: {
        border: '1px solid #ccc',
        borderRadius: 3,
        padding: '7px 5px'
    }
}));

export default function ProductVariantsArea({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <p className={classes.label}>
            Escolha a variação:
        </p>

        <div className={classes.selector}>
            Selecione
        </div>
    </div>
  );
};