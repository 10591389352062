import React from "react";
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { PLATFORMS } from "../../Editor/_helpers/constants";

const useStyles = makeStyles(() => ({
  root: {
    padding: 20
  },
  oldPrice: props =>({
    color: props.options.oldPriceColor,
    fontSize: 16,
    margin: 0,
    textDecorationLine: "line-through"
  }),
  price: props =>({
    color: props.options.priceColor,
    fontSize: 30,
    margin: 0,
    marginLeft: -2
  }),
  paymentOption: props =>({
    color: props.options.installmentsColor,
    fontSize: 14,
    margin: 0
  })
}));

export default function PriceArea({ colors, data, product }) {
  const classes = useStyles(colors);
  const platform = useSelector(state => state.StoreReducer.platform);

  return (
    <div className={classes.root}>
        {_.get(product, 'hasPromotionalPrice', true) && data.options.showOldPrice &&
          <div className={classes.firstPriceRow}>
              <p className={classes.oldPrice}>
                {_.get(product, 'displayOldPrice', 'R$ 100,00')}
              </p>
          </div>
        }

        <div className={classes.secondPriceRow}>
            <div>
                <p className={classes.price}>
                  {_.get(product, 'displayPrice', 'R$ 90,00')}
                </p>

                {platform === PLATFORMS.TRAY && data.options.showInstallments && (
                    <p className={classes.paymentOption}>
                      {_.get(product, 'paymentOption', 'R$ 80,00 à vista com desconto ou 10x de R$ 10,00 Sem juros')}
                    </p>
                )}
            </div>
        </div>
    </div>
  );
};