import qs from "qs";
import { apiFetcher } from "./apiFetcher";

export const categoriesService = {
  getCategories,
  getAllCategories
};

function getCategories(filters) {
  const url = "/categories?per_page=200&" + qs.stringify(filters);
  
  return apiFetcher(url, { method: "GET" });
}

function getAllCategories(page = 1, categoriesArray = []) {
  return getCategories({ page })
    .then(response => {
      categoriesArray = categoriesArray.concat(response);
      page += 1;
      return getAllCategories(page, categoriesArray);
    })
    .catch(() => {
      return categoriesArray;
    })
}