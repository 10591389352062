import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';
import ContactCard from './ContactCard';

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        cursor: 'grab',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    contactsWrapper: {
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: 3,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 15,
      marginTop: 15,
      width: '92%'
    }
}));
  

export default function LoginScreen() {
  const selectedArea = useSelector(state => getSelectedArea(state));
  const data = selectedArea.sections.find(s => s.key === 'contactScreenSettings');
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {!_.isEmpty(data.content) &&
          <div className={classes.contactsWrapper}>
            {data.content.map((contact, index) => {
              return <ContactCard contact={contact} key={`cc-${index}`}/>
            })}
          </div>
        }
    </div>
  );
}
