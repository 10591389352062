import React from 'react';

export default function FlashText({ data }) {
  return (
      <hr 
        style={{ 
            borderColor: data.options.color, 
            display: data.isVisible ? 'block' : 'none',
            margin: '10px 5px',
            width: '100%'
        }} 
    />
  );
}