import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';
import { formatFileName } from '../utils/format';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none'
  },
  uploadCard: {
    border: '1px solid rgba(0, 0, 0, 0.12);'
  },
  loadingWrapper: {
    padding: '20px 0'
  },
  imageWrapper: {
    alignItems: 'center',
    backgroundColor: '#ededed',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    display: 'flex',
    justifyContent: 'center',
    width: 305
  },
  image: {
    height: 'auto',
    width: '100%'
  },
  button: {
    backgroundColor: '#FCFCFD',
    borderRadius: 0,
    width: '100%'
  }
}));

function ImageUploader(props) {
  const fileInputRef= useRef(null);

  const classes = useStyles({ imageWidth: props.imageWidth });
  const platformId = useSelector(state => state.StoreReducer.platform);
  const storeId = useSelector(state => state.ThemeReducer.storeId);

  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(props.imageUrl);

  useEffect(() => {
    setUploadedImageUrl(props.imageUrl);
  }, [props.imageUrl]);

  function handleUploadChange(e)
  {
      const file = e.target.files[0];
      if (!file) {
          return;
      }

      let img = new Image();
      const _URL = window.URL || window.webkitURL;

      img.src = _URL.createObjectURL(file);
      img.onload = function() {

        let imageData = new FormData();
        imageData.append("image", file, formatFileName(file.name));

        mobilizeiStoresService.uploadFile(platformId, storeId, imageData)
        .then((response) => {
          onUploadSuccess(response?.fileUrl, img.height, img.width)
        })
        .finally(() => {
          setIsLoading(false);
        })
      }
  }

  function onUploadSuccess(downloadURL, height, width) {
      setIsLoading(false);
      setUploadedImageUrl(downloadURL);

      props.onUploadSuccess({
        height,
        url: downloadURL,
        width
      });
  }

  return (
    <>
        <input
          accept="image/*"
          className={classes.hidden}
          onChange={handleUploadChange}
          ref={fileInputRef}
          type="file"
        />

        <p className={classes.imageTitle}>{props.label}</p>

        <div className={classes.uploadCard}>
            <div className={classes.imageWrapper}>
              {isLoading ?
                <div className={classes.loadingWrapper}>
                  <CircularProgress color="primary"/> 
                </div> :
                <img
                  alt=""
                  className={classes.image}
                  src={
                    uploadedImageUrl ?
                    uploadedImageUrl :
                    require('../_assets/images/image_placeholder_350_350.png')
                  }
                />
              }
            </div>

            <Button 
              className={classes.button} 
              disableElevation 
              onClick={() => fileInputRef.current.click()}
              startIcon={<FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />}
              variant="contained" 
            >
                trocar imagem
            </Button>
        </div>
    </>
  );
}

export default ImageUploader;