import React, { useEffect, useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { navigationActions } from '../_actions/navigation.actions';
import _ from 'lodash';
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Tour steps
const TOUR_STEPS = [
    {
        placement: 'center',
        target: 'body',
        content: "Seja bem-vindo(a) ao editor de temas do seu aplicativo! Vamos guiar você pelas partes principais do editor.",
        disableBeacon: true
    },
    {
        target: "[class*='previewerWrapper']",
        content: "No visualizador você pode acompanhar em tempo real como está ficando o layout do seu aplicativo.",
        placement: 'left'
    },
    {
        target: ".screen-selector",
        content: "Aqui você pode selecionar qual tela do aplicativo você deseja editar."
    },
    {
        target: ".edit-screen-tab",
        content: 'Na aba "Editar tela" do menu lateral você irá encontrar as opções de edição disponíveis para a tela selecionada.'
    },
    {
        target: ".area-sections-list",
        content: "Clicando em cada uma das opções você terá acesso aos seus detalhes e poderá configurá-las individualmente.",
        placement: 'right'
    },
    {
        target: "[data-icon='eye']",
        content: "Clicando neste ícone você pode escolher se deseja exibir ou não determinado elemento."
    },
    {
        target: "[data-icon='grip-vertical']",
        content: "Sempre que este ícone estiver disponível você pode clicar em cima dele, segurar e arrastar para reeordenar os elementos da lista."
    },
    {
        target: ".add-new-section-button",
        content: "É possível adicionar novas seções (como banners, lista de produtos, entre outros) à tela inicial clicando neste botão."
    },
    {
        target: ".configure-theme-tab",
        content: 'Na aba "Configurar tema" você irá encontrar opções de configuração geral do seu tema.',
    },
    {
        target: ".settings-sections-list",
        content: "Menus, cards de produtos, cores e outros elementos gerais do seu aplicativo estão disponíveis para serem configurados aqui.",
        placement: 'right'
    },
    {
        target: ".save-button",
        content: "Depois de configurar o seu tema não se esqueça de salvá-lo! Para ver as modificações no seu celular você deverá fechar e abrir o seu aplicativo para que as novas configurações seja carregadas."
    }
];

// Tour component
const Tour = () => {
    const dispatch = useDispatch();
    const [run, setRun] = useState(false);

    useEffect(() => {
        const isTourCompleted = window.localStorage.getItem('tour_completed');
        if(!isTourCompleted) {
            setRun(true);
        }
    }, []);

    const handleJoyrideCallback = data => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    
        if (finishedStatuses.includes(status)) {
            window.localStorage.setItem('tour_completed', true);
            setRun(false);
        }

        const target = _.get(data, 'step.target', null);

        if(target === '.configure-theme-tab' || target === '.settings-sections-list') {
            dispatch(navigationActions.selectTab(1));
        } else {
            dispatch(navigationActions.selectArea(0));
            dispatch(navigationActions.selectTab(0));
        }
    };

    return (
        <>
            <IconButton edge="end" onClick={() => setRun(true)}>
                <FontAwesomeIcon icon={['far', 'question-circle']} fixedWidth />
            </IconButton>

            <Joyride 
                callback={handleJoyrideCallback}
                continuous={true} 
                disableOverlayClose={true}
                locale={{
                    back: "Voltar",
                    close: "Fechar",
                    last: "Sair",
                    next: "Próximo",
                    skip: "Sair"
                }}
                run={run}
                showClo
                showSkipButton={true}
                steps={TOUR_STEPS} 
                styles={{
                    buttonBack: {
                        color: '#37c961'
                    },
                    buttonClose: {
                        display: 'none',
                    },
                    buttonNext: {
                        backgroundColor: '#37c961'
                    },
                    options: {
                        zIndex: 999999999999999,
                    }
                }}
            />
        </>
    );
};

export default Tour;