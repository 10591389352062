import { store } from "../../../index";
import config from '../../_helpers/config'

export const apiFetcher = (path, init, attempt = 1, returnHeaders = false) => {
  const at = store.getState().UserReducer.user.store.accessToken;
  const storeId = store.getState().UserReducer.user.store.id;
  let options = init;

  let url = `${config.MOBILIZEI_API_BASE_URL}/platforms/nuvemshop/api_forward/${storeId}${path}`;

  let headers = new Headers();
  headers.append('Authentication', at);
  headers.append('User-Agent', 'Mobilizei (suporte@mobilize.com.br)');
  headers.append('Content-Type', 'application/json');
  options['headers'] = headers;

  return fetch(url, init).then(response => {
    if (response.status === 204) {
      return;
    }
    return response.json().then(data => {
      if (!response.ok) {
        if (response.status === 401 && attempt <= 3) {
          return apiFetcher(path, init, attempt + 1);
        }
        const error = data || response.statusText;
        return Promise.reject(error);
      }

      if(returnHeaders) {
        return { data, headers: response.headers }
      } else {
        return data;
      }
    });
  });
};