import { apiFetcher } from "./apiFetcher";

export const mobilizeiUsersService = {
  getSelf,
};

function getSelf() {
  return apiFetcher(
    'users/self', {
    method: "GET",
  }).then(response => response);
}