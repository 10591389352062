import { apiFetcher } from "./apiFetcher";
import qs from "qs";

export const productsService = {
  getProducts,
  getProductById,
  getProductVariants,
  searchProducts
};

function getProducts(filters) {
  const url = `/products?${qs.stringify(filters)}`;

  return apiFetcher(url, { method: "GET" });
}

function getProductById(productId) {
  return apiFetcher(`/products/${productId}`, {
    method: "GET"
  }).then(response => {
    return response.Product;
  });
}

function getProductVariants(productId) {
  return apiFetcher(
    `/variants?limit=50&product_id=${productId}`,
    {
      method: "GET"
    }
  ).then(response => {
    return response.Variants;
  });
}

function searchProducts(filters, query) {
  const url = `/web_api/search?query=${query}&${qs.stringify(filters)}`;

  return apiFetcher(url, { method: "GET" });
}
