import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import NavigationReducer from '../_reducers/navigation.reducer';
import PreviewerNavigationReducer from '../_reducers/previewerNavigation.reducer';
import ThemeReducer from '../_reducers/theme.reducer';
import UserReducer from '../_reducers/user.reducer';
import StoreReducer from '../_reducers/store.reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
        NavigationReducer,
        PreviewerNavigationReducer,
        ThemeReducer,
        UserReducer,
        StoreReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};