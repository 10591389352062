import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import ContactCard from './ContactCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dragIcon: {
    cursor: 'grab',
    position: 'absolute',
    right: 0,
    top: 5,
    '&:active': {
        cursor: 'grabbing'
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'row'
  },
  heading: {
    marginLeft: 10
  },
  icon: {
    fontSize: '1.5rem',
    textAlign: 'center',
    marginRight: 10
  },
  actionIcon: {
      fontSize: 20
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    padding: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    fontSize: 16,
    marginRight: 0,
    order: -1
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0
  },
}))(MuiAccordionDetails);

export default function ContactAccordion({ contact, contactIndex, cardRef, dragIconRef, onChange, onDelete }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const getContactIcon = () => {
    switch(contact.contactType) {
        case 'email':
            return <FontAwesomeIcon icon={['fas', 'envelope']} className={classes.icon} />
        case 'phone':
            if(contact.isWhatsapp) {
              return <FontAwesomeIcon icon={['fab', 'whatsapp']} className={classes.icon} />
            }
            return <FontAwesomeIcon icon={['fas', 'phone-alt']} className={classes.icon} />
        case 'address':
            return <FontAwesomeIcon icon={['fas', 'map-marker-alt']} className={classes.icon} />
        default:
            return null;
    }
}

  return (
    <Accordion expanded={expanded}>
        <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} className={classes.expandIcon} />}
            onClick={() => setExpanded(!expanded)}
            ref={cardRef}
        >
            <div className={classes.title}>
              {getContactIcon()}
              <Typography className={classes.heading}>{contact.title || 'Contato'}</Typography>
            </div>
            <IconButton className={classes.dragIcon} ref={dragIconRef} onDrag={() => setExpanded(false)}>
              <FontAwesomeIcon icon={['fas', 'grip-vertical']} className={classes.actionIcon} fixedWidth />
            </IconButton>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            <ContactCard   
                contactIndex={contactIndex} 
                data={contact}
                onChange={onChange}
                onDelete={onDelete}
            />
        </AccordionDetails>
    </Accordion>
  );
}