import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  imageContainer: props =>({
    backgroundImage: `url(${props.imageUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: props.resizeMode === 'fit' ? 'contain' : 'cover',
    height: props.imageShape === 'portrait' ? 245 : 175.5,
    overflow: "hidden",
    width: 175.5
  })
}));

export default function ImageArea({ images, settings }) {
  const imageUrl = 
    _.isEmpty(images) ? 
    require("../../../Editor/_assets/images/image_placeholder_350_350.png") :
    images[0].imageUrl;

  const classes = useStyles({...settings, imageUrl: imageUrl });

  return (
    <div className={classes.imageContainer} />
  );
}
