import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { themeActions } from '../../../../_actions/theme.actions';
import DragAndDropProvider from '../../../../_helpers/DragAndDropProvider';
import DraggableElement from '../../../../_helpers/DraggableElement';
import SocialNetworkAccordion from './SocialNetworkAccordion';
import update from 'immutability-helper';
import _ from 'lodash';
import { Card, CardContent, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import ColorPicker from '../../../ColorPicker';
import DeleteSectionButton from '../../../DeleteSectionButton';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: 30,
  }
}));

export default function SocialNetworks({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAccordionMove = (dragIndex, hoverIndex) => {
    let socialNetworksCopy = JSON.parse(JSON.stringify(data.content));
    const dragSection = socialNetworksCopy[dragIndex];

    socialNetworksCopy =
      update(socialNetworksCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })

    let newData = { ...data, content: socialNetworksCopy }

    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleSocialNetworkChange = (property, value, socialNetworkIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, `content[${socialNetworkIndex}][${property}]`, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
      <Typography variant="overline" display="block" className={classes.contentTitle}>
          Configurações
      </Typography>
      
      <Card elevation={1} square>
        <CardContent>
          <FormControl fullWidth className={classes.formControl}>
              <TextField 
                label="Título"
                value={data.options.title || ''} 
                onChange={(e) => handleChange('options.title', e.target.value)} 
              />
          </FormControl>

          <FormControl fullWidth className={classes.formControl}>
              <InputLabel>Alinhamento do título</InputLabel>
              <Select
                  native
                  value={data.options.textAlign || ''}
                  onChange={(e) => handleChange('options.textAlign', e.target.value)}
              >
                  <option value='left'>Alinhar à esquerda</option>
                  <option value='center'>Centralizar</option>
                  <option value='right'>Alinhar à direita</option>
              </Select>
          </FormControl>

          <ColorPicker 
            label='Cor do título' 
            initialColor={data.options.titleColor} 
            onChange={(color) => handleChange('options.titleColor', color)} 
          />

          <ColorPicker 
            label='Cor dos ícones' 
            initialColor={data.options.iconColor} 
            onChange={(color) => handleChange('options.iconColor', color)} 
          />
        </CardContent>
      </Card>

      <Typography variant="overline" display="block" className={classes.contentTitle}>
          Conteúdo
      </Typography>
      
      <div>
        <DragAndDropProvider>
          {data.content.map((socialNetwork, index) => (
            <DraggableElement hoverIndex={index} key={`dc-${index}`} onMove={handleAccordionMove}>
              <SocialNetworkAccordion 
                socialNetwork={socialNetwork}
                socialNetworkIndex={index} 
                key={`sna-${index}`} 
                onChange={handleSocialNetworkChange}
              />
            </DraggableElement>
          ))}
        </DragAndDropProvider>
      </div>
    
      {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}