import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import SectionCard from '../../../SectionCard';

const useStyles = makeStyles(() => ({
  tabContent: {
    backgroundColor: '#f9fafb',
    flexGrow: 1,
    paddingTop: 5
  }
}));

export default function SettingsSectionsTab(props) {
  const classes = useStyles(props);
  const settingsSections = useSelector(state => state.ThemeReducer.settings);

  return (
    <div className={classes.tabContent}>
        <List className='settings-sections-list'>
            {settingsSections.map((section, index) => (
                <SectionCard 
                    first={index === 0} 
                    key={`ts-${index}`} 
                    section={section} 
                    sectionIndex={index} 
                />
            ))}
        </List>
    </div>
  );
}