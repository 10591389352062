import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    imageTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 12,
        marginBottom: 0
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
    dragIcon: {
        cursor: 'grab',
        marginTop: 10,
        position: 'absolute',
        right: 0,
        '&:active': {
            cursor: 'grabbing'
        }
    }
}));

export default function ContactCard({ contactIndex, data, onChange, onDelete }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Card elevation={1} square>
                <CardContent>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Tipo de contato</InputLabel>
                        <Select
                            native
                            value={data.contactType || ''}
                            onChange={(e) => onChange('contactType', e.target.value, contactIndex)}
                            label="Tipo de contato"
                        >
                            <option value='email'>Email</option>
                            <option value='phone'>Telefone</option>
                            <option value='address'>Endereço</option>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <TextField
                            label="Título"
                            value={data.title || ''}
                            onChange={(e) => onChange('title', e.target.value, contactIndex)}
                        />
                    </FormControl>

                    {data.contactType === 'email' &&
                        <>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Endereço de email"
                                    value={data.email || ''}
                                    onChange={(e) => onChange('email', e.target.value, contactIndex)}
                                />
                            </FormControl>
                        </>
                    }

                    {data.contactType === 'phone' &&
                        <>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="DDD"
                                    value={data.areaCode || ''}
                                    onChange={(e) => onChange('areaCode', e.target.value, contactIndex)}
                                    type="number"
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Número"
                                    value={data.phoneNumber || ''}
                                    onChange={(e) => { 
                                        // Allow only digits and dash
                                        const re = /^[0-9-\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            onChange('phoneNumber', e.target.value, contactIndex)
                                        }
                                    }}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={_.isNil(data.isWhatsapp) ? false : data.isWhatsapp}
                                            onChange={() => onChange('isWhatsapp', !data.isWhatsapp, contactIndex)}
                                            color="primary"
                                        />
                                    }
                                    label="Este número de telefone possui WhatsApp"
                                />
                            </FormControl>
                        </>
                    }

                    {data.contactType === 'address' &&
                        <>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Endereço"
                                    value={data.address || ''}
                                    onChange={(e) => onChange('address', e.target.value, contactIndex)}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Número"
                                    value={data.addressNumber || ''}
                                    onChange={(e) => onChange('addressNumber', e.target.value, contactIndex)}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Complemento"
                                    value={data.addressComplement || ''}
                                    onChange={(e) => onChange('addressComplement', e.target.value, contactIndex)}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Bairro"
                                    value={data.neighbourhood || ''}
                                    onChange={(e) => onChange('neighbourhood', e.target.value, contactIndex)}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Cidade"
                                    value={data.city || ''}
                                    onChange={(e) => onChange('city', e.target.value, contactIndex)}
                                />
                            </FormControl>

                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Estado"
                                    value={data.state || ''}
                                    onChange={(e) => onChange('state', e.target.value, contactIndex)}
                                />
                            </FormControl>
                        </>
                    }
                </CardContent>

                <CardActions>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => onDelete(contactIndex)}
                        startIcon={<FontAwesomeIcon icon={['fas', 'trash']} />}
                    >
                        Excluir contato
                    </Button>
                </CardActions>
            </Card>

        </div>
    );
}