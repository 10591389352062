import _ from "lodash"

export const createWixFilter = (filtersObj) => {
    return !_.isEmpty(filtersObj) ? JSON.stringify(filtersObj) : null
}

export const createWixPaging = (page, limit) => {
    return { offset: (page-1) * limit, limit: limit }
}

export const createWixSortArray = (sortStringArray) => {
    return JSON.stringify(sortStringArray.map((item) => {
        const sortStringArray = item.split('-')
        const sortField = _.get(sortStringArray, '[0]', '');
        const sortOption = _.get(sortStringArray, '[1]', '');
    
        return { [sortField]: sortOption }
    }))
}

export const createWixQuery = (filters, paging, sortArray) => {
    const query = {
        filter: filters,
        paging: paging,
        sort: sortArray
    }

    return !_.isEmpty(query) ? query : {}
}