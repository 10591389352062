export const storeActions = {
    setCategories,
    setPlatform
};

function setCategories(categories) {
    return dispatch => {
      return dispatch({ type: "SET_CATEGORIES", payload: categories });
    };
}

function setPlatform(platform) {
  return dispatch => {
    return dispatch({ type: "SET_PLATFORM", payload: platform });
  };
}