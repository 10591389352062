import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollContainer from 'react-indiana-drag-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  root: props =>({
    cursor: 'grab',
    display: props.isVisible ? 'block' : 'none',
    marginBottom: 20,
    width: '100%'
  }),
  title: props =>({
    color: props.options.titleColor,
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    marginBottom: 10,
    padding: '10px 5px',
    textAlign: props.options.textAlign
  }),
  contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%'
  },
  content: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
  },
  icon: props =>({
    color: props.options.iconColor,
    fontSize: 28,
    margin: '0 10px'
  })
}));

export default function SocialNetworks({ data }) {
  const classes = useStyles(data);

  return (
    <div className={classes.root}>
        <p className={classes.title}>{data.options.title}</p>

        <div className={classes.contentWrapper}>
            <ScrollContainer className={classes.content}>
                {data.content.map((socialNetwork, index) => {
                    return socialNetwork.isVisible && 
                    <FontAwesomeIcon 
                        className={classes.icon} 
                        fixedWidth
                        icon={[socialNetwork.icon.style, socialNetwork.icon.name]} 
                        key={`sn-${index}`}
                    />
                })}
            </ScrollContainer>
        </div>
    </div>
  );
}