import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ColorPicker from '../../ColorPicker'
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Select, TextField } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import appInfoService from '../../../_helpers/appInfoService';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginTop: theme.spacing(3)
  },
  helperText: {
    marginTop: -8
  }
}));

export default function WishlistButton({ data }) {
  appInfoService.init();

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
      <Typography variant="overline" display="block" className={classes.contentTitle}>
        Configurações
      </Typography>

      <Card elevation={1} square>
        {appInfoService.isUsingProductListWebview ? (
          <CardContent>
            <Typography variant="body2" style={{ textAlign: 'justify' }}>
              O aplicativo está configurado para exibir a lista de produtos do seu site. Por este motivo, a Lista de Desejos não pode ser ativada!
            </Typography>
          </CardContent>
        ) : (
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={_.isNil(data.showWishlist) ? false : data.showWishlist}
                  color="primary"
                  onChange={() => handleChange('showWishlist', !data.showWishlist)}
                />
              }
              label="Exibir botão da lista de desejos"
            />
            <FormHelperText className={classes.helperText}>Esse é o botão que permite adicionar um produto à lista de desejos</FormHelperText>

            {data.showWishlist &&
              <>
                <ColorPicker
                  label='Cor do ícone'
                  initialColor={data.options.iconColor}
                  onChange={(color) => handleChange('options.iconColor', color)}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={_.isNil(data.options.showIconBackground) ? false : data.options.showIconBackground}
                      color="primary"
                      onChange={() => handleChange('options.showIconBackground', !data.options.showIconBackground)}
                    />
                  }
                  label="Exibir fundo no ícone"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={_.isNil(data.options.showIconInProductCard) ? false : data.options.showIconInProductCard}
                      color="primary"
                      onChange={() => handleChange('options.showIconInProductCard', !data.options.showIconInProductCard)}
                    />
                  }
                  label="Exibir ícone nos cards de produtos"
                />

                {!appInfoService.isUsingProducttWebview &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={_.isNil(data.options.showIconInProductScreen) ? false : data.options.showIconInProductScreen}
                        color="primary"
                        onChange={() => handleChange('options.showIconInProductScreen', !data.options.showIconInProductScreen)}
                      />
                    }
                    label="Exibir ícone na tela de produto"
                  />
                }

                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Posição do botão nos cards de produtos</InputLabel>
                  <Select
                    native
                    value={data.options.iconPositionInProductCard || ''}
                    onChange={(e) => handleChange('options.iconPositionInProductCard', e.target.value)}
                    label="Posicão do botão"
                  >
                    <option value='left|bottom'>Canto inferior esquerdo</option>
                    <option value='right|bottom'>Canto inferior direito</option>
                    <option value='left|top'>Canto superior esquerdo</option>
                    <option value='right|top'>Canto superior direito</option>
                  </Select>
                </FormControl>

                {!appInfoService.isUsingProducttWebview &&
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel>Posição do botão na tela de produto</InputLabel>
                    <Select
                      native
                      value={data.options.iconPositionInProductScreen || ''}
                      onChange={(e) => handleChange('options.iconPositionInProductScreen', e.target.value)}
                      label="Posicão do botão"
                    >
                      <option value='left|bottom'>Canto inferior esquerdo</option>
                      <option value='right|bottom'>Canto inferior direito</option>
                      <option value='left|top'>Canto superior esquerdo</option>
                      <option value='right|top'>Canto superior direito</option>
                    </Select>
                  </FormControl>
                }
              </>
            }
          </CardContent>
        )}
      </Card>
    </main>
  );
}