import axios from "axios";
import _ from 'lodash';
import config from "../../_helpers/config";
import { mobilizeiStoresService } from "../mobilizei/stores.service";
import { store } from "../../../index";

export const authWixService = {
  refreshAccessToken
};

function refreshAccessToken() {
    const storeData = store.getState().UserReducer.user.store;
    const storeId = storeData.id;

    return axios
        .post(
            `${config.MOBILIZEI_API_BASE_URL}/platforms/wix/auth/refresh`,
            { storeId: storeId }
        )
        .then(() => {
            return mobilizeiStoresService.getStoreById('wix', storeId).then(storeData => {
                return storeData;
            })
        })
}