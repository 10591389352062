import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, FormControl, TextField, Button } from '@material-ui/core';
import _ from 'lodash';
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'grab',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flexGrow: 1
    },
    logo: {
        height: 'auto',
        marginTop: 30,
        width: 210
    },
    form: {
        marginTop: 10,
        width: "80%"
    },
    formControl: { 
        margin: '15px 0'
    },
    label: {
        color: '#000'
    },
    button: props =>({
        backgroundColor: props.buttonColor,
        borderRadius: 2,
        color: props.buttonTextColor,
        margin: '15px 0',
        padding: '10px 0',
        width: "100%",
        "&:hover": {
            backgroundColor: props.buttonColor,
        }
    }),
    signUpText: {
        marginTop: 20,
        textAlign: 'center'
    }
}));
  

export default function LoginScreen() {
    const selectedArea = useSelector(state => getSelectedArea(state));
    const data = selectedArea.sections.find(s => s.key === 'loginScreenSettings');

    const colors = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'colors'));
    const classes = useStyles({...data.options, ...colors.options});

  return (
    <div className={classes.root}>
        {data.options.showLogo &&
            <Grid container justify="center">
                <img 
                    alt="logo" 
                    className={classes.logo} 
                    src={_.get(data, 'options.logo.url', require('../../Editor/_assets/images/image_placeholder_350_350.png'))}
                />
            </Grid>
        }
  
        <div className={classes.form}>
            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                    InputLabelProps={{
                        className: classes.label,
                    }}
                    label="Email"
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                    InputLabelProps={{
                        className: classes.label,
                    }}
                    label="Senha" 
                />
            </FormControl>

            <Button className={classes.button} variant="contained">ENTRAR</Button>

            <p className={classes.signUpText}>Não possui conta? Cadastre-se aqui</p>
        </div>

    </div>
  );
}
