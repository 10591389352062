import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActions } from '../../../_actions/navigation.actions';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Divider, Hidden } from '@material-ui/core';
import SectionConfiguration from './SectionConfiguration';
import AreaSectionsTab from './Tabs/AreaSectionsTab';
import SettingsSectionsTab from './Tabs/SettingsSectionsTab';
import { isEmpty } from 'lodash';
import { getSelectedSection } from '../../../_reducers/theme.reducer';
import NewSectionPicker from './NewSectionPicker';

const useStyles = makeStyles((theme) => ({
  drawer: props => ({
    flexShrink: 0,
    width: props.drawerWidth
  }),
  drawerPaper: props => ({
    width: props.drawerWidth,
  }),
  tabs: {
    backgroundColor: theme.palette.background.default,
  }
}));

export default function SideMenu(props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const showNewSectionPicker = useSelector(state => state.NavigationReducer.showNewSectionPicker);
  const selectedSection = useSelector(state => getSelectedSection(state));
  const selectedTabIndex = useSelector(state => state.NavigationReducer.selectedTabIndex);
  const storeData = useSelector(state => state.UserReducer.user.store); 
  const isWebviewTheme = storeData.activeTheme == 'webview';

  const handleTabChange = (e, newValue) => {
    dispatch(navigationActions.selectTab(newValue));
  };

  const renderContent = () => {
    if (showNewSectionPicker) {
      return <NewSectionPicker />
    }

    if (!isEmpty(selectedSection)) {
      return <SectionConfiguration drawerWidth={props.drawerWidth} />;
    }

    return (
      <>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleTabChange}
          textColor="primary"
          value={selectedTabIndex}
          variant="fullWidth"
        >
          <Tab label="Editar tela" className="edit-screen-tab" />
          <Tab label="Configurar tema" className="configure-theme-tab" />
        </Tabs>

        <Divider />

        {selectedTabIndex === 0 ? <AreaSectionsTab /> : <SettingsSectionsTab />}
      </>
    )
  }

  useEffect(() => {
    if(isWebviewTheme){
      dispatch(navigationActions.selectTab(1))
      dispatch(navigationActions.selectSection(0))
    }
  }, [])

  return (
    <>
      <Hidden xsUp implementation="css">
        <Drawer
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          variant="temporary"
          open={props.isSideMenuOpen}
          onClose={props.handleSideMenuToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {renderContent()}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          anchor="left"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
        >
          {renderContent()}
        </Drawer>
      </Hidden>
    </>
  );
}