const navigationReducerDefaultState = {
    selectedAreaIndex: 0, // There is always a selected area
    selectedTabIndex: 0, // There is always a selected tab  (it defines if we are looking at areas or settings)
    selectedSectionIndex: null, // We can have a selected section or not. A section can be part of an area or the settings.
    showNewSectionPicker: false
};

export default (state = navigationReducerDefaultState, action) => {
    switch (action.type) {
        case "SELECT_AREA":
            return {
                ...state,
                selectedAreaIndex: action.payload,
                selectedTabIndex: 0,
                selectedSectionIndex: null
            };
        case "SELECT_TAB":
            return {
                ...state,
                selectedTabIndex: action.payload,
                selectedSectionIndex: null
            };
        case "SELECT_SECTION":
            return {
                ...state,
                selectedSectionIndex: action.payload
            };
        case "SET_SELECTED_SECTION_INDEX":
            return {
                ...state,
                selectedSectionIndex: action.payload
            };
        case "SET_SHOW_NEW_SECTION_PICKER":
            return {
                ...state,
                showNewSectionPicker: action.payload
            };
        default:
            return state;
    }
};
  