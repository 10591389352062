import { store } from '../../index';
import _ from 'lodash';

class appInfoService {
    init()
    {    
        const settings = store.getState().UserReducer.user.store;

        this.accessToken = settings.accessToken;
        this.iconUrl = settings.iconUrl;
        this.platformId = settings.platformId;
        this.storeId = settings.id;
        this.storeStatus = settings.status;
        this.title = settings.name;
        this.trialEndsAt = settings.trialEndsAt;

        this.androidPublishStatus = _.get(settings, 'platforms.android.publishStatus', 'first_publish_pending');
        this.iOSPublishStatus = _.get(settings, 'platforms.ios.publishStatus', 'first_publish_pending');
    
        this.androidDownloadUrl = _.get(settings, 'platforms.android.downloadUrl') || '';
        this.iOSDownloadUrl = _.get(settings, 'platforms.ios.downloadUrl') || '';

        this.isUsingProductListWebview = _.get(settings, 'generalSettings.useProductListWebview', false);
        this.isUsingProducttWebview = _.get(settings, 'generalSettings.useProductWebview', false);
    }

    isAppPublished = (platform) => {
        let appDownloadUrl = '';
        if(platform === 'android') {
            appDownloadUrl = this.androidDownloadUrl;
        } else {
            appDownloadUrl = this.iOSDownloadUrl;
        }


        if(!_.isEmpty(appDownloadUrl)) {
            return true;
        }

        return false;
    }

    isBothAppVersionsPublished = () => {
        if(!_.isEmpty(this.androidDownloadUrl) && !_.isEmpty(this.iOSDownloadUrl)) {
            return true;
        }

        return false;
    }

    isAndroidAppPublished = () => {
        if(!_.isEmpty(this.androidDownloadUrl)) {
            return true;
        }

        return false;
    }

    isiOSAppPublished = () => {
        if(!_.isEmpty(this.iOSDownloadUrl)) {
            return true;
        }

        return false;
    }

    isFirstPublishPending = () => {
        if(this.androidPublishStatus === 'first_publish_pending' && this.iOSPublishStatus === 'first_publish_pending') {
            return true;
        };

        return false
    }

    isAppBeingPublished = (platform) => {
        let currentPublishStatus = '';
        if(platform === 'android') {
            currentPublishStatus = this.androidPublishStatus;
        } else {
            currentPublishStatus = this.iOSPublishStatus;
        }

        if(
            currentPublishStatus === 'pending_listing_creation' || 
            currentPublishStatus === 'publishing' || 
            currentPublishStatus === 'in_review' 
        ) {
            return true;
        }

        return false;
    }
}

const instance = new appInfoService();

export default instance;
