import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, FormControl, FormHelperText, IconButton, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  },
  buttons: {
    position: 'absolute',
    right: 0,
    top: 5,
    zIndex: 99
  },
  dragIcon: {
    cursor: 'grab',
    '&:active': {
        cursor: 'grabbing'
    }
  },
  title: {
    display: 'flex',
    flexDirection: 'row'
  },
  heading: {
    marginLeft: 10
  },
  icon: {
    fontSize: '1.5rem',
    textAlign: 'center',
    marginRight: 10
  },
  actionIcon: {
      fontSize: 20
  },
  card: {
      width: '100%'
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    padding: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    fontSize: 16,
    marginRight: 0,
    order: -1
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0
  },
}))(MuiAccordionDetails);

export default function SocialNetworkAccordion({ socialNetwork, socialNetworkIndex, cardRef, dragIconRef, onChange }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const icon = _.get(socialNetwork, 'icon', {});

  return (
    <div className={classes.container}>
        <div className={classes.buttons}>
            <IconButton onClick={() => onChange('isVisible', !socialNetwork.isVisible, socialNetworkIndex)}>
                { socialNetwork.isVisible ? 
                <FontAwesomeIcon icon={['fas', 'eye']} className={classes.actionIcon} fixedWidth /> : 
                <FontAwesomeIcon icon={['fas', 'eye-slash']} className={classes.actionIcon} fixedWidth /> 
                }
            </IconButton>

            <IconButton className={classes.dragIcon} ref={dragIconRef} onDrag={() => setExpanded(false)}>
            <FontAwesomeIcon icon={['fas', 'grip-vertical']} className={classes.actionIcon} fixedWidth />
            </IconButton>
        </div>

        <Accordion expanded={expanded}>
            <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} className={classes.expandIcon} />}
                onClick={() => setExpanded(!expanded)}
                ref={cardRef}
            >
                <div className={classes.title}>
                    <FontAwesomeIcon icon={[icon.style, icon.name]} className={classes.icon} fixedWidth />
                    <Typography className={classes.heading}>{socialNetwork.name}</Typography>
                </div>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
                <Card elevation={1} square className={classes.card}>
                    <CardContent>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField 
                                label="Link" 
                                value={socialNetwork.link || ''}
                                onChange={(e) => onChange('link', e.target.value, socialNetworkIndex)} 
                            />
                            <FormHelperText>{socialNetwork.helperText}</FormHelperText>
                        </FormControl>
                    </CardContent>
                </Card>
            </AccordionDetails>
        </Accordion>
    </div>
  );
}