import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { useDispatch } from 'react-redux';
import { Button, Card, CircularProgress, FormControl, Grid, InputLabel, Modal, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import iconsTranslate from '../_assets/data/icons_translate.json';

library.add(fas, far, fab)

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#e5e5e5',
  },
  button: {
    marginTop: 22,
    width: '100%'
  },
  modal: {
    position: 'absolute',
    width: '70%',
    padding: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  modalButton: {
    margin: '15px 0',
  },
  table: {
    boxShadow: 'none'
  },
  root: {
    flexGrow: 1,
    overflowY: 'auto',
    height: 400,
    padding: '0 10px',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    borderRadius: 10,
    cursor: 'pointer',
    width: 80
  },
  paperSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    borderRadius: 10,
    cursor: 'pointer',
    border: `1px solid #000`,
    width: 80
  },
  formControl: {
    width: '40%',
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    alignItems: 'center',
    marginBottom: '10px'
  },
  title: {
    fontSize: 22,
  }
}));

const IconPicker = ({ menuItem, onPicked }) => {
  const dispatch = useDispatch()
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const getAllIcons = () => {
    if(!showModal){
      return []
    }

    const array = []

    const styles = Object.keys(library.definitions);

    for (const style of styles) {
      const names = Object.keys(library.definitions[style]);

      for (const name of names) {
        const object = _.find(iconsTranslate, { name: name });

        if(object){
          array.push({
            style: style,
            name: name,
            keywords: object?.keywords
          })
        }
      }
    }
    
    const ordenatedIcons = array.sort(function(a,b) {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
    
    return ordenatedIcons;
  }

  const handleSearchChange = (value) => {
    const filtered = getAllIcons().filter((icon) => {

        if(icon.keywords){
          const findKeyword = icon.keywords.filter(x => x.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                              .includes(value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()));

          if(!_.isEmpty(findKeyword)){
            return true
          }
        }

        if(icon.name.replace('-', '').includes(value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())){
          return true
        }


        return false
    });
    return filtered;
};

  const handleClick = (icon) => {
    setShowModal(false)
    setSelectedIcon(icon);
    
    onPicked({ name: icon.name, style: icon.style })
  };

  const filteredIcons = searchValue.length > 0
        ? handleSearchChange(searchValue)
        : getAllIcons();

  return (
    <div>
      <div className={classes.container}>
        <InputLabel shrink>Ícone</InputLabel>

        <div className={classes.iconContainer}>
          {!_.isNil(selectedIcon) 
            ? <FontAwesomeIcon
              icon={[selectedIcon.style, selectedIcon.name]}
              color='#757575'
              size='10x'
            />
            : <>
              {menuItem.icon && (
                <FontAwesomeIcon
                  icon={[menuItem.icon.style, menuItem.icon.name]}
                  color='#757575'
                  size='10x'
                />
              )}
            </>
          }
        </div>
        
        <Button
          className={classes.button}
          onClick={() => setShowModal(true)}
          variant="outlined"
        >
          {!menuItem.icon ? 'Selecionar ícone' : 'Trocar ícone'}
        </Button>
      </div>

      <Modal
        disableAutoFocus
        disableEnforceFocus
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <Card className={classes.modal}>
          <Grid container justifyContent='space-between' className={classes.header}>
            <Typography variant='subtitle1' className={classes.title}>
              Ícones
            </Typography>
            <FormControl className={classes.formControl}>
              <TextField
                placeholder='Pesquisar ícones...'
                value={searchValue || ''}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <FontAwesomeIcon 
                      icon={['fas', 'search']}
                      style={{ marginRight: 10 }}
                    />
                  ),
                  endAdornment: (
                    <FontAwesomeIcon 
                      icon={['fas', 'times']}
                      style={{ marginRight: 10, cursor: 'pointer' }}
                      onClick={() => setSearchValue('')}
                    />
                  )
                }}
              />
            </FormControl>
          </Grid>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                {filteredIcons.map((item, index) => (
                  <Grid key={index} item >
                    <Paper 
                      className={!_.isNil(selectedIcon) 
                        &&  _.isEqual(selectedIcon.name, item.name) && _.isEqual(selectedIcon.style, item.style)
                          ? classes.paperSelected : classes.paper} 
                      title={item.name} 
                      onClick={() => handleClick(item)}
                    >
                      <FontAwesomeIcon
                        icon={[item.style, item.name]}
                        size='3x'
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify='center'>
            <Button color="secondary" size="small" className={classes.modalButton} onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
          </Grid>
        </Card>
      </Modal>
    </div>
  );
};

export default IconPicker;