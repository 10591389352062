import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    mainContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        padding: '0 20px'
    },
    title: props =>({
      color: props.titleColor,
      fontSize: 20,
      marginRight: 10
    }),
    quantitySelectorContainer: {
      border: '1px solid #ccc',
      borderRadius: 4,
      display: 'flex',
      flexDirection: "row",
      justifyContent: "space-between",
      width: 180
    },
    minusButton: {
      backgroundColor: "#ededed",
      borderRight: '1px solid #ccc',
      paddingVertical: 5,
      width: "32%"
    },
    plusButton: {
      borderLeft: '1px solid #ccc',
      paddingVertical: 5,
      width: "32%"
    },
    quantityText: {
      color: "#909090",
      fontSize: 18,
      margin: 0,
      padding: '10px 0',
      textAlign: "center",
    },
    quantitySelectorText: {
      color: "#909090",
      fontSize: 20,
      margin: 0,
      padding: '10px 0',
      textAlign: "center",
    }
}));

export default function QuantitySelector({ colors }) {
  const classes = useStyles(colors);

    return (
        <div className={classes.mainContainer}>
            <p className={classes.title}>Quantidade: </p>
            <div className={classes.quantitySelectorContainer}>
                <div className={classes.minusButton}>
                    <p className={classes.quantitySelectorText}>-</p>
                </div>
                <div className={classes.quantity}>
                    <p className={classes.quantityText}>1</p>
                </div>
                <div className={classes.plusButton}>
                    <p className={classes.quantitySelectorText}>+</p>
                </div>
            </div>
        </div>
    )
};