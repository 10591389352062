import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageUploader from '../../../ImageUploader';
import CategoryPicker from '../../../CategoryPicker';

const useStyles = makeStyles((theme) => ({
  imageTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    marginBottom: 0
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
  dragIcon: {
    cursor: 'grab',
    marginTop: 10,
    position: 'absolute',
    right: 0,
    '&:active': {
        cursor: 'grabbing'
    }
  }
}));

export default function CategoryCard({ categoryIndex, data, onChange, onMultipleChange, onDelete, showDeleteButton }) {
  const classes = useStyles();

  const handleUploadSuccess = (data) => {
    onMultipleChange(data, categoryIndex);
  }

  return (
    <div className={classes.container}>
    <Card elevation={1} square>
        <CardContent>
            <ImageUploader 
              label='Imagem' 
              onUploadSuccess={handleUploadSuccess} 
              imageHeight={data.height}  
              imageUrl={data.url} 
              imageWidth={data.width} 
            />
            <FormHelperText>Tamanho recomendado: 800 x 800 px</FormHelperText>
            
            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Título da categoria"
                  value={data.title || ''} 
                  onChange={(e) => onChange('title', e.target.value, categoryIndex)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <CategoryPicker
                    selectedCategoryId={data.categoryId || ''}
                    onPicked={(category) => onChange('categoryId', category.id, categoryIndex)} 
                />
                <FormHelperText>Selecione a categoria que será exibida ao clicar na imagem</FormHelperText>
            </FormControl>

        </CardContent>
        {showDeleteButton &&
          <CardActions>
            <Button color="secondary" size="small" onClick={() => onDelete()} startIcon={<FontAwesomeIcon icon={['fas', 'trash']} />}>Excluir categoria</Button>
          </CardActions>
        }
    </Card>

    </div>
  );
}