import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Header from './_components/Header';
import DrawerMenu from './_components/DrawerMenu';
import TabBar from './_components/TabBar';
import ScrollContainer from 'react-indiana-drag-scroll'
import HomeScreen from './HomeScreen/HomeScreen';
import CategoryScreen from './CategoryScreen/CategoryScreen';
import ProductScreen from './ProductScreen/ProductScreen';
import LoginScreen from './LoginScreen/LoginScreen';
import ContactScreen from './ContactScreen/ContactScreen';
import { getSelectedArea } from '../Editor/_reducers/theme.reducer';
import _ from 'lodash';
import WhatsAppButton from './_components/Sections/WhatsAppButton';
import CartScreen from './_components/CartScreen/CartScreen';
import WebviewScreen from './WebviewScreen/WebviewScreen';

const useStyles = makeStyles((theme) => ({
  previewerWrapper: {
    backgroundColor: '#fff',
    boxShadow: '0 0 3px rgba(0,0,0,.1),0 0 12px rgba(0,0,0,.1)',
    height: '100%',
    maxHeight: 812,
    overflow: 'hidden',
    position: 'relative',
    width: 375,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 56px)',
      position: 'absolute',
      top: 56,
      width: '100vw'
    }
  },
  statusBarWrapper: props => ({
    backgroundColor: props.backgroundColor,
  }),
  statusBar: props => ({
      width: '100%',
      backgroundColor: props.backgroundColor,
  }),
  content: props =>({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: "wrap",
    height: '100%',
    maxHeight: '100%',
    overflowY: 'scroll',
    paddingBottom: props.isTabBarVisible ? 140 : 80
  })
}));

export default function Previewer() {
  const storeData = useSelector(state => state.UserReducer.user.store);
  const tabBarData = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'tabBar'));
  const headerData = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'header'));
  const isTabBarVisible = _.get(tabBarData, 'options.isVisible', true);
  const options = _.get(headerData, 'options', {});

  const classes = useStyles({ 
    isTabBarVisible, 
    backgroundColor: options.backgroundColor ? options.backgroundColor :  "#000",
     ...options 
  });

  const selectedArea = useSelector(state => getSelectedArea(state));

  const getSelectedScreen = () => {
    if(storeData.activeTheme == 'webview'){
      return <WebviewScreen />; 
    }
    
    switch(selectedArea.key) {
      case 'homeScreen':
        return <HomeScreen />;
      case 'categoryScreen':
        return <CategoryScreen />
      case 'contactScreen':
        return <ContactScreen />
      case 'productDetailsScreen':
        return <ProductScreen />
      case 'loginScreen':
        return <LoginScreen />
      case 'cartScreen':
        return <CartScreen />
      default:
        return null;
    }
  }

  return (
    <div className={classes.previewerWrapper}>
        <div className={classes.statusBarWrapper}>
          {options.statusBarTextColor == "#fff" || options.statusBarTextColor == "#ffffff" || !options.statusBarTextColor
            ? <img src={require('../Editor/_assets/images/status_bar_light.png')} alt="status_bar" className={classes.statusBar} />
            : <img src={require('../Editor/_assets/images/status_bar_dark.png')} alt="status_bar" className={classes.statusBar} />
          }
        </div>
        <Header />
        <DrawerMenu />
        <TabBar />

        <ScrollContainer className={classes.content} horizontal={false}>
          <WhatsAppButton isTabBarVisible={isTabBarVisible} selectedScreen={selectedArea.key} />
          {getSelectedScreen()}
        </ScrollContainer>
    </div>
  );
}