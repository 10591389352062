import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActions } from '../../_actions/navigation.actions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Hidden, IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { isNull } from 'lodash';
import { getSelectedArea } from '../../_reducers/theme.reducer';
import { PLATFORMS } from '../../_helpers/constants';
import _ from 'lodash';
import EditIcon from '@material-ui/icons/Edit';
import MissingFeaturesModal from '../MissingFeaturesModal';
import { getActiveMissingFeatures } from '../../_helpers/missingFeatures';
import { mobilizeiStoresService } from '../../_services/mobilizei/stores.service';

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: '#fff',
        height: 56
    },
    toolbar: {
        height: 56,
        minHeight: 56,
        justifyContent: 'space-between',
    },
    progress: {
        margin: '0 20px'
    },
    icon: {
        marginLeft: 10
    },
    hideMenuItem: {
        display: 'none'
    },
    button: {
        backgroundColor: '#3cd568',
        color: '#fff',
        textTransform: 'lowercase',
        "&:hover": {
            backgroundColor: '#2a9548',
        }
    }
}));

function Header(props) {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const platformId = useSelector(state => state.StoreReducer.platform);
    const theme = useSelector(state => state.ThemeReducer);

    const selectedArea = useSelector(state => getSelectedArea(state));

    const storeData = useSelector(state => state.UserReducer.user.store); 
    const isWebviewTheme = storeData.activeTheme == 'webview';

    const [anchorEl, setAnchorEl] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const missingFeatures = _.get(storeData, 'missingFeatures', [])
    const [activeMissingFeatures, setActiveMissingFeatures] = useState({});
    const [showMissingFeaturesModal, setShowMissingFeaturesModal] = useState(false);

    const [alert, setAlert] = useState({
        message: '',
        open: false,
        severity: 'success'
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAreaSelect = (index) => {
        if (!isNull(index)) {
            dispatch(navigationActions.selectArea(index));
        }
        setAnchorEl(null);
    };

    const handleSave = () => {
        // Check if there is any missing feature that is activated
        const amf = getActiveMissingFeatures(missingFeatures, theme);
        if(!_.isEmpty(amf)) {
            setActiveMissingFeatures(amf);
            setShowMissingFeaturesModal(true);
            return;
        }

        setIsSaving(true);

        mobilizeiStoresService.setTheme(storeData?.platformId, storeData?.id, theme.key, theme)
            .then(() => {
                setAlert({
                    open: true,
                    message: 'Configurações salvas com sucesso!',
                    severity: 'success'
                });
            })
            .catch(() => {
                setAlert({
                    open: true,
                    message: 'Não foi possível salvar as suas configurações. Por favor, tente novamente.',
                    severity: 'error'
                });
            })
            .finally(() => {
                setIsSaving(false);
            })
    }

    return (
        <AppBar position="absolute" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>

                <MissingFeaturesModal
                    activeMissingFeatures={activeMissingFeatures}
                    setAlert={setAlert}
                    setShowModal={setShowMissingFeaturesModal}
                    showModal={showMissingFeaturesModal}
                />

                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={6000}
                    onClose={() => setAlert({ ...alert, open: false })}
                    open={alert.open}
                    style={{ position: 'absolute' }}
                >
                    <Alert elevation={6} variant="filled" severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>

                <Box display="flex">
                    <Hidden smUp implementation="css" display="inline">
                        <IconButton aria-label="edit" onClick={props.handleSideMenuToggle}>
                            <EditIcon />
                        </IconButton>
                    </Hidden>

                    {!isWebviewTheme && (
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className='screen-selector'>
                            <Hidden smUp implementation="css" display="inline">
                                <span style={{ textTransform: 'none' }}>Tela:&nbsp;</span>
                            </Hidden>
                            <Hidden xsDown implementation="css" display="inline">
                                <span style={{ textTransform: 'none' }}>Tela selecionada:&nbsp;</span>
                            </Hidden>

                            {selectedArea.title}
                            <FontAwesomeIcon icon={['fas', 'chevron-down']} className={classes.icon} />
                        </Button>
                    )}
                    
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => handleAreaSelect(null)}
                    >
                        {theme.areas.map((area, index) => {
                            if(platformId === PLATFORMS.NUVEMSHOP){
                                if(area.key != 'cartScreen'){
                                    return (
                                        <MenuItem
                                            key={`area-selector-${index}`}
                                            onClick={() => handleAreaSelect(index)}
                                            className={platformId === PLATFORMS.NUVEMSHOP && area.key === 'loginScreen' ? classes.hideMenuItem : ''}
                                        >
                                            {area.title}
                                        </MenuItem>
                                    )
                                }
                            }else{
                                return (
                                    <MenuItem
                                        key={`area-selector-${index}`}
                                        onClick={() => handleAreaSelect(index)}
                                        className={platformId === PLATFORMS.NUVEMSHOP && area.key === 'loginScreen' ? classes.hideMenuItem : ''}
                                    >
                                        {area.title}
                                    </MenuItem>
                                )
                            }
                        })}
                    </Menu>
                </Box>

                {isSaving ? (
                    <CircularProgress className={classes.progress} />
                ) : (
                    <Button className={classes.button} variant="contained" onClick={() => handleSave()}>
                        Salvar
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;