import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { previewerNavigationActions } from '../../Editor/_actions/previewerNavigation.actions';
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    height: 60,
    marginTop: -5,
  },
  toolbar: props =>({
    alignItems: 'center',
    backgroundColor: props.options?.backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between',
    padding: 0,
    position: 'relative',
    width: '100%'
  }),
  menuButton: {
    marginLeft: 10,
    zIndex: 99
  },
  title: props =>({
    color: props.options?.textColor,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: 18,
    position: 'absolute',
    width: '100%'
  }),
  rightButtons: {
    justifySelf: 'flex-end'
  },
  searchButton: props =>({
    padding: 0,
    paddingRight: props.options?.showCartIcon ? 5 : 20
  }),
  cartButton: {
      paddingRight: 20
  },
  icon: props =>({
    color: props.options?.iconColor,
    fontSize: 18
  }),
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%'
  },
  logo: {
    height: 45,
    width: 'auto'
  },
}));

export default function Header() {
  const storeData = useSelector(state => state.UserReducer.user.store);
  const data = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'header'));
  const drawerMenuData = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'drawerMenu'));
  const selectedArea = useSelector(state => getSelectedArea(state));

  const showDrawerMenu = _.get(drawerMenuData, 'options.isVisible', true);

  const classes = useStyles(data);
  const dispatch = useDispatch();

  if(storeData.activeTheme == 'webview'){
    return <></>
  }

  if(data.options.isVisible || _.isNil(data.options.isVisible)){
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <div className={classes.toolbar}>
            {showDrawerMenu ? 
              (<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => dispatch(previewerNavigationActions.setDrawerState(true))}>
                <FontAwesomeIcon icon={['fas', 'bars']} className={classes.icon} />
              </IconButton>) :
              <></>
            }
            
            {data.options?.showLogo ? (
              <div className={classes.logoContainer}>
                <img 
                  alt="logo" 
                  className={classes.logo} 
                  src={_.get(data, 'options.logo.url', require('../../Editor/_assets/images/image_placeholder_350_350.png'))}
                />
              </div>
            ) : (
              <p className={classes.title}>
                  {selectedArea.title}
              </p>
            )}
  
            <div className={classes.rightButtons}>
              <IconButton aria-label="search" color="inherit" className={classes.searchButton}>
                <FontAwesomeIcon icon={['fas', 'search']} className={classes.icon} />
              </IconButton>
              {data.options?.showCartIcon &&
               ( <IconButton aria-label="search" color="inherit" className={classes.cartButton}>
                    <FontAwesomeIcon icon={['fas', 'shopping-cart']} className={classes.icon} />
                </IconButton>)
              }
            </div>
          </div>
        </AppBar>
      </div>
    );
  }else{
    return <></>
  }
}