import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollContainer from 'react-indiana-drag-scroll'

const useStyles = makeStyles(() => ({
  root: props =>({
    cursor: 'grab',
    display: props.isVisible ? 'block' : 'none',
    margin: '10px 0',
    width: '100%'
  }),
  content: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      width: '100%'
  },
  wrapper: () =>({
    position: 'relative'
  }),
  imageWrapper: props =>({
    alignItems: 'center',
    borderRadius: props.options.imageShape === 'round' ? '50%' : 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 4px',
    minWidth: 120,
    overflow: 'hidden',
    position: 'relative',

  }),
  overlay: props =>({
    backgroundColor: props.options.overlayColor,
    height: '100%',
    opacity: (100 - props.options.opacity) / 100,
    position: 'absolute',
    width: '100%'
  }),
  image: props =>({
    objectFit: 'cover',
    height: props.options.imageShape === 'rectangle' ? 70 : 120,
    width: props.options.imageShape === 'rectangle' ? 145 : 120,
  }),
  titleBottom: props =>({
    color: '#000',
    fontSize: 16,
    margin: '6px 0',
    textAlign: 'center'
  }),
  titleOver: props =>({
    color: '#fff',
    fontSize: 16,
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: '90%'
  })
}));

export default function CategoriesCarousel({ data }) {
  const classes = useStyles(data);

  return (
    <div className={classes.root}>
        <ScrollContainer className={classes.content}>
            {data.content.map((category, index) => {
              return (
                <div className={classes.wrapper} key={`cat-img-${index}`}>
                    <div className={classes.imageWrapper}>
                        {data.options.titlePosition === 'over' && <div className={classes.overlay} />}
                        <img 
                            alt="banner"
                            className={classes.image} 
                            src={category.url ? category.url : require('../../../Editor/_assets/images/image_placeholder_350_350.png')} 
                        />
                    </div>

                    <p className={data.options.titlePosition === 'over' ? classes.titleOver : classes.titleBottom}>
                        {category.title}
                    </p>
                </div>
              )
            })}
        </ScrollContainer>
    </div>
  );
}