import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ColorPicker from '../../ColorPicker'
import { Card, CardContent, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import DeleteSectionButton from '../../DeleteSectionButton';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  button: {
    margin: 30
  }
}));

export default function FlashText({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>
            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Texto" 
                  multiline 
                  rows={4} 
                  variant="outlined" 
                  value={data.options.text} 
                  onChange={(e) => handleChange('options.text', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Alinhamento do texto</InputLabel>
                <Select
                    native
                    value={data.options.textAlign || ''}
                    onChange={(e) => handleChange('options.textAlign', e.target.value)}
                >
                    <option value='left'>Alinhar à esquerda</option>
                    <option value='center'>Centralizar</option>
                    <option value='right'>Alinhar à direita</option>
                </Select>
            </FormControl>

            <FormControl fullWidth  className={classes.formControl}>
                <InputLabel>Tamanho do texto</InputLabel>
                <Select
                    native
                    value={data.options.fontSize}
                    onChange={(e) => handleChange('options.fontSize', e.target.value)} 
                    label="Tamanho do texto"
                    inputProps={{ name: 'fontSize' }}
                >
                    <option value='small'>Pequeno</option>
                    <option value='medium'>Médio</option>
                    <option value='large'>Grande</option>
                </Select>
            </FormControl>

            <ColorPicker 
              label='Cor do texto' 
              initialColor={data.options.fontColor} 
              onChange={(color) => handleChange('options.fontColor', color)} 
            />

            <ColorPicker 
              label='Cor de fundo' 
              initialColor={data.options.backgroundColor} 
              onChange={(color) => handleChange('options.backgroundColor', color)} 
            />
          </CardContent>
        </Card>

        {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}