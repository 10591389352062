import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActions } from '../../../_actions/navigation.actions';
import Typography from '@material-ui/core/Typography';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slideshow from '../../Sections/AreaSections/Slideshow/Slideshow';
import ProductCarousel from '../../Sections/AreaSections/ProductCarousel';
import Banner from '../../Sections/AreaSections/Banner';
import FlashText from '../../Sections/AreaSections/FlashText';
import Header from '../../Sections/SettingsSections/Header';
import DrawerMenu from '../../Sections/SettingsSections/DrawerMenu';
import TabBar from '../../Sections/SettingsSections/TabBar';
import Colors from '../../Sections/SettingsSections/Colors';
import CategorySettings from '../../Sections/AreaSections/CategorySettings';
import ProductDetailsSettings from '../../Sections/AreaSections/ProductDetailsSettings';
import LoginScreenSettings from '../../Sections/AreaSections/LoginScreenSettings';
import ContactScreenSettings from '../../Sections/AreaSections/ContactScreenSettings/ContactScreenSettings';
import { getSelectedSection } from '../../../_reducers/theme.reducer';
import ProductList from '../../Sections/AreaSections/ProductList';
import SocialNetworks from '../../Sections/AreaSections/SocialNetworks/SocialNetworks';
import Separator from '../../Sections/AreaSections/Separator';
import WhatsAppButton from '../../Sections/SettingsSections/WhatsAppButton';
import WishlistButton from '../../Sections/SettingsSections/WishlistButton';
import TextBlock from '../../Sections/AreaSections/TextBlock';
import CategoriesCarousel from '../../Sections/AreaSections/CategoriesCarousel/CategoriesCarousel';
import ImageCarousel from '../../Sections/AreaSections/ImageCarousel/ImageCarousel';
import ProductCardsSettings from '../../Sections/SettingsSections/ProductCardsSettings';
import CartScreenSettings from '../../Sections/AreaSections/CartScreenSettings';

const useStyles = makeStyles(() => ({
  sectionConfigurationContent: {
    overflowX: 'hidden'
  },
  sectionTitleBar: props =>({
    alignItems: 'center',
    backgroundColor: '#f9fafb',
    height: 56,
    minHeight: 56,
    justifyContent: 'center',
    width: props.drawerWidth,
  }),
  sectionTitleRoot: {
    justifyContent: 'center',
    width: '100%'
  },
  sectionTitle: {
    color: '#000',
    fontWeight: 'bold'
  },
  backIconButton: {
    left: 10,
    position: 'absolute'
  },
  backIcon: {
    color: '#000',
    fontSize: 16
  }
}));

export default function SectionConfiguration(props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const selectedSection = useSelector(state => getSelectedSection(state));
  const storeData = useSelector(state => state.UserReducer.user.store); 
  const isWebviewTheme = storeData.activeTheme == 'webview';

  const getSection = (sectionKey) => {
    switch(sectionKey) {
      case 'banner':
        return <Banner data={selectedSection} showDeleteButton />
      case 'slideshow':
        return <Slideshow data={selectedSection} showDeleteButton />
      case 'categoriesCarousel':
        return <CategoriesCarousel data={selectedSection} showDeleteButton />
      case 'categorySettings':
        return <CategorySettings data={selectedSection} />
      case 'contactScreenSettings':
        return <ContactScreenSettings data={selectedSection} />
      case 'colors':
        return <Colors data={selectedSection} />
      case 'drawerMenu':
        return <DrawerMenu data={selectedSection} />
      case 'flashText':
        return <FlashText data={selectedSection} showDeleteButton />
      case 'header':
        return <Header data={selectedSection} />
      case 'imageCarousel':
        return <ImageCarousel data={selectedSection} showDeleteButton />
      case 'loginScreenSettings':
        return <LoginScreenSettings data={selectedSection} />
      case 'productCardsSettings':
        return <ProductCardsSettings data={selectedSection} />
      case 'productCarousel':
        return <ProductCarousel data={selectedSection} showDeleteButton />
      case 'productList':
        return <ProductList data={selectedSection} showDeleteButton />
      case 'productDetailsSettings':
        return <ProductDetailsSettings data={selectedSection} />
      case 'socialNetworks':
        return <SocialNetworks data={selectedSection} showDeleteButton />
      case 'separator':
        return <Separator data={selectedSection} showDeleteButton />
      case 'tabBar':
        return <TabBar data={selectedSection} />
      case 'textBlock':
        return <TextBlock data={selectedSection} showDeleteButton />
      case 'whatsAppButton':
        return <WhatsAppButton data={selectedSection} />
      case 'wishlist':
        return <WishlistButton data={selectedSection} />
      case 'cartScreenSettings':
        return <CartScreenSettings data={selectedSection} />
      default:
        return null
    }
  }

  return (
    <div className={classes.sectionConfigurationContent}>
        <AppBar position="relative" className={classes.sectionTitleBar}>
            <Toolbar classes={{ root: classes.sectionTitleRoot}}>
                {!isWebviewTheme && (
                  <IconButton className={classes.backIconButton} onClick={() => dispatch(navigationActions.setSelectedSectionIndex(null))}>
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} className={classes.backIcon} />
                  </IconButton>
                )}
                <Typography className={classes.sectionTitle}>{selectedSection.title}</Typography>
            </Toolbar>
        </AppBar>

        {getSection(selectedSection.key)}
    </div>
  );
}