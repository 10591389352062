import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd';
import shortid from 'shortid';

export default function(props) {
    const dragIndex = shortid.generate();
    const { hoverIndex, onMove } = props;

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'draggable',
        hover(item, monitor) {
        if (!ref.current) {
            return
        }
        const dragIndex = item.index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
        }
        // Time to actually perform the action
        onMove(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
        },
    })
    const [, drag, preview] = useDrag({
        item: { type: 'draggable', id: dragIndex, index: hoverIndex }
    })
    drag(drop(ref))

    return (
        React.cloneElement(props.children, { cardRef: preview, dragIconRef: ref  })
    );
}