const previewerNavigationReducerDefaultState = {
    isDrawerOpen: false
};

export default (state = previewerNavigationReducerDefaultState, action) => {
    switch (action.type) {
        case "SET_DRAWER_STATE":
            return {
                ...state,
                isDrawerOpen: action.payload
            };
        default:
            return state;
    }
};
  