import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    root: props =>({
        backgroundColor: '#fff',
        cursor: 'grab',
        display: props.isVisible ? 'flex' : 'none',
        flexDirection: 'column',
        width: '100%'
    }),
    title: props =>({
        color: props.options.titleColor,
        fontSize: 18,
        fontWeight: 'bold',
        margin: 0,
        padding: '10px 5px 0 5px',
        textAlign: props.options.textAlign
    }),
    subtitle: props =>({
        color: props.options.subtitleColor,
        fontSize: 15,
        margin: 0,
        padding: '0 5px',
        textAlign: props.options.textAlign
    }),
    text: props =>({
        color: props.options.textColor,
        fontSize: 14,
        margin: 0,
        padding: 5,
        paddingTop: 0,
        textAlign: props.options.textAlign
    }),
    button: props =>({
        alignSelf: 'center',
        backgroundColor: props.buttonColor,
        borderRadius: 4,
        bottom: 0,
        margin: '0 0 20px 0',
        padding: '8px 14px',
    }),
    buttonText: props =>({
        color: props.buttonTextColor,
        fontSize: 14,
        margin: 0,
        textAlign: 'center'
    }),
}));

export default function TextBlock({ data }) {
  const colors = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'colors'));

  const classes = useStyles({...data, ...colors.options});

  return (
    <div className={classes.root}>
        <p className={classes.title}>{data.options.title}</p>
        <p className={classes.subtitle}>{data.options.subtitle}</p>
        <p className={classes.text}>{data.options.text}</p>

        {data.options.showButton &&
            <div className={classes.button}>
                <p className={classes.buttonText}>
                    {_.get(data, 'options.buttonText', 'Ver mais')}
                </p>
            </div>
        }
    </div>
  );
}
