import React from 'react';
import { connect } from "react-redux";
import AuthUserContext from './context';
import { themeActions } from '../_actions/theme.actions';
import { userActions } from '../_actions/user.actions';
import { storeActions } from '../_actions/store.actions';
import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { mobilizeiUsersService } from '../_services/mobilizei/users.service';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isUserLogged: false,
        loading: true,
        signingIn: true
      };
    }

    setUserData(userData){
      const platformId = _.get(userData, 'store.platformId');
      const storeId = _.get(userData, 'store.id');

      if(!userData.store){
        return this.setState({ isUserLogged: false, loading: false });
      }

      // Set platformId
      this.props.setPlatform(platformId);

      // If the user does not contain an activeTheme, set it as classic
      const themeKey = _.get(userData, 'store.activeTheme', 'classic');

      // Get user theme data from API
      mobilizeiStoresService.getThemeById(platformId, storeId, themeKey)
        .then(themeData => {
          // Set theme data on reducer
          this.props.setTheme({
            storeId: storeId,
            key: themeKey,
            ...themeData
          });

          // Set user data on reducer
          this.props.setUser(userData)

          this.setState({ isUserLogged: true, loading: false });
        })
        .catch(() => {
          this.setState({ isUserLogged: false, loading: false });
        })
    }

    componentDidMount() {
      // Try to get the access token from the url or the local storage
      const urlParams = new URLSearchParams(window.location.search);
      let accessToken = urlParams.get('at');

      if(accessToken) {
          window.localStorage.setItem('at', accessToken);
      } else {
          accessToken = window.localStorage.getItem('at');
      }

      // If there is no access token, we return an error
      if(accessToken) {
        mobilizeiUsersService.getSelf()
        .then((userData) => {
          this.setUserData(userData);
          window.clarity("identify", userData.email);
        })
        .catch(() => {
          this.setState({ isUserLogged: false, loading: false });
        })
        .finally(() => {
          this.setState({ signingIn: false });
        })
      } else {
        this.setState({ isUserLogged: false, loading: false, signingIn: false });
      }
    }

    render() {
      if(this.state.loading || this.state.signingIn) {
        return (
          <Grid 
            container
            alignContent="center"
            justify="center"
            style={{ 
              background: '#fff', 
              height: '100vh'
            }}
          >
            <CircularProgress style={{ color: '#3cd568' }} />
          </Grid>
        )
      }

      if(this.state.isUserLogged) {
        return (
          <AuthUserContext.Provider value={this.state.authUser}>
            <Component {...this.props} />
          </AuthUserContext.Provider>
        );
      } else {
        return (
          <Grid 
            container
            alignContent="center"
            justify="center"
            style={{ 
              background: 'radial-gradient(rgb(22, 25, 31) 0%, #2D323E 80%)', 
              height: '100vh'
            }}
          >
            <Card>
              <Typography variant="body1" style={{ maxWidth: 400, padding: 24, textAlign: 'center' }}>
                Erro ao carregar o editor de layout. Por favor, recarregue a página e tente novamente.
              </Typography>
            </Card>
          </Grid>
        )
      }
    }
  }

  return WithAuthentication;
};

const mapDispatchToProps = dispatch => ({
  setTheme: (data) => dispatch(themeActions.setTheme(data)),
  setUser: (data) => dispatch(userActions.setUser(data)),
  setPlatform: (data) => dispatch(storeActions.setPlatform(data))
});

export default WrappedComponent => connect(null, mapDispatchToProps)(
  withAuthentication(WrappedComponent)
);