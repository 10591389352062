import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { FEATURES } from '../_helpers/constants';
import appInfoService from '../_helpers/appInfoService';
import _ from 'lodash';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';

const useStyles = makeStyles(() => ({
    progress: {
        margin: '20px'
    }
}));

function MissingFeaturesModal({ activeMissingFeatures, setAlert, setShowModal, showModal }) {
    const classes = useStyles();

    appInfoService.init();

    const theme = useSelector(state => state.ThemeReducer);
    const storeData = useSelector(state => state.UserReducer.user.store);
    const [changes, setChanges] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [shouldPublishAndroid, setShouldPublishAndroid] = useState(false);
    const [shouldPublishiOS, setShouldPublishiOS] = useState(false);

    useEffect(() => {
        // Set the variables that check the app publish status
        setShouldPublishAndroid(shouldPublishApp('android'));
        setShouldPublishiOS(shouldPublishApp('ios'));

        // Fill the text that will be displayed based on the
        // missing features
        let changeList = [];

        for (let platform in activeMissingFeatures) {
            activeMissingFeatures[platform].forEach(amf => {
                // We are using _.union to guarantee that changeList will have
                // unique values.
                switch(amf){
                    case FEATURES.WISHLIST:
                        changeList = _.union(changeList, ['Ativação da Lista de Desejos']);
                        break
                    case FEATURES.CATEGORY_TITLE_COLOR_CHANGE:
                        changeList = _.union(changeList, ['Alteração da cor do título das categorias']);
                        break
                    case FEATURES.SHIPPING_CALCULATOR:
                        changeList = _.union(changeList, ['Cálculo de frete']);
                        break
                    case FEATURES.STATUS_BAR_FONT_COLOR:
                        changeList = _.union(changeList, ['Cor dos ícones da barra de status']);
                        break
                    case FEATURES.HEADER_VISIBLE:
                        changeList = _.union(changeList, ['Visibilidade do cabeçalho']);
                        break
                    case FEATURES.MENU_ITEMS:
                        changeList = _.union(changeList, ['Itens do menu inferior ou lateral']);
                        break
                    default:
                        break
                }
            });
        }

        setChanges(changeList);
    }, [showModal]);

    const shouldPublishApp = (platform) => {
        return (
            !_.isEmpty(activeMissingFeatures[platform]) &&
            appInfoService.isAppPublished(platform) &&
            !appInfoService.isAppBeingPublished(platform)
        )
    }

    const getPublishStatusText = () => {
        if (shouldPublishAndroid && shouldPublishiOS) {
            return 'Por este motivo, será necessário que você solicite uma nova publicação dos seus aplicativos Android e iOS.';
        }

        if (!shouldPublishAndroid && shouldPublishiOS) {
            return 'Por este motivo, será necessário que você solicite uma nova publicação do seu aplicativo iOS.';
        }

        if (shouldPublishAndroid && !shouldPublishiOS) {
            return 'Por este motivo, será necessário que você solicite uma nova publicação do seu aplicativo Android.';
        }

        return 'Como você já tem uma solicitação de publicação em andamento, basta aguardar a sua conclusão para ver as alterações no seu aplicativo!';
    }

    const handleSave = () => {
        setIsSaving(true);

        mobilizeiStoresService.setTheme(storeData?.platformId, storeData?.id, theme.key, theme)
            .then(() => {
                if (shouldPublishAndroid || shouldPublishiOS) {
                    let message = 'missing_feature_save';
                    message = shouldPublishAndroid ? message + '_android' : message;
                    message = shouldPublishiOS ? message + '_ios' : message;

                    window.top.postMessage(message, '*');
                } else {
                    setIsSaving(false);
                    setShowModal(false);

                    setAlert({
                        open: true,
                        message: 'Configurações salvas com sucesso!',
                        severity: 'success'
                    });
                }
            })
            .catch(() => {
                setIsSaving(false);
                setShowModal(false);

                setAlert({
                    open: true,
                    message: 'Não foi possível salvar as suas configurações. Por favor, tente novamente.',
                    severity: 'error'
                });
            })
    }

    return (
        <Dialog
            open={showModal}
            onClose={() => !isSaving && setShowModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent id="alert-dialog-title">
                <Typography align="justify" variant="h5" color="primary">
                    Republicação necessária
                </Typography>
            </DialogContent>
            <DialogContent>
                <Typography align="justify" variant="subtitle1" className="mb-32" color="textSecondary">
                    As alterações listadas abaixo só terão efeito após a publicação de uma atualização para o seu aplicativo:
                </Typography>
                <Typography align="justify" variant="subtitle1" className="mb-32" color="textSecondary">
                    <ul>
                        {changes.map((change, index) => {
                            return <li key={`feat-${index}`}>{change}</li>
                        })}
                    </ul>
                </Typography>

                <Typography align="justify" variant="subtitle1" className="mb-32" color="textSecondary">
                    {getPublishStatusText()}
                </Typography>

                <Typography align="justify" variant="subtitle1" className="mb-32" color="textSecondary">
                    <br />Você tem certeza que deseja salvar as alterações realizadas?
                </Typography>
            </DialogContent>
            <DialogActions>
                {isSaving ? (
                    <CircularProgress className={classes.progress} />
                ) : (
                    <>
                        <Button onClick={() => setShowModal(false)} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={() => handleSave()} color="secondary" autoFocus>
                            Salvar
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default MissingFeaturesModal;