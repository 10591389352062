const userReducerDefaultState = {
    user: {}
};

export default (state = userReducerDefaultState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.payload
            };
        case "SET_USER_ACCESS_TOKEN":
            return {
                ...state,
                user: {
                    ...state.user,
                    store: {
                        ...state.user.store,
                        accessToken: action.payload
                    }
                }
            };
        case "CLEAR_USER":
            return {
                ...state,
                user: {}
            };
        default:
            return state;
    }
};
  