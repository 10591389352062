import { store } from '../../index';
import { navigationActions } from './navigation.actions';

export const themeActions = {
  setTheme,
  setSelectedArea,
  setSelectedSection,
  setSectionByIndex,
  deleteSelectedSection,
  addSectionToSelectedArea,
};

function setTheme(data) {
  return dispatch => {
    return dispatch({ type: "SET_THEME", payload: data });
  };
}

function setSelectedArea(data) {
  const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;

  return dispatch => {
    return dispatch({ type: "SET_AREA", areaIndex: selectedAreaIndex, data });
  };
}

function setSelectedSection(data) {
  const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;
  const selectedTabIndex = store.getState().NavigationReducer.selectedTabIndex;
  const selectedSectionIndex = store.getState().NavigationReducer.selectedSectionIndex;

  return dispatch => {
    return dispatch({ 
      type: "SET_SECTION", 
      areaIndex: selectedAreaIndex, 
      tabIndex: selectedTabIndex,
      sectionIndex: selectedSectionIndex, 
      data 
    });
  };
}

function setSectionByIndex(sectionIndex, data) {
  const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;
  const selectedTabIndex = store.getState().NavigationReducer.selectedTabIndex;

  return dispatch => {
    return dispatch({ 
      type: "SET_SECTION", 
      areaIndex: selectedAreaIndex, 
      tabIndex: selectedTabIndex,
      sectionIndex, 
      data 
    });
  };
}

function deleteSelectedSection() {
  const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;
  const selectedTabIndex = store.getState().NavigationReducer.selectedTabIndex;
  const selectedSectionIndex = store.getState().NavigationReducer.selectedSectionIndex;

  return dispatch => {
    dispatch(navigationActions.setSelectedSectionIndex(null));

    return dispatch({ 
      type: "DELETE_SECTION", 
      areaIndex: selectedAreaIndex, 
      tabIndex: selectedTabIndex,
      sectionIndex: selectedSectionIndex 
    });
  };
}

function addSectionToSelectedArea(data) {
  const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;

  return dispatch => {
    dispatch(navigationActions.setShowNewSectionPicker(false));

    return dispatch({ type: "ADD_SECTION_TO_AREA", areaIndex: selectedAreaIndex, data });
  };
}