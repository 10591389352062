import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, Grid, Modal, Typography } from '@material-ui/core';
import { themeActions } from '../_actions/theme.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 30
  },
  modal: {
    position: 'absolute',
    width: 400,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  buttonsContainer: {
    justifyContent: 'flex-end'
  },
  modalButton: {
      width: 100
  }
}));

export default function DeleteSectionButton() {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
        <Grid container justify="center">
          <Button
            color="secondary"
            className={classes.button}
            onClick={() => setShowModal(true)}
            size="small"
            startIcon={<FontAwesomeIcon icon={['fas', 'trash']} />}
            variant="contained"
          >
            Excluir seção
          </Button>
        </Grid>

        <Modal
            disableAutoFocus
            disableEnforceFocus
            onClose={() => setShowModal(false)}
            open={showModal}
        >
            <Card className={classes.modal}>
                <CardContent>
                    <Typography gutterBottom variant="h6" align="left">
                        Você tem certeza que deseja excluir essa seção?
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonsContainer}>
                    <Button size="small" variant="contained" className={classes.modalButton} onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button size="small" color="secondary" variant="contained" className={classes.modalButton} onClick={() => dispatch(themeActions.deleteSelectedSection())}>
                        Excluir
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    </div>
  );
}