import React from "react";
import { useSelector } from 'react-redux';
import PriceArea from "./PriceArea";
import ImageArea from "./ImageArea";
import WishlistButton from "../Sections/WishlistButton";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { PLATFORMS } from "../../../Editor/_helpers/constants";

const useStyles = makeStyles(() => ({
  cardArea: {
    minWidth: 375 / 2,
    padding: 5,
    width: 375 / 2
  },
  cardContainer: props =>({
    alignItems: "center",
    backgroundColor: "#fff",
    border: props.showBorder ? '1px solid #ccc' : 0,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    overflow: "hidden"
  }),
  productTitle: props =>({
    color: props.options.titleColor,
    fontSize: 13,
    margin: '8px 0 0 0',
    overflow: 'hidden',
    textAlign: props.textAlign,
    height: `${parseInt(props.titleLinesNumber) * 1.3}em`,
    lineHeight: '1.3em',
    display: '-webkit-box',
    '-webkit-line-clamp': parseInt(props.titleLinesNumber),
    '-webkit-box-orient': 'vertical'
  }),
}));

export default function ProductHalfCard({ product }) {
  const colors = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'colors'));
  const productCardSettings = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'productCardsSettings'));
  const options = _.get(productCardSettings, 'options', {})

  const classes = useStyles({...options, ...colors});
  const platform = useSelector(state => state.StoreReducer.platform);

  return (
    <div className={classes.cardArea}>
      <div className={classes.cardContainer}>
        <div style={{position: 'relative'}}>

        <div style={{position: 'relative'}}>
          <ImageArea images={_.get(product, 'images', [])} settings={options} />
          <WishlistButton screenType={"ProductHalfCard"} />
        </div>

        <div style={{ padding: '0 10px 10px 10px', width: '100%' }}>

          <p className={classes.productTitle}>{_.get(product, 'name', 'Título do produto')}</p>

          <PriceArea
            colors={colors}
            displayOldPrice={_.get(product, 'displayOldPrice', 'R$ 100,00')}
            displayPrice={_.get(product, 'displayPrice', 'R$ 90,00')}
            hasPromotionalPrice={_.get(product, 'hasPromotionalPrice', true)}
            isAvailable={true}
            paymentOption={platform === PLATFORMS.TRAY && _.get(product, 'paymentOption', "R$80,00 à vista com desconto ou 10x de R$ 10,00 Sem juros")}
            settings={options}
          />
          </div>
        </div>
      </div>
    </div>
  );
}