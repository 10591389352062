import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, FormControl, TextField, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShippingCard from './ShippingCard';

const useStyles = makeStyles(() => ({
    title: props =>({
        fontSize: 18,
        position: 'relative',
        textAlign: 'center',
        zIndex: 1,
        '&::before': {
            borderTop: `2px solid ${props.options.titleColor}`,
            content:'""',
            position: 'absolute', 
            top: '50%', 
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1
        },
        '& span': { 
            color: props.options.titleColor,
            background: '#fff', 
            padding: '0 30px' 
        }
    }),
    titleCart: props => ({
        fontSize: 16,
        fontWeight: 'bold',
        padding: '0 0 10px 00',
        borderBottom: `1px solid ${props.options.titleColor}`,
    }),
    formControl: { 
        marginBottom: 10,
        marginTop: -10,
    },
    label: {
        color: '#000'
    },
    form: {
        margin: '10px 10px',
        padding: '5px 15px',
        background: '#fff'
    },
    button: props =>({
        backgroundColor: props.options.buttonColor,
        borderRadius: 2,
        color: props.options.buttonTextColor,
        margin: '15px 0',
        padding: '10px 0',
        width: "100%",
        "&:hover": {
            backgroundColor: props.options.buttonColor,
        }
    }),
    sectionTitle: props => ({
        fontWeight: 'bold'
    }),
    changeCepText: props => ({
        fontSize: 16,
        fontWeight: 'bold',
        textDecorationLine: 'underline',
        textAlign: 'right',
        marginTop: -35,
        cursor: 'pointer'
    }),
    expandButton: props => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        cursor: 'pointer',
        '& p': { 
            textAlign: 'center'
        }
    }),
    icon: {
        fontSize: 14,
        margin: '0'
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        marginBottom: 10,
    },
    summaryLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #c5c5c5',
    },
    summaryLastLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    priceText: props => ({
        color: props.options.priceColor
    }),
    totalText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    totalPriceText: props => ({
        fontSize: 16,
        fontWeight: 'bold',
        color: props.options.priceColor
    })
}));

const shippingOptions = [
    {
        name: 'Correios Normal (PAC)',
        information: `Prazo de Entrega: até 6 dias.\n14/07/2023`,
        displayPrice: 'R$ 17,21',
        price: 17.21,
    },
    {
        name: 'Correios Expresso (SEDEX)',
        information: `Prazo de Entrega: até 2 dias.\n10/07/2023`,
        displayPrice: 'R$ 19,05',
        price: 19.05,
    },
    {
        name: 'PAC MINI',
        information: `6 a 15 dias úteis`,
        displayPrice: 'R$ 21,50',
        price: 21.50
    },
]

const Shipping = ({ colors, products, enableSummary, isCartScreen }) => {

    const classes = useStyles(colors);

    const [ shippingCalculated, setShippingCalculate ] = useState(isCartScreen ? true : false);
    const [ expandedResults, setExpandedResults ] = useState(false);

    const [ selectedIndex, setSelectedIndex ] = useState(0);

    const getCartPrice = () => {
        let totalValue = 0;
    
        for(const product of products){
          totalValue = totalValue + product.price
        }


        if(totalValue.toString().search(/[.]/) == -1){
            return `R$ ${totalValue},00`
        }else{
            const format = totalValue.toString().split('.');

            const before = format[1].slice(0,2).length < 2 
                ? `${format[1].slice(0,2)}0`
                : format[1].slice(0,2)

            return `R$ ${format[0]},${before}`
        }
    }

    const getTotalPrice = () => {
        let totalValue = 0;
    
        for(const product of products){
          totalValue = totalValue + product.price
        }

        if(shippingCalculated){
            totalValue = totalValue + shippingOptions[selectedIndex].price
        }

        if(totalValue.toString().search(/[.]/) == -1){
            return `R$ ${totalValue},00`
        }else{
            const format = totalValue.toString().split('.');

            const before = format[1].slice(0,2).length < 2 
                ? `${format[1].slice(0,2)}0`
                : format[1].slice(0,2)

            return `R$ ${format[0]},${before}`
        }
    }
    
    return (
        <>
            <div className={classes.form}>
                {isCartScreen
                    ? <p className={classes.titleCart}>
                        Frete
                    </p>
                    : <p className={classes.title}>
                        <span>Frete</span>
                    </p>
                }
                

                {!shippingCalculated && (
                    <>
                        <p>Simular frete</p>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField 
                                InputLabelProps={{
                                    className: classes.label,
                                }}
                                label="29177-300"
                            />
                        </FormControl>

                        <Button onClick={() => setShippingCalculate(true)} className={classes.button} variant="contained">CALCULAR FRETE</Button>
                    </>
                )}

                {shippingCalculated && (
                    <>
                        <p className={classes.sectionTitle}>
                            Serra - ES
                        </p>
                        <p>
                            Praça Delzira de Oliveira Silva
                        </p>
                        <p>
                            29177-300
                        </p>
                        <p onClick={() => setShippingCalculate(false)} className={classes.changeCepText}>
                            Alterar CEP
                        </p>
                        <div onClick={() => setExpandedResults(!expandedResults)} className={classes.expandButton}>
                            <p>{expandedResults ? 'Ocultar formas de envio' : 'Exibir formas de envio' }</p>
                            {expandedResults
                                ? <FontAwesomeIcon icon={['fas', 'chevron-up']} className={classes.icon} />
                                : <FontAwesomeIcon icon={['fas', 'chevron-down']} className={classes.icon} />
                            }
                        </div>
                        {expandedResults && (
                            <div className={classes.optionsContainer}>
                                <h5>Selecione uma forma de envio abaixo</h5>

                                {shippingOptions.map((item, index) => (
                                    <ShippingCard
                                        data={item}
                                        colors={colors}
                                        selectedOption={index == selectedIndex ? true : false}
                                        key={index}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>

            {enableSummary && (
                <div className={classes.form}>
                    <p className={classes.titleCart}>
                        Resumo do pedido
                    </p>
                    <div className={classes.summaryLine}>
                        <p>Subtotal</p>
                        <p className={classes.priceText}>{getCartPrice()}</p>
                    </div>
                    {shippingCalculated && (
                        <div className={classes.summaryLine}>
                            <p>{shippingOptions[selectedIndex].name}</p>
                            <p className={classes.priceText}>{shippingOptions[selectedIndex].displayPrice}</p>
                        </div>
                    )}
                    <div className={classes.summaryLastLine}>
                        <p className={classes.totalText}>Total</p>
                        <p className={classes.totalPriceText}>
                            {getTotalPrice()}
                        </p>
                    </div>
                </div>
            )}
        </>
        
    )
}

export default Shipping;