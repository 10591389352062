import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, FormControl, Grid, InputLabel, Select } from '@material-ui/core';
import ColorPicker from '../../../ColorPicker';
import { themeActions } from '../../../../_actions/theme.actions';
import _ from 'lodash';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function Configurations({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
          Configurações
        </Typography>

        <Card elevation={1} square>
          <CardContent>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Formato da imagem</InputLabel>
                <Select
                    native
                    value={data.options.imageShape}
                    onChange={(e) => handleChange('options.imageShape', e.target.value)}
                >
                    <option value='round'>Redonda</option>
                    <option value='square'>Quadrada</option>
                    <option value='rectangle'>Retangular</option>
                </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Posição do título da categoria</InputLabel>
                <Select
                    native
                    value={data.options.titlePosition}
                    onChange={(e) => handleChange('options.titlePosition', e.target.value)}
                >
                    <option value='over'>Sobre a imagem</option>
                    <option value='bellow'>Embaixo da imagem</option>
                </Select>
            </FormControl>

            {data.options.titlePosition === 'over' &&
                <>
                    <FormControl fullWidth>
                        <Typography className={"MuiTypography-root MuiFormLabel-root MuiInputLabel-shrink"}>
                            Nível de transparência
                        </Typography>
                        <Grid alignItems="center" container spacing={2}>
                            <Grid item xs={10}>
                                <Slider 
                                    value={data.options.opacity ?? 50}
                                    onChange={(e, newValue) => handleChange('options.opacity', newValue)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <p>{data.options.opacity}%</p>
                            </Grid>
                        </Grid>
                    </FormControl>

                    <ColorPicker 
                        label='Cor da transparência' 
                        initialColor={data.options.overlayColor} 
                        onChange={(color) => handleChange('options.overlayColor', color)} 
                    />
                </>
            }

          </CardContent>
        </Card>
    </>
  );
}