import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import MbBackground from '../_layout/MbBackground';
import appInfoService from '../../Editor/_helpers/appInfoService';
import { Box } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { baseDeeplink } from '../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  statusBarWrapper: props => ({
    backgroundColor: '#000',
    position: 'absolute',
    zIndex: 999,
    width: '100%',
    top: -0.2
  }),
  statusBar: props => ({
    width: '100%',
    backgroundColor: '#000',
  }),
  titleContent: {
    alignItems: 'center',
    marginBottom: 2,
    marginTop: -60
  },
  title: {
    display: "flex",
    flexDirection: "column",
    position: 'relative',
    
    "& h1": {
      zIndex: 99,
      fontFamily: `Cabin, sans-serif`,
      lineHeight: 1,
      fontWeight: '700',
      textAlign: 'center',
      fontSize: 45,
      '@media (max-height: 764px)': {
        fontSize: 35,
      },
      '@media (max-height: 600px)': {
        fontSize: 35,
      },
      '@media (max-width: 460px)': {
        fontSize: 32,
      },
      '@media (max-width: 321px)': {
        fontSize: 28,
      },
      '@media (max-width: 321px)': {
        fontSize: 24,
      },
    }
  },
  titleDecoration: {
    position: 'relative',
    top: -45,
    right: '15%',
    alignSelf: "flex-end",
    zIndex: 1,
    borderRadius: 20,
    height: 15,
    width: 100,
    backgroundColor: '#3cd568',
    '@media (max-height: 765px)': {
      top: -35,
      height: 10,
      right: 0,
    },
    '@media (max-width: 600px)': {
      top: -35,
      height: 10,
      right: 0,
    },
    '@media (max-width: 460px)': {
      top: -30,
      height: 10,
      right: 0,
    },
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    '@media (max-height: 764px)': {
      padding: 0,
    },
    '@media (max-width: 604px)': {
      padding: '0 20px',
    },
    '@media (max-width: 480px)': {
      padding: 0,
    }
  },
  iconContent: props => ({
    padding: 20,
    borderRadius: 20,
    border: '1px solid #c5c5c5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: props.windowHeight > 550 ? 220 : 140,
    width: props.windowHeight > 550 ? 220 : 140,
  }),
  icon: props => ({
    height: props.windowHeight > 550 ? 220 : 140,
    width: props.windowHeight > 550 ? 220 : 140,
    borderRadius: 20,
  }),
  qrCodeContent: props => ({
    padding: 2,
    border: '3px solid #3cd568',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: props.windowHeight > 550 ? 220 : 140,
    width: props.windowHeight > 550 ? 220 : 140,
  }),
  helpText: {
    fontSize: 14,
    lineHeight: 2,
    color: '#a2a2a2',
    textAlign: 'justify',
    maxWidth: 220,
    '@media (max-height: 764px)': {
      maxWidth: '100%'
    },
  }
}));

export default function WebviewScreen() {
  appInfoService.init();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const classes = useStyles({windowHeight: windowHeight});

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const data = appInfoService.isAndroidAppPublished() && appInfoService.isiOSAppPublished()
    ? {
      helpText: "Edite seu tema, clique no botão salvar e abra seu aplicativo para visualizar as alterações."
    }
    : {
      helpText: "Edite seu tema, clique no botão salvar e escaneie o QR Code para acessar seu aplicativo."
    }

  return (
    <div className={classes.root}>
      <div className={classes.statusBarWrapper}>
        <img src={require('../../Editor/_assets/images/status_bar_light.png')} alt="status_bar" className={classes.statusBar} />
      </div>
      <MbBackground>
        <Box className={classes.content}>
          <div className={classes.titleContent}>
            <div className={classes.title}>
              <h1>Veja seu aplicativo</h1>
              <strong className={classes.titleDecoration} />
            </div>
          </div>

          {appInfoService.isAndroidAppPublished() && appInfoService.isiOSAppPublished()
            ? <Box className={classes.iconContent}>
              <img src={appInfoService.iconUrl} alt='app icon' className={classes.icon} />
            </Box>
            : <Box className={classes.qrCodeContent}>
              <QRCode
                value={`${baseDeeplink}&deep_link_value=${appInfoService.platformId}&deep_link_sub1=${appInfoService.storeId}`}
                size={windowHeight > 550 ? 180 : 120}
              />
            </Box>
          }

          <p className={classes.helpText}>
            {data.helpText}
          </p>
        </Box>
      </MbBackground>
    </div>
  );
}