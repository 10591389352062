import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { themeActions } from '../../../../_actions/theme.actions';
import DragAndDropProvider from '../../../../_helpers/DragAndDropProvider';
import update from 'immutability-helper';
import _ from 'lodash';
import DraggableElement from '../../../../_helpers/DraggableElement';
import CategoryAccordion from './CategoryAccordion';

const useStyles = makeStyles(() => ({
  contentTitle: {
    padding: '10px 15px'
  },
  button: {
    marginTop: 30,
  }
}));

export default function Content({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addCategory = () => {
    let newData = JSON.parse(JSON.stringify(data));
    newData['content'].push({ title: 'Categoria'})
    dispatch(themeActions.setSelectedSection(newData));
  }

  const deleteCategory = (categoryIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData['content'].splice(categoryIndex, 1);
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleCategoryAccordionMove = (dragIndex, hoverIndex) => {
    let categoriesCopy = JSON.parse(JSON.stringify(data.content));
    const dragSection = categoriesCopy[dragIndex];

    categoriesCopy =
      update(categoriesCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })

    let newData = { ...data, content: categoriesCopy }

    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChange = (property, value, categoryIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, `content[${categoryIndex}][${property}]`, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleMultipleChange = (changedData, categoryIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.content[categoryIndex] = {...newData.content[categoryIndex], ...changedData};
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
          Conteúdo
        </Typography>

        <div>
          <DragAndDropProvider>
            {data.content.map((category, index) => (
              <DraggableElement hoverIndex={index} key={`hs-${index}`} onMove={handleCategoryAccordionMove}>
                <CategoryAccordion 
                  category={category}
                  categoryIndex={index}
                  key={`ba-${index}`} 
                  onChange={handleChange}
                  onDelete={() => deleteCategory(index)}
                  onMove={handleCategoryAccordionMove}
                  onMultipleChange={handleMultipleChange}
                />
              </DraggableElement>
            ))}
          </DragAndDropProvider>
        </div>

        <Grid container justify="center">
          <Button
            color="default"
            className={classes.button}
            onClick={() => addCategory()}
            startIcon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
            variant="contained"
          >
            Adicionar nova categoria
          </Button>
        </Grid>
    </>
  );
}