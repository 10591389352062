import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import ColorPicker from '../../ColorPicker';
import { PLATFORMS, NUVEM_PRODUCT_SORT_OPTIONS, TRAY_PRODUCT_SORT_OPTIONS, WIX_PRODUCT_SORT_OPTIONS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  firstFormControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export default function CategorySettings({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.StoreReducer.platform);

  const sortOptions = 
    platform === PLATFORMS.TRAY ?
    TRAY_PRODUCT_SORT_OPTIONS() : platform === PLATFORMS.NUVEMSHOP ?
    NUVEM_PRODUCT_SORT_OPTIONS : WIX_PRODUCT_SORT_OPTIONS

  const getSortOptionHelperText = () => {
    const sortOption = sortOptions.find(opt => opt.value === data.options.defaultSorting);

    if(!!sortOption) {
      return sortOption.helperText;
    }

    return '';
  }

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>

            <ColorPicker
              label='Cor do título da categoria'
              initialColor={_.get(data, 'options.titleFontColor', '#ffffff')}
              onChange={(color) => handleChange('options.titleFontColor', color)}
            />

            <ColorPicker
              label='Cor de fundo do título da categoria'
              initialColor={_.get(data, 'options.titleBackgroundColor', '#000000')}
              onChange={(color) => handleChange('options.titleBackgroundColor', color)}
            />

            <FormControl fullWidth className={classes.firstFormControl}>
                <InputLabel>Ordenação padrão</InputLabel>
                <Select
                    native
                    value={data.options.defaultSorting}
                    onChange={(e) => handleChange('options.defaultSorting', e.target.value)}
                    label="Ordenação padrão"
                >
                  {sortOptions.map((sortOption, index) => {
                    return <option key={`so=${index}`} value={sortOption.value}>{sortOption.label}</option>
                  })}
                </Select>
                <FormHelperText>{getSortOptionHelperText()}</FormHelperText>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Visualização padrão</InputLabel>
                <Select
                    native
                    value={data.options.defaultView}
                    onChange={(e) => handleChange('options.defaultView', e.target.value)}
                    label="Visualização padrão"
                >
                    <option value='grid'>Grid</option>
                    <option value='list'>Lista</option>
                </Select>
            </FormControl>
          </CardContent>
        </Card>
    </main>
  );
}