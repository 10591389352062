import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  root: props =>({
    cursor: 'grab',
    display: !props.isVisible || props.content.length === 0 ? 'none' : 'block',
    marginBottom: -5,
    width: '100%'
  }),
  image: {
      height: 'auto',
      width: '100%'
  },
  slider: props =>({
    '& .slick-dots': {
      display: "block!important",
      bottom: 10,
      '& li button:before': {
        color: `${props.options.pagerColor}!important`,
        opacity: 1
      },
      '& li.slick-active button:before': {
          color: `${props.options.activePagerColor}!important`,
      }
    }
  })
}));

export default function Slideshow({ data }) {
  const classes = useStyles(data);

  return (
    <div className={classes.root}>
      <Slider 
        autoplay={data.options.autoplay}
        autoplaySpeed={parseInt(_.get(data, 'options.autoplaySpeed', 2000))}
        className={classes.slider} 
        dots={data.options.showPager}

        // We are setting the key like this to force the component to rerender when 
        // autoplay changes, otherwise the autoplay dynamically selection will not work
        key={data.options.autoplay} 
      >
        {data.content.map((banner, index) => {
            return <img key={`banner-${index}`} src={banner.url ? banner.url : require('../../../Editor/_assets/images/image_placeholder_350_350.png')} className={classes.image} alt="banner"/>
        })}
      </Slider>
    </div>
  );
}