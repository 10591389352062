import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageUploader from './ImageUploader';
import CategoryPicker from './CategoryPicker';
import ProductPicker from './ProductPicker';

const useStyles = makeStyles((theme) => ({
  imageTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    marginBottom: 0
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
  dragIcon: {
    cursor: 'grab',
    marginTop: 10,
    position: 'absolute',
    right: 0,
    '&:active': {
        cursor: 'grabbing'
    }
  }
}));

export default function BannerCard({ bannerIndex, data, onChange, onMultipleChange, onDelete, showDeleteButton, showWideRatio }) {
  const classes = useStyles();

  const handleUploadSuccess = (data) => {
    onMultipleChange(data, bannerIndex);
  }

  return (
    <div className={classes.container}>
    <Card elevation={1} square>
        <CardContent>
            <ImageUploader 
              label='Imagem' 
              onUploadSuccess={handleUploadSuccess} 
              imageHeight={data.height}  
              imageUrl={data.url} 
              imageWidth={data.width} 
            />
            <FormHelperText>Tamanho recomendado: 800 x 480 px</FormHelperText>

            {showWideRatio &&
              <FormControl fullWidth  className={classes.formControl}>
                  <InputLabel>Tamanho de exibição</InputLabel>
                  <Select
                      native
                      value={data.wideRatio}
                      onChange={(e) => onChange('wideRatio', e.target.value, bannerIndex)} 
                      label="Tamanho de exibição"
                  >
                      <option value='100'>Ocupar toda a largura da tela</option>
                      <option value='50'>Ocupar metade da largura da tela</option>
                  </Select>
              </FormControl>
            }

            <FormControl fullWidth  className={classes.formControl}>
                <InputLabel>Ação</InputLabel>
                <Select
                    native
                    value={data.action}
                    onChange={(e) => onChange('action', e.target.value, bannerIndex)} 
                    label="Ação"
                    inputProps={{ name: 'action' }}
                >
                    <option value='noAction'>Sem ação</option>
                    <option value='goToProduct'>Ir para um produto</option>
                    <option value='goToCategory'>Ir para uma categoria de produtos</option>
                    <option value='goToUrl'>Abrir um link externo</option>
                </Select>
                <FormHelperText>Selecione a ação que irá acontecer ao clicar nesta imagem</FormHelperText>
            </FormControl>
            
            {data.action === 'goToCategory' &&
              <FormControl fullWidth className={classes.formControl}>
                <CategoryPicker
                  selectedCategoryId={data.categoryId || ''}
                  onPicked={(category) => onChange('categoryId', category.id, bannerIndex)} 
                />
                <FormHelperText>Selecione a categoria que será exibida ao clicar na imagem</FormHelperText>
              </FormControl>
            }

            {data.action === 'goToProduct' &&
              <FormControl fullWidth className={classes.formControl}>
                <ProductPicker
                  selectedProductId={data.productId || ''}
                  onPicked={(product) => onChange('productId', product.id, bannerIndex)} 
                />
                <FormHelperText>Selecione o produto que será exibido ao clicar na imagem</FormHelperText>
              </FormControl>
            }

            {data.action === 'goToUrl' &&
              <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Link"
                  value={data.link || ''}
                  onChange={(e) => onChange('link', e.target.value, bannerIndex)} 
                />
                <FormHelperText>Insira o link que será aberto no navegador do celular ao clicar na imagem. Ex.: https://www.minhaloja.com.br/promocao</FormHelperText>
              </FormControl>
            }
        </CardContent>
        {showDeleteButton &&
          <CardActions>
            <Button color="secondary" size="small" onClick={() => onDelete()} startIcon={<FontAwesomeIcon icon={['fas', 'trash']} />}>Excluir banner</Button>
          </CardActions>
        }
    </Card>

    </div>
  );
}