import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from "./Editor/_store/configureStore";
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './App';

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
  </Provider>,
  document.querySelector('#root'),
);
