import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActions } from '../../../../_actions/navigation.actions';
import { themeActions } from '../../../../_actions/theme.actions';
import { Button, Grid, List } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import DragAndDropProvider from '../../../../_helpers/DragAndDropProvider';
import { getSelectedArea } from '../../../../_reducers/theme.reducer';
import DraggableElement from '../../../../_helpers/DraggableElement';
import SectionCard from '../../../SectionCard';

const useStyles = makeStyles(() => ({
  tabContent: {
    backgroundColor: '#f9fafb',
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingTop: 5
  }
}));

export default function AreaSectionsTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedArea = useSelector(state => getSelectedArea(state));

  const handleSectionMove = (dragIndex, hoverIndex) => {
    let sectionsCopy = JSON.parse(JSON.stringify(selectedArea.sections));
    const dragSection = sectionsCopy[dragIndex];

    sectionsCopy =
      update(sectionsCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })

    dispatch(themeActions.setSelectedArea({ ...selectedArea, sections: sectionsCopy }));
  }

  const toogleCardVisibility = (section, sectionIndex) => {
    let newData = {...section};
    newData['isVisible'] = !newData.isVisible
    dispatch(themeActions.setSectionByIndex(sectionIndex, newData));
  }

  return (
    <div className={classes.tabContent}>
        <List className='area-sections-list'>
            <DragAndDropProvider>
                {selectedArea["sections"].map((section, index) => (
                    <DraggableElement hoverIndex={index} key={`hs-${index}`} onMove={handleSectionMove}>
                        <SectionCard 
                          first={index === 0} 
                          onToogleCardVisibility={() => toogleCardVisibility(section, index)}
                          section={section} 
                          sectionIndex={index}
                      />
                    </DraggableElement>

                ))}
            </DragAndDropProvider>
        </List>

        {selectedArea.canAddSections &&
            <Grid container justify="center">
                <Button
                    color="default"
                    className="add-new-section-button"
                    onClick={() => dispatch(navigationActions.setShowNewSectionPicker(true))}
                    startIcon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
                    variant="contained"
                    style={{ margin: '30px 0' }}
                >
                    Adicionar nova seção
                </Button>
            </Grid>
        }
    </div>
  );
}