import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        background: `url(${require('../_assets/img/brand-dots.svg')}) no-repeat -330px 390px`,
        backgroundSize: '100% 100%',
        height: '100%',
        width: '100%',
        position: "absolute",
        '@media (max-width: 600px)': {
            background: `url(${require('../_assets/img/brand-dots.svg')}) no-repeat -330px 850px`,
        },
        '@media (max-height: 1280px)': {
            background: `url(${require('../_assets/img/brand-dots.svg')}) no-repeat -330px 600px`,
        },
        '@media (max-height: 800px)': {
            background: `url(${require('../_assets/img/brand-dots.svg')}) no-repeat -380px 480px`,
        },
        "&:before": {
            background: `url(${require('../_assets/img/brand-circle.svg')}) no-repeat`,
            backgroudOverflow: 'hidden',
            backgroundSize: '100% 100%',
            content: '""',
            height: 530,
            position: "absolute",
            zIndex: 99,
            right: -410,
            top: -200,
            width: 500,
            '@media (max-width: 600px)': {
                right: -300,
                top: -300,
            },
            '@media (max-width: 500px)': {
                right: -350,
                top: -350,
            },
            '@media (max-height: 765px)': {
                right: -300,
                top: -420,
            },
        },
    },
}));

export default function MbBackground({ children }) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} component="main" className={classes.container}>
            {children}
        </Container>
    );
}