import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FlashText from '../_components/Sections/FlashText';
import Banner from '../_components/Sections/Banner';
import Slideshow from '../_components/Sections/Slideshow';
import ProductCarousel from '../_components/Sections/ProductCarousel';
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';
import ProductList from '../_components/Sections/ProductList';
import SocialNetworks from '../_components/Sections/SocialNetworks';
import Separator from '../_components/Sections/Separator';
import TextBlock from '../_components/Sections/TextBlock';
import CategoriesCarousel from '../_components/Sections/CategoriesCarousel';
import ImageCarousel from '../_components/Sections/ImageCarousel';

const useStyles = makeStyles(() => ({
  root: {
      alignContent: 'flex-start',
      backgroundColor: '#fff',
      display:  'flex',
      flexWrap: 'wrap',
      width: '100%'
  }
}))

export default function HomeScreen() {
  const classes = useStyles();
  const selectedArea = useSelector(state => getSelectedArea(state));

  const getSectionComponent = (section, index) => {
    switch(section.key) {
      case 'banner':
        return <Banner data={section} key={`psh-${index}`} />;
      case 'categoriesCarousel':
        return <CategoriesCarousel data={section} key={`psh-${index}`} />;
      case 'flashText':
        return <FlashText data={section} key={`psh-${index}`} />;
      case 'imageCarousel':
        return <ImageCarousel data={section} key={`psh-${index}`} />;
      case 'productCarousel':
        return <ProductCarousel data={section} key={`psh-${index}`} />;
      case 'productList':
        return <ProductList data={section} key={`psh-${index}`} />;
      case 'separator':
        return <Separator data={section} key={`psh-${index}`} />;
      case 'slideshow':
        return <Slideshow data={section} key={`psh-${index}`} />;
      case 'socialNetworks':
        return <SocialNetworks data={section} key={`psh-${index}`} />;
      case 'textBlock':
        return <TextBlock data={section} key={`psh-${index}`} />;
      default:
        break;
    }
  }

  return (
    <div className={classes.root}>
        {selectedArea.sections.map((section, index) => {
            return getSectionComponent(section, index);
        })}
    </div>
  );
}