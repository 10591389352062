import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Grid, Modal, InputLabel, Paper, CircularProgress } from '@material-ui/core';
import MaterialTable from "material-table";
import { flatMappedCategories } from '../_helpers/mappers/nuvemshop';
import tableIcons from '../_helpers/materialTableIcons';
import _ from 'lodash';
import { PLATFORMS } from '../_helpers/constants';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 22,
    width: '100%'
  },
  modal: {
    position: 'absolute',
    width: '70%',
    padding: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  modalButton: {
      margin: 20
  },
  table: {
    boxShadow: 'none'
  }
}));

export default function CategoryPicker({ onPicked, selectedCategoryId }) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  const platform = useSelector(state => state.StoreReducer.platform);
  const categories = useSelector(state => state.StoreReducer.categories);
  const loading = useSelector(state => state.StoreReducer.loadingCategories);
  const [flattenedCategories, setFlattenedCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fmc = flatMappedCategories(categories);
    setFlattenedCategories(fmc);

    if(!!selectedCategoryId) {
      const id = platform === PLATFORMS.WIX ? selectedCategoryId : _.toNumber(selectedCategoryId)
      const sc = fmc.find(c => c.id === id);
      setSelectedCategory(sc);
    }

  }, [categories, selectedCategoryId]);

  const handleRowClick = (data) => {
    setShowModal(false);
    setSelectedCategory(data);

    if(onPicked) {
      onPicked(data)
    }
  }

  return (
    <div>
        <Grid container>
            <InputLabel shrink>Categoria</InputLabel>
            <Button
                className={classes.button}
                onClick={() => setShowModal(true)}
                variant="outlined"
            >
              { loading ? 
                <CircularProgress size={20} /> :
                (_.isEmpty(selectedCategory) ? 'Selecionar categoria' : selectedCategory.breadcrumb)
              }
            </Button>
        </Grid>

        <Modal
            disableAutoFocus
            disableEnforceFocus
            onClose={() => setShowModal(false)}
            open={showModal}
        >
            <Card className={classes.modal}>
              <MaterialTable
                  columns={[
                    { title: 'Categoria', field: 'breadcrumb' }
                  ]}
                  components={{
                    Container: props => <Paper {...props} elevation={0}/>
                  }}
                  data={flattenedCategories}
                  localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}'
                    },
                    toolbar: {
                      searchPlaceholder: 'Buscar',
                      searchTooltip: 'Buscar'
                    },
                    body: {
                        emptyDataSourceMessage: 'Sem categorias para exibir'
                    }
                  }}
                  icons={tableIcons}
                  isLoading={loading}
                  onRowClick={(e, data) => handleRowClick(data)}
                  options={{
                    pageSize: 10,
                    pageSizeOptions: [],
                    header: false
                  }}
                  title="Categorias"
              />
              <Grid container justify='center'>
                  <Button color="secondary" size="small" className={classes.modalButton} onClick={() => setShowModal(false)}>
                      Cancelar
                  </Button>
              </Grid>
            </Card>
        </Modal>
    </div>
  );
}