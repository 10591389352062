import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ColorPicker from '../../ColorPicker'
import { Card, CardContent } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import DeleteSectionButton from '../../DeleteSectionButton';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  button: {
    margin: 30
  }
}));

export default function Separator({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>
            <ColorPicker 
              label='Cor' 
              initialColor={data.options.color} 
              onChange={(color) => handleChange('options.color', color)} 
            />
          </CardContent>
        </Card>

        {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}