import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import ColorPicker from '../../../ColorPicker';
import { themeActions } from '../../../../_actions/theme.actions';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function Configurations({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
          Configurações
        </Typography>

        <Card elevation={1} square>
          <CardContent>
            <ColorPicker 
              label='Cor do título' 
              initialColor={data.options.titleColor} 
              onChange={(color) => handleChange('options.titleColor', color)} 
            />

            <ColorPicker 
              label='Cor do subtítulo' 
              initialColor={data.options.subtitleColor} 
              onChange={(color) => handleChange('options.subtitleColor', color)} 
            />

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Título"
                  value={data.options.title || ''} 
                  onChange={(e) => handleChange('options.title', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Subtítulo"
                  value={data.options.subtitle || ''} 
                  onChange={(e) => handleChange('options.subtitle', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Alinhamento dos títulos</InputLabel>
                <Select
                    native
                    value={data.options.textAlign || ''}
                    onChange={(e) => handleChange('options.textAlign', e.target.value)}
                >
                    <option value='left'>Alinhar à esquerda</option>
                    <option value='center'>Centralizar</option>
                    <option value='right'>Alinhar à direita</option>
                </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Formato da imagem</InputLabel>
                <Select
                    native
                    value={data.options.imageShape}
                    onChange={(e) => handleChange('options.imageShape', e.target.value)}
                >
                    <option value='round'>Redonda</option>
                    <option value='square'>Quadrada</option>
                    <option value='rectangle'>Retangular</option>
                </Select>
            </FormControl>
          </CardContent>
        </Card>
    </>
  );
}