import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  root: props =>({
    alignItems: 'center',
    backgroundColor: '#4dc247',
    borderRadius: 23,
    bottom: props.isTabBarVisible ? 80 : 20,
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.4)',
    display: props.options?.showButton ? 'flex' : 'none',
    height: 46,
    justifyContent: 'center',
    left: props.options?.position === 'bottomLeft' ? 20 : 'auto',
    position: 'absolute',
    right: props.options?.position === 'bottomRight' ? 20 : 'auto',
    width: 46,
    zIndex: 99
  }),
  icon: {
    color: 'white',
    fontSize: 30,
    textAlign: 'center',
  }
}));

export default function WhatsAppButton({ isTabBarVisible, selectedScreen }) {
  const data = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'whatsAppButton'));
  const classes = useStyles({...data, isTabBarVisible});

  const showButton = () => {
    if(selectedScreen === 'contactScreen' ||
      selectedScreen === 'productDetailsScreen' ||
      selectedScreen === 'cartScreen' ||
      !_.get(data, 'options.showButton', false)
    ) {
        return false;
    }

    return true;
  }

  if(showButton()) {
    return (
      <div className={classes.root}>
        <FontAwesomeIcon icon={['fab', 'whatsapp']} className={classes.icon} />
      </div>
    );
  }

  return null;
}