import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import CategoryCard from './CategoryCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  categoryThumbnail: {
    height: 'auto',
    marginRight: 10,
    width: 30
  },
  dragIcon: {
    cursor: 'grab',
    position: 'absolute',
    right: 0,
    top: 5,
    '&:active': {
        cursor: 'grabbing'
    }
  },
  icon: {
    fontSize: 16,
    textAlign: 'center'
  },
  actionIcon: {
      fontSize: 20
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    padding: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    marginRight: 0,
    order: -1
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0
  },
}))(MuiAccordionDetails);

export default function CategoryAccordion({ category, categoryIndex, cardRef, dragIconRef, onChange, onDelete, onMove, onMultipleChange }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded}>
        <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} className={classes.icon} />}
            onClick={() => setExpanded(!expanded)}
            ref={cardRef}
        >
            <img 
              src={category.url ? category.url : require('../../../../_assets/images/image_placeholder_350_350.png')} 
              className={classes.categoryThumbnail} 
              alt="category"
            />
            <Typography className={classes.heading}>{category.title}</Typography>
            <IconButton className={classes.dragIcon} ref={dragIconRef} onDrag={() => setExpanded(false)}>
              <FontAwesomeIcon icon={['fas', 'grip-vertical']} className={classes.actionIcon} fixedWidth />
            </IconButton>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            <CategoryCard   
                categoryIndex={categoryIndex} 
                data={category}
                onChange={onChange}
                onDelete={onDelete}
                onMultipleChange={onMultipleChange}
                showDeleteButton 
            />
        </AccordionDetails>
    </Accordion>
  );
}