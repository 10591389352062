import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';
import _ from 'lodash'

const useStyles = makeStyles({
  root: props =>({
    alignItems: 'center',
    backgroundColor: props.options.backgroundColor,
    bottom: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-evenly',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 10
  }),
  button: props =>({
    alignItems: 'center',
    color: props.options.iconColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 75
  }),
  activeButton: props =>({
    alignItems: 'center',
    color: props.options.activeIconColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 75
  }),
  icon: {
    fontSize: 18
  },
  label: { 
      fontSize: 12,
      margin: '4px 0 0 0',
      textAlign: 'center'
  }
});

export default function TabBar() {
  const data = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'tabBar'));
  const selectedArea = useSelector(state => getSelectedArea(state));
  const classes = useStyles(data);
  
  if(!data.options.isVisible) {
    return null;
  }

  const getActiveTab = (section, index) => {
    var activeExists = false;

    if(_.isEmpty(data?.content)){
      return index == 0
    }

    for(const item of data.content){
      if(item.relatedScreens && item.relatedScreens.includes(selectedArea.key)){
        activeExists = true
      }
    }

    if(activeExists){
      return section.relatedScreens && section.relatedScreens.includes(selectedArea.key)
    }else{
      return index == 0
    }
  }
  
  return (
    <ul className={classes.root}>
      {data.content.map((section, index) => (
        <li 
          className={getActiveTab(section, index) ? classes.activeButton : classes.button} 
          key={`tbb-${index}`} 
          style={{ display: section.isVisible ? 'flex' : 'none' }}
        >
          {section.icon && (
            <FontAwesomeIcon icon={[section.icon.style, section.icon.name]} className={classes.icon} fixedWidth />
          )}
          <p className={classes.label}>{section.title}</p>
        </li>
      ))}
    </ul>
  );
}