import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import ImageUploader from '../../ImageUploader';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  helperText: {
      marginTop: -8
  }
}));

export default function LoginScreenSettings({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleUploadSuccess = (uploadData) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData = {...newData, options: { ...newData.options, logo: uploadData }};
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>
            <FormControlLabel
                control={
                    <Checkbox
                      checked={_.isNil(data.options.showLogo) ? false : data.options.showLogo}
                      onChange={() => handleChange('options.showLogo', !data.options.showLogo)} 
                      color="primary"
                    />
                }
                label="Exibir logo"
            />

            {data.options.showLogo && 
              <>
                <ImageUploader 
                  imageUrl={_.get(data, 'options.logo.url', null)} 
                  label='Logo' 
                  onUploadSuccess={handleUploadSuccess} 
                />
                <FormHelperText>Tamanho recomendado: 420 x 300 px</FormHelperText>
              </>
            }
          </CardContent>
        </Card>
    </main>
  );
}