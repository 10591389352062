import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { themeActions } from '../../../_actions/theme.actions';
import { navigationActions } from '../../../_actions/navigation.actions';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { AREA_SECTIONS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '20px 15px 5px 15px'
  },
  listItem: {
      backgroundColor: '#fff',
  },
  border: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12);'
  },
  cancelButton: {
      marginTop: 20
  },
  icon: {
    fontSize: '1.5rem',
    textAlign: 'center'
  },
  materialIcon: {
    fontSize: '2rem',
    textAlign: 'center'
  }
}));

export default function NewSectionPicker() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const platform = useSelector(state => state.StoreReducer.platform);

    const getSectionIcon = (key) => {
        switch(key) {
            case 'slideshow':
                return <FontAwesomeIcon icon={['fas', 'images']} className={classes.icon} fixedWidth />;
            case 'banner':
                return <FontAwesomeIcon icon={['fas', 'image']} className={classes.icon} fixedWidth />;
            case 'categoriesCarousel':
                return <ViewColumnIcon className={classes.materialIcon} />
            case 'flashText':
                return <FontAwesomeIcon icon={['fas', 'font']} className={classes.icon} fixedWidth />;
            case 'imageCarousel':
                return <SettingsEthernetIcon className={classes.materialIcon} />
            case 'productCarousel':
                return <ViewCarouselIcon className={classes.materialIcon} />
            case 'productList':
                return <FontAwesomeIcon icon={['fas', 'tags']} className={classes.icon} fixedWidth />;
            case 'separator':
                return <FontAwesomeIcon icon={['fas', 'divide']} className={classes.icon} fixedWidth />;
            case 'socialNetworks':
                return <FontAwesomeIcon icon={['fas', 'thumbs-up']} className={classes.icon} fixedWidth />;
            case 'textBlock':
                return <FontAwesomeIcon icon={['fas', 'align-left']} className={classes.icon} fixedWidth />;
            default:
                return null;
        }
    }

    return (
        <main className={classes.content}>
            <p variant="overline" display="block" className={classes.contentTitle}>
                Selecione abaixo o tipo de seção que você deseja adicionar:
            </p>

            <List className={classes.secIndex}>
                {AREA_SECTIONS(platform).map((section, secIndex) => (
                    <div key={`sec-${secIndex}`}>
                        <Typography variant="overline" display="block" className={classes.contentTitle}>
                            {section.title}
                        </Typography>

                        {section.items.map((item, itemIndex) => (
                            <ListItem 
                                button 
                                classes={{ root: classes.listItem, divider: itemIndex === 0 ? classes.border : null }}
                                divider
                                key={`sec-${secIndex}-item-${itemIndex}`}
                                onClick={() => dispatch(themeActions.addSectionToSelectedArea(item))}
                            >
                                <ListItemIcon>
                                    {getSectionIcon(item.key)}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        ))}
                    </div>
                ))}
            </List>

            <Grid container justify="center" className={classes.cancelButton}>
                <Button color="secondary" size="small" onClick={() => dispatch(navigationActions.setShowNewSectionPicker(false))} >Cancelar</Button>
            </Grid>
        </main>
    );
}