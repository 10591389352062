import { apiFetcher } from "./apiFetcher";
import qs from "qs";

export const productsService = {
  getProducts,
  getProductById,
  getProductVariants,
  searchProducts
};

function getProducts(filters) {
  const url = `/products?published=true&${qs.stringify(filters)}`;

  return apiFetcher(url, { method: "GET" }, 1, true);
}

function getProductById(productId) {
  return apiFetcher(`/products/${productId}`, {
    method: "GET"
  })
}

function getProductVariants(productId) {
  return apiFetcher(
    `/products/${productId}/variants?per_page=200`,
    {
      method: "GET"
    }
  )
}

function searchProducts(filters, query) {
  const url = `/products?published=true&q=${query}&${qs.stringify(filters)}`

  return apiFetcher(url, { method: "GET" }, 1, true);
}
