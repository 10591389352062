import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PLATFORMS } from '../../../Editor/_helpers/constants';

import { makeStyles } from '@material-ui/core/styles';
import Shipping from '../Shipping/Shipping';

import { getSelectedArea } from '../../../Editor/_reducers/theme.reducer';
import _, { isEmpty } from 'lodash';
import { productsActions } from '../../../Editor/_actions/products.actions';
import CartItem from './CartItem';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: props => ({
    width: '100%'
  }),
  summary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 10px',
    padding: '0px 15px',
    background: '#fff'
  },
  summaryText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  priceText: props => ({
    fontSize: 16,
    fontWeight: 'bold',
    color: props.options.priceColor
  }),
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    height: '100%',
    width: '100%',
  },
}));

const CartScreen = () => {
  const dispatch = useDispatch();
  const selectedArea = useSelector(state => getSelectedArea(state));
  const data = selectedArea.sections.find(s => s.key === 'cartScreen');
  const sections = _.get(selectedArea, 'sections', []);
  const settings = sections.find(s => s.key === 'cartScreenSettings');
  const options = _.get(settings, 'options', {});

  const platform = useSelector(state => state.StoreReducer.platform);

  const colors = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'colors'));
  const classes = useStyles(colors);

  const [ products, setProducts ] = useState([]);

  useEffect(() => {
    const defaultSorting = _.get(data, 'options.defaultSorting', null);
    const filters = platform === PLATFORMS.TRAY ? {
          limit: 3,
          sort: _.isEmpty(defaultSorting) ? 'release_desc' : defaultSorting
      } : {
          per_page: 3,
          sort_by: _.isEmpty(defaultSorting) ? 'created-descending' : defaultSorting
      }

      dispatch(productsActions.getProducts(filters))
        .then(response => {
          setProducts([response.products[0], response.products[2]]);
        })
        .catch(() => {
          setProducts([]);
        })
  }, []);

  if(isEmpty(products)){
    return (
      <div className={classes.loadingWrapper}>
        <CircularProgress color="primary"/> 
      </div>
    )
  }

  const getCartPrice = () => {
    let totalValue = 0;

    for(const product of products){
      totalValue = totalValue + product?.price
    }


    if(totalValue.toString().search(/[.]/) == -1){
      return `R$ ${totalValue},00`
    }else{
      const format = totalValue.toString().split('.');

      const before = format[1].slice(0,2).length < 2 
          ? `${format[1].slice(0,2)}0`
          : format[1].slice(0,2)

      return `R$ ${format[0]},${before}`
    }
  }

  return (
    <div className={classes.root}>
      {products.map((item, index) => (
        <CartItem colors={colors} product={item} />
      ))}
      {PLATFORMS.TRAY && options.showShipping
        ? <Shipping colors={colors} isCartScreen={true} enableSummary={true} products={products} />
        : <div className={classes.summary}>
          <p className={classes.summaryText}>
            Total do Pedido
          </p>
          <p className={classes.priceText}>
            {!isEmpty(products) && getCartPrice()}
          </p>
        </div>
      }
    </div>

  )
}

export default CartScreen;