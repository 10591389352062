import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: props =>({
    display: props.isVisible ? 'block' : 'none',
    height: 'auto',
    marginBottom: -5,
    width: `${props.options.wideRatio}%`
  }),
  image: {
      height: 'auto',
      width: '100%'
  }
}));

export default function Banner({ data }) {
  const classes = useStyles(data);

  return (
    <div className={classes.root}>
      <img 
        alt="banner"
        className={classes.image} 
        src={data.options.url ? data.options.url : require('../../../Editor/_assets/images/image_placeholder_350_350.png')} 
      />
    </div>
  );
}