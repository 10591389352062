import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, InputLabel, Select } from '@material-ui/core';
import ColorPicker from '../../../ColorPicker';
import { themeActions } from '../../../../_actions/theme.actions';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function Configurations({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
          Configurações
        </Typography>

        <Card elevation={1} square>
          <CardContent>

            <FormControlLabel
              control={
                  <Checkbox
                      checked={data.options.autoplay || false} 
                      onChange={() => handleChange('options.autoplay', !data.options.autoplay)}
                      name="autoplay"
                      color="primary"
                  />
              }
              label="Rotação automática"
            />

            {data.options.autoplay &&
              <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Trocar de banner a cada:</InputLabel>
                  <Select
                      native
                      value={data.options.autoplaySpeed}
                      onChange={(e) => handleChange('options.autoplaySpeed', e.target.value)}
                  >
                      <option value='2000'>2 segundos</option>
                      <option value='3000'>3 segundos</option>
                      <option value='4000'>4 segundos</option>
                      <option value='5000'>5 segundos</option>
                      <option value='6000'>6 segundos</option>
                  </Select>
              </FormControl>
            }

            <FormControlLabel
              control={
                  <Checkbox
                      checked={data.options.showPager || false}
                      onChange={() => handleChange('options.showPager', !data.options.showPager)}
                      name="showPager"
                      color="primary"
                  />
              }
              label="Exibir paginação"
            />

            {data.options.showPager &&
              <>
                <ColorPicker 
                  label='Cor do ponto de paginação' 
                  initialColor={data.options.pagerColor} 
                  onChange={(color) => handleChange('options.pagerColor', color)} 
                />

                <ColorPicker 
                  label='Cor do ponto de paginação ativo' 
                  initialColor={data.options.activePagerColor} 
                  onChange={(color) => handleChange('options.activePagerColor', color)} 
                />
              </>
            }
          </CardContent>
        </Card>
    </>
  );
}