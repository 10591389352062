import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Select, TextField } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  helperText: {
    marginBottom: theme.spacing(3),
    marginTop: -8
  }
}));

export default function WhatsAppButton({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={_.isNil(data.options.showButton) ? false : data.options.showButton}
                        color="primary"
                        onChange={() => handleChange('options.showButton', !data.options.showButton)} 
                    />
                }
                label="Exibir botão flutuante do WhatsApp"
            />
            <FormHelperText className={classes.helperText}>O botão flutuante abre o aplicativo do WhatsApp com o chat aberto para o seu número</FormHelperText>



            {data.options.showButton && 
              <>
                <FormControl fullWidth className={classes.formControl}>
                    <TextField 
                        helperText={"Insira o DDD do número do seu WhatsApp. Ex: 11"}
                        label="DDD" 
                        value={data.options.areaCode || ''}
                        onChange={(e) => handleChange('options.areaCode', e.target.value)} 
                        type="number"
                    />
                </FormControl>


                <FormControl fullWidth className={classes.formControl}>
                    <TextField 
                        helperText={"Insira número do seu WhatsApp. Ex: 99123-8070"}
                        label="Número" 
                        value={data.options.phoneNumber || ''}
                        onChange={(e) => handleChange('options.phoneNumber', e.target.value)} 
                    />
                </FormControl>

                <FormControl fullWidth  className={classes.formControl}>
                    <InputLabel>Posição do botão</InputLabel>
                    <Select
                        native
                        value={data.options.position ||  ''}
                        onChange={(e) => handleChange('options.position', e.target.value)} 
                        label="Posicão do botão"
                    >
                        <option value='bottomRight'>Canto inferior direito</option>
                        <option value='bottomLeft'>Canto inferior esquerdo</option>
                    </Select>
                </FormControl>

              </>
            }
          </CardContent>
        </Card>
    </main>
  );
}