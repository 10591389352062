const mapNuvemCategory = category => {
    let mappedCategory = {
        id: category.id,
        name: category.name.pt,
        parentId: category.parent
    }

    return mappedCategory;
}

const mapCategoriesTree = (items, id = 0, link = 'parentId', parentName = '') => {
    items.sort((a,b) => { return a.name.localeCompare(b.name) });

    return items
        .filter(item => item[link] === id)
        .map(item => {
          const breadcrumb = 
            parentName === '' ?
            item.name :
            `${parentName} > ${item.name}`

          return { 
            ...item, 
            breadcrumb,
            subcategories: mapCategoriesTree(items, item.id, 'parentId', breadcrumb) 
          };
        });
}

export const mapNuvemCategories = (categories) => {
    let mappedCategories = categories.reduce((result, category) => {
        result.push(mapNuvemCategory(category));
        return result;
    }, []);

    return mapCategoriesTree(mappedCategories);
};

export const flatMappedCategories = (categories, sortBy = 'breadcrumb') => {
  let a = [];
  for(let i = 0; i < categories.length; i++){
    let o = categories[i];
    if(o.subcategories){
      var c = flatMappedCategories(o.subcategories);
      if(c){
          a = a.concat(c);
      }
    }
    a.push(o)
  }
  
  return a.sort((a,b) => { return a[sortBy].localeCompare(b[sortBy]) });
}

export const mapNuvemProduct = product => {
    let mappedProduct = {};
  
    mappedProduct["id"] = product.id;
    mappedProduct["name"] = product.name.pt;
    mappedProduct["brand"] = product.brand;
  
    mappedProduct["description"] = product.description.pt
      ? product.description.pt.replace(/[\n\r]/g, "")
      : "";
  
    mappedProduct["url"] = product.canonical_url;
  
    // Price variables to help setting other properties. We are always going
    // to have at least one variant, so we get the price from the first one.
    const nuvemPrice = isNaN(parseFloat(product.variants[0].price))
      ? 0
      : parseFloat(product.variants[0].price);
    const nuvemPromotionalPrice = isNaN(parseFloat(product.variants[0].promotional_price))
      ? 0
      : parseFloat(product.variants[0].promotional_price);
    const hasNuvemPromotinalPrice =
      !!nuvemPromotionalPrice && 
      (nuvemPromotionalPrice > 0);
  
    // Set product prices
    mappedProduct["price"] = hasNuvemPromotinalPrice
      ? nuvemPromotionalPrice
      : nuvemPrice;
    mappedProduct["oldPrice"] = hasNuvemPromotinalPrice ? nuvemPrice : 0;
  
    // Set product display prices
    mappedProduct["displayPrice"] = `R$ ${mappedProduct["price"]
      .toFixed(2)
      .replace(".", ",")}`;
    mappedProduct["displayOldPrice"] = `R$ ${mappedProduct["oldPrice"]
      .toFixed(2)
      .replace(".", ",")}`;
  
    // Set the discount percentage
    mappedProduct["discountPercentage"] =
      mappedProduct["oldPrice"] > 0
        ? Math.floor(
            (1 - mappedProduct["price"] / mappedProduct["oldPrice"]) * 100
          )
        : 0;
  
    // Set product info booleans
    mappedProduct["hasPromotionalPrice"] = hasNuvemPromotinalPrice;
    mappedProduct["hasVariant"] = product.variants && product.variants.length > 0;
  
    // Map product images to array
    mappedProduct["images"] =
      product.images && product.images.length > 0
        ? product.images.reduce((result, element) => {
            result.push({
              imageUrl: element.src
            });
            return result;
          }, [])
        : [];
  
    return mappedProduct;
  };
  
  export const mapNuvemVariants = product => {
    let mappedVariants = [];
  
    product.variants.forEach(variant => {
      // Tray price variables to help setting other properties
      const nuvemPrice = isNaN(parseFloat(variant.price))
        ? 0
        : parseFloat(variant.price);
      const nuvemPromotionalPrice = isNaN(parseFloat(variant.promotional_price))
        ? 0
        : parseFloat(variant.promotional_price);
      const hasNuvemPromotinalPrice =
        !!nuvemPromotionalPrice && 
        (nuvemPromotionalPrice > 0);
  
      // Set product prices
      const price = hasNuvemPromotinalPrice
        ? nuvemPromotionalPrice
        : nuvemPrice;
      const oldPrice = hasNuvemPromotinalPrice ? nuvemPrice : 0;
  
      // Set the display price
      const displayPrice = `R$ ${price.toFixed(2).replace(".", ",")}`;
      const displayOldPrice = `R$ ${oldPrice.toFixed(2).replace(".", ",")}`;
  
      // Build the sku list
      let skuList = {}
      for(let i = 0; i < product.attributes.length; i++) {
        const attributeName = product.attributes[i].pt;
        if (skuList[attributeName] === undefined) {
          skuList[attributeName] = "";
        }
        skuList[attributeName] = variant.values[i].pt;
      }
  
      // Set the product stock (if the stock is null, Nuvemshop considers it infinite)
      const stock = variant.stock === null ? 999999 :
          isNaN(parseInt(variant.stock))
          ? 0
          : parseInt(variant.stock);
  
      // Set product info booleans:
      // On request
      // stock > 0 && price === 0
      // isAvailable: false / isOnRequest: true
  
      // Sold out
      // stock === 0 && price === 0
      // isAvailable: false / isOnRequest: false
  
      // Sold out
      // stock === 0 && price > 0
      // isAvailable: false / isOnRequest: false
  
      // Available
      // stock > 0 && price > 0
      // isAvailable: true / isOnRequest: false
      const isAvailable = stock > 0 && price > 0;
      const isOnRequest = stock > 0 && price === 0;
  
      // Get the variant images from product images array
      let images = [];
      const image = product.images.find(image => {
        return image.id === variant.image_id
      })
  
      // Check if the variant has an image
      if(image) {
        images.push({
          imageUrl: image.src
        })
      } else {
        // If the variant does not have an image, set the product images
        // as the variant images
        images = product.images.map(image => {
          return {
            imageUrl: image.src
          }
        })
      }
  
      mappedVariants.push({
        Sku: skuList,
        Price: price,
        DisplayPrice: displayPrice,
        OldPrice: oldPrice,
        DisplayOldPrice: displayOldPrice,
        HasPromotionalPrice: hasNuvemPromotinalPrice,
        Id: variant.id,
        Images: images,
        IsAvailable: isAvailable,
        IsOnRequest: isOnRequest,
        Stock: stock
      });
      
    });
  
    return mappedVariants;
  }
  
  export const mapNuvemVariantOptions = (product, hasDifferentVariantPrices) => {
    let mappedVariantOptions = {};
  
    product.variants.forEach(variant => {
      const skus = variant.values;
      const includePrice = (skus.length === 1 && hasDifferentVariantPrices)
      const variantPrice = variant.price;
      const variantDisplayPrice = !!variantPrice ? `R$ ${variantPrice.replace(".", ",")}` : "";
  
      for(let i = 0; i < skus.length; i++) {
        const attributeName = product.attributes[i].pt;
        if (mappedVariantOptions[attributeName] === undefined) {
          mappedVariantOptions[attributeName] = [];
        }
        
        if(!mappedVariantOptions[attributeName].some(e => e.value === variant.values[i].pt)) {
          if(includePrice) {
            mappedVariantOptions[attributeName].push({
              value: variant.values[i].pt,
              price: variantDisplayPrice
            });
          } else {
            mappedVariantOptions[attributeName].push({
              value: variant.values[i].pt
            });
          }
        }
      }
    })
  
    // Sort variant options by name
    for (const key in mappedVariantOptions) {
      mappedVariantOptions[key].sort((a,b) => { return a.value.localeCompare(b.value) });
    }
  
    return mappedVariantOptions;
  }
  
  export const mapNuvemProductWithVariants = (product) => {
    let mappedProduct = mapNuvemProduct(product);
    const mappedVariants = mapNuvemVariants(product);
  
    // Set product variants
    mappedProduct["variants"] = mappedVariants; 
  
    // Indicates if these product variants have different 
    // prices between any of them
    mappedProduct["hasDifferentVariantPrices"] = false; 
  
    let previousVariantPrice = null;
    mappedVariants.forEach(variant => {
      // Set the cheapest variant price as the mappedProduct
      // price and displayPrice
      if(variant.IsAvailable) {
        if(mappedProduct["price"] > variant.Price || mappedProduct["price"] === 0) {
          mappedProduct["price"] = variant.Price;
          mappedProduct["displayPrice"] = variant.DisplayPrice;
        }
      }
  
      // Check if the variants price are diferent
      if(!!previousVariantPrice && previousVariantPrice !== variant.Price) {
        mappedProduct["hasDifferentVariantPrices"] = true;
      }
  
      // Set the current variant price as previousVariantPrice
      // to the next loop
      previousVariantPrice = variant.Price;
    });
  
    // Set product availability
    mappedProduct["isAvailable"] = mappedProduct["variants"].some(variant => {
      return variant.IsAvailable;
    });
  
    // Set product availability
    mappedProduct["isOnRequest"] = mappedProduct["variants"].some(variant => {
      return variant.IsOnRequest;
    });
  
    // Set variants options
    mappedProduct["variantOptions"] = mapNuvemVariantOptions(product, mappedProduct["hasDifferentVariantPrices"]);
  
    return mappedProduct;
  }
  
  export const mapNuvemProductArrayWithVariants  = (products) => {
    return products.map(product => mapNuvemProductWithVariants(product));
  }