import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    title: props =>({
        fontSize: 18,
        position: 'relative',
        textAlign: 'center',
        zIndex: 1,
        '&::before': {
            borderTop: `2px solid ${props.options.titleColor}`,
            content:'""',
            margin: '0 20px',
            position: 'absolute', 
            top: '50%', 
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1
        },
        '& span': { 
            color: props.options.titleColor,
            background: '#fff', 
            padding: '0 30px' 
        }
    }),
    description: {
        padding: '0 20px',
        '& img': {
            maxWidth: '100%!important',
            height: 'auto!important'
        },
        '& h1, h2': {
            display: 'none'
        },
        '& p, span, font': {
            color: '#000!important',
            fontSize: '15px!important'
        },
        '& tr td span font': {
            fontWeight: 'bold!important',
            color: '#000!important'
        },
        '& *': {
            maxWidth: '100%!important',
        }
    }
}));

export default function DescriptionContainer({ colors, product }) {
  const classes = useStyles(colors);

  return (
    <>
        <p className={classes.title}>
            <span>Descrição</span>
        </p>

        {_.isEmpty(product.description) ? (
            <div className={classes.description}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu tincidunt turpis, vitae rhoncus sapien. Aliquam dapibus hendrerit semper. Morbi condimentum rutrum neque non rutrum. Pellentesque pulvinar urna non feugiat egestas. Sed dictum magna vel lectus ultricies vestibulum. Phasellus mollis consequat quam sed consectetur. Donec tristique est et nisl lacinia semper. Pellentesque ut quam vel quam aliquam finibus. Cras facilisis malesuada leo, quis rutrum diam posuere eget. Integer iaculis tempus luctus. Phasellus dignissim arcu non tellus condimentum, at porttitor turpis finibus. Proin placerat risus ac quam pharetra molestie. Pellentesque ipsum arcu, fringilla ac turpis sed, tristique bibendum eros.</p>
                <p>Sed vitae volutpat orci, sed ullamcorper felis. Praesent ac rhoncus magna, vitae imperdiet metus. Phasellus ut quam a nibh finibus viverra. Maecenas rhoncus quam sit amet placerat lacinia. Aliquam erat volutpat. Nulla egestas elementum eros, eu accumsan metus malesuada quis. Aenean leo tellus, dignissim sit amet ornare eu, semper non neque. In aliquet magna ligula, et venenatis est egestas a. Quisque in tincidunt nisi. Suspendisse blandit purus ac purus sodales faucibus. Pellentesque et rhoncus massa.</p>
                <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam fringilla odio tellus. Pellentesque facilisis mollis tempus. Morbi sit amet dignissim enim, vitae tincidunt elit. Donec finibus nunc eros, in cursus eros fermentum ornare. Nam vulputate tincidunt lacus nec vulputate. Etiam bibendum vulputate euismod. Vestibulum id eros et dui lobortis rutrum. Sed consequat lorem tincidunt metus ullamcorper, in molestie sapien mattis. Donec lobortis justo et orci rutrum, non venenatis leo condimentum. Donec tincidunt libero eget massa accumsan, a faucibus magna volutpat. Curabitur eleifend venenatis arcu vitae porttitor. Donec ut pulvinar velit. Aenean eleifend sollicitudin nulla ut laoreet. Aenean tincidunt fringilla efficitur.</p>
            </div>
        ) : (
            <div className={classes.description} dangerouslySetInnerHTML={{ __html: product.description }} />
        )}
    </>
  );
};