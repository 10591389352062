import _ from 'lodash';

const mapTrayCategory = (category, parentName = '') => {
  const breadcrumb = 
    parentName === '' ?
    category.Category.name :
    `${parentName} > ${category.Category.name}`

  let mappedCategory = {
    breadcrumb,
    id: _.toNumber(category.Category.id),
    name: category.Category.name,
    subcategories: []
  }

  if(category.Category.children && category.Category.children.length > 0) {
    category.Category.children.forEach(child => {
      let mappedSubcategory = mapTrayCategory(child, breadcrumb);
      mappedCategory.subcategories.push(mappedSubcategory);
    })

    // Sort subcategories by name
    mappedCategory.subcategories.sort((a,b) => { return a.name.localeCompare(b.name) });
  }

  return mappedCategory;
}

export const mapTrayCategories = (categories) => {
  let mappedCategories = categories.Category.reduce((result, category) => {
    result.push(mapTrayCategory(category));
    return result;
  }, []);

  // Sort categories by name
  mappedCategories.sort((a,b) => { return a.name.localeCompare(b.name) });

  return mappedCategories;
};

const isOnPromotionalPeriod = (startDate, endDate) => {
  if(startDate && endDate) {
    const sd = startDate.split("-");
    const ed = endDate.split("-");
    
    const from = new Date(sd[0], parseInt(sd[1])-1, sd[2]);  // -1 because months are from 0 to 11
    const to   = new Date(ed[0], parseInt(ed[1])-1, ed[2]);
    const check = Date.now();
  
    return (check > from && check < to);
  }

  return false;
}

export const mapTrayProduct = product => {
  let mappedProduct = {};

  mappedProduct["id"] = product.id;
  mappedProduct["name"] = product.name;
  mappedProduct["reference"] = product.reference;
  mappedProduct["brand"] = product.brand;

  mappedProduct["description"] = product.description
    ? product.description.replace(/[\n\r]/g, "")
    : "";

  mappedProduct["paymentOption"] = 
    product.payment_option ? 
    product.payment_option
      .replace('&agrave;', 'à')
      .replace('&aacute;', 'á')
      .replace('&atilde;', 'ã')
      .replace('&acirc;', 'â')
      .replace('&ograve;', 'ò')
      .replace('&oacute;', 'ó')
      .replace('&otilde;', 'õ')
      .replace('&ocirc;', 'ô')
      .replace('&eacute;', 'é')
      .replace('&ecirc;', 'ê')
    : '';

  mappedProduct["url"] =
    product.url && product.url.https ? product.url.https : "";

  // Set product stock
  mappedProduct["stock"] = isNaN(parseFloat(product.stock))
    ? 0
    : parseFloat(product.stock);

  // Tray price variables to help setting other properties
  const trayPrice = isNaN(parseFloat(product.price))
    ? 0
    : parseFloat(product.price);
  const trayPromotionalPrice = isNaN(parseFloat(product.promotional_price))
    ? 0
    : parseFloat(product.promotional_price);
  const hasTrayPromotinalPrice =
    !!trayPromotionalPrice && 
    (trayPromotionalPrice > 0) && 
    isOnPromotionalPeriod(product.start_promotion, product.end_promotion);

  // Set product prices
  mappedProduct["price"] = hasTrayPromotinalPrice
    ? trayPromotionalPrice
    : trayPrice;
  mappedProduct["oldPrice"] = hasTrayPromotinalPrice ? trayPrice : 0;

  // Set product display prices
  mappedProduct["displayPrice"] = `R$ ${mappedProduct["price"]
    .toFixed(2)
    .replace(".", ",")}`;
  mappedProduct["displayOldPrice"] = `R$ ${mappedProduct["oldPrice"]
    .toFixed(2)
    .replace(".", ",")}`;

  // Set the discount percentage
  mappedProduct["discountPercentage"] =
    mappedProduct["oldPrice"] > 0
      ? Math.floor(
          (1 - mappedProduct["price"] / mappedProduct["oldPrice"]) * 100
        )
      : 0;

  // Set the boleto price information
  mappedProduct["hasBoletoPrice"] =
    product.payment_option_details !== 'undefined' &&
    product.payment_option_details.length > 0 &&
    product.payment_option_details.some(elem => elem.type === "bank_billet");

  mappedProduct["boletoPrice"] = 
    mappedProduct["hasBoletoPrice"] 
    ? product.payment_option_details.filter(elem => elem.type === "bank_billet")[0].value
    : 0;

  mappedProduct["displayBoletoPrice"] = `R$ ${parseFloat(mappedProduct["boletoPrice"])
    .toFixed(2)
    .replace(".", ",")}`;

  // Check if product is customizable
  mappedProduct["isCustomizable"] =
    product.AdditionalInfos && product.AdditionalInfos.length > 0
      ? true
      : false;

  // If product is customizable, get the additional infos
  if (mappedProduct["isCustomizable"]) {
    mappedProduct["additionalInfos"] = product.AdditionalInfos.reduce(
      (result, element) => {
        // Get the additional info value
        const value = isNaN(parseFloat(element.value))
          ? 0
          : parseFloat(element.value);

        // Format the additional info value
        const displayValue = `R$ ${value.toFixed(2).replace(".", ",")}`;

        // Create the additional info object
        let additionalInfo = {
          Name: element.name,
          Type: element.type,
          Value: value,
          DisplayValue: displayValue
        };

        // Check if the additional info has options attached to it
        if (element["options"] && Object.keys(element["options"]).length > 0) {
          additionalInfo["Options"] = [];

          // Iterate through the options values
          Object.values(element["options"]).forEach(option => {
            // Get the option value
            const optionValue = isNaN(parseFloat(option.value))
              ? 0
              : parseFloat(option.value);

            // Format the option value
            const optionDisplayValue = `R$ ${optionValue
              .toFixed(2)
              .replace(".", ",")}`;

            // Add the option value to the current additional info Options array
            additionalInfo["Options"].push({
              Name: option.name,
              DisplayValue: optionDisplayValue,
              Value: optionValue
            });
          });
        }

        result.push(additionalInfo);
        return result;
      },
      []
    );
  }

  // Set product info booleans
  mappedProduct["hasPromotionalPrice"] = hasTrayPromotinalPrice;
  mappedProduct["hasVariant"] = product.Variant && product.Variant.length > 0;
  mappedProduct["isOffer"] = hasTrayPromotinalPrice && product.release !== "1";
  mappedProduct["isRelease"] = product.release === "1";
  mappedProduct["isAvailable"] = product.available_for_purchase === "1";

  // Set if this is a "on request" product
  mappedProduct["isOnRequest"] = mappedProduct["isAvailable"] 
    && !hasTrayPromotinalPrice 
    && mappedProduct["price"] === 0 
    && mappedProduct["oldPrice"] === 0;

  // Map product images to array
  mappedProduct["images"] =
    product.ProductImage && product.ProductImage.length > 0
      ? product.ProductImage.reduce((result, element) => {
          result.push({
            imageUrl: element.https
          });
          return result;
        }, [])
      : [];

  // Main category
//   mappedProduct["mainCategory"] = {};
  
//   if(product.all_categories && product.all_categories.length > 0) {
//     const mainCategoryId = product.all_categories[0];
//     const categories = store.getState().SettingsReducer.categories;
  
//     if(mainCategoryId && categories) {
//       const category = categories.find(cat => cat.Id === parseInt(mainCategoryId));
  
//       if(category) {
//         mappedProduct["mainCategory"] = {id: mainCategoryId, name: category.Name }
//       }
//     }
//   }

  return mappedProduct;
};

export const mapTrayVariantOptions = (variants, hasDifferentVariantPrices) => {
  let mappedVariantOptions = {};

  variants.forEach(variant => {
    if (variant.Variant && 
      variant.Variant.Sku &&
      variant.Variant.available === "1" && 
      variant.Variant.Sku.length > 0
    ) {

      const skus = variant.Variant.Sku;
      const includePrice = (skus.length === 1 && hasDifferentVariantPrices)
      const variantPrice = variant.Variant.price;
      const variantDisplayPrice = !!variantPrice ? `R$ ${variantPrice.replace(".", ",")}` : "";

      skus.forEach(sku => {
        if (mappedVariantOptions[sku.type] === undefined) {
          mappedVariantOptions[sku.type] = [];
        }

        if(!mappedVariantOptions[sku.type].some(e => e.value === sku.value)) {
          if(includePrice) {
            mappedVariantOptions[sku.type].push({
              value: sku.value,
              price: variantDisplayPrice
            });
          } else {
            mappedVariantOptions[sku.type].push({
              value: sku.value
            });
          }
        }
      })
  }})

  // Sort variant options by name
  for (const key in mappedVariantOptions) {
    mappedVariantOptions[key].sort((a,b) => { return a.value.localeCompare(b.value) });
  }

  return mappedVariantOptions;
}

export const mapTrayVariants = variants => {
  let mappedVariants = [];

  variants.forEach(variant => {
    if (variant.Variant && 
        variant.Variant.Sku &&
        variant.Variant.available === "1" && 
        variant.Variant.Sku.length > 0
      ) {

      // Tray price variables to help setting other properties
      const trayPrice = isNaN(parseFloat(variant.Variant.price))
        ? 0
        : parseFloat(variant.Variant.price);
      const trayPromotionalPrice = isNaN(parseFloat(variant.Variant.promotional_price))
        ? 0
        : parseFloat(variant.Variant.promotional_price);
      const hasTrayPromotinalPrice =
        !!trayPromotionalPrice && 
        (trayPromotionalPrice > 0) && 
        isOnPromotionalPeriod(variant.Variant.start_promotion, variant.Variant.end_promotion);

      // Set product price
      let price = hasTrayPromotinalPrice
        ? trayPromotionalPrice
        : trayPrice;

      // Set the display price
      const displayPrice = `R$ ${price.toFixed(2).replace(".", ",")}`;

      // Build the sku list
      let skuList = {}
      variant.Variant.Sku.forEach(sku => {
        if (skuList[sku.type] === undefined) {
          skuList[sku.type] = "";
        }
        skuList[sku.type] = sku.value;
      })

      // Map product images to array
      let images =
      variant.Variant.VariantImage && variant.Variant.VariantImage.length > 0
        ? variant.Variant.VariantImage.reduce((result, element) => {
            result.push({
              imageUrl: element.https,
            });
            return result;
          }, [])
        : [];

      const paymentOption = 
        variant.Variant.payment_option ? 
        variant.Variant.payment_option
          .replace('&agrave;', 'à')
          .replace('&aacute;', 'á')
          .replace('&atilde;', 'ã')
          .replace('&acirc;', 'â')
          .replace('&ograve;', 'ò')
          .replace('&oacute;', 'ó')
          .replace('&otilde;', 'õ')
          .replace('&ocirc;', 'ô')
          .replace('&eacute;', 'é')
          .replace('&ecirc;', 'ê')
        : '';

      mappedVariants.push({
        sku: skuList,
        price: price,
        displayPrice: displayPrice,
        id: variant.Variant.id,
        images: images,
        paymentOption: paymentOption,
        stock: isNaN(parseInt(variant.Variant.stock))
          ? 0
          : parseInt(variant.Variant.stock)
      });
    }
  });

  return mappedVariants;
}

export const mapTrayProductWithVariants = (product, variants) => {
  let mappedProduct = mapTrayProduct(product);
  const mappedVariants = mapTrayVariants(variants);

  // Set product variants
  mappedProduct["variants"] = mappedVariants; 

  // Indicates if these product variants have different 
  // prices between any of them
  mappedProduct["hasDifferentVariantPrices"] = false; 

  let previousVariantPrice = null;
  mappedVariants.forEach(variant => {
    // Set the cheapest variant price as the mappedProduct
    // price and displayPrice
    if(mappedProduct["price"] > variant.Price) {
      mappedProduct["price"] = variant.Price;
      mappedProduct["displayPrice"] = variant.DisplayPrice;
    }

    // Check if the variants price are diferent
    if(!!previousVariantPrice && previousVariantPrice !== variant.Price) {
      mappedProduct["hasDifferentVariantPrices"] = true;
    }

    // Set the current variant price as previousVariantPrice
    // to the next loop
    previousVariantPrice = variant.Price;
  });

  // Set variants options
  mappedProduct["variantOptions"] = mapTrayVariantOptions(variants, mappedProduct["hasDifferentVariantPrices"]);

  return mappedProduct;
}

export const mapTrayProductArray  = (products) => {
  return products.map(product => mapTrayProduct(product.Product));
}