import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, InputLabel, Select, Typography } from '@material-ui/core';
import ColorPicker from '../../ColorPicker';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import { PLATFORMS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function ProductDetailsSettings({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.StoreReducer.platform);

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={_.isNil(data.options.showOldPrice) ? false : data.options.showOldPrice}
                        color="primary"
                        onChange={() => handleChange('options.showOldPrice', !data.options.showOldPrice)} 
                    />
                }
                label="Exibir preço antigo"
            />

            {platform === PLATFORMS.TRAY &&
              <FormControlLabel
                control={
                    <Checkbox
                      checked={_.isNil(data.options.showInstallments) ? false : data.options.showInstallments}
                      onChange={() => handleChange('options.showInstallments', !data.options.showInstallments)}
                      color="primary"
                    />
                }
                label="Exibir opções de parcelamento"
              />
            }

            <FormControlLabel
                control={
                    <Checkbox
                      checked={_.isNil(data.options.showQuantitySelector) ? false : data.options.showQuantitySelector}
                      onChange={() => handleChange('options.showQuantitySelector', !data.options.showQuantitySelector)}
                      color="primary"
                    />
                }
                label="Exibir seletor de quantidade"
            />

            <FormControlLabel
              control={
                  <Checkbox
                      checked={data.options.autoplay || false} 
                      onChange={() => handleChange('options.autoplay', !data.options.autoplay)}
                      name="autoplay"
                      color="primary"
                  />
              }
              label="Rotação automática das imagens"
            />

            {data.options.autoplay &&
              <FormControl fullWidth className={classes.formControl}>
                  <InputLabel>Trocar de banner a cada:</InputLabel>
                  <Select
                      native
                      value={data.options.autoplaySpeed}
                      onChange={(e) => handleChange('options.autoplaySpeed', e.target.value)}
                  >
                      <option value='2000'>2 segundos</option>
                      <option value='3000'>3 segundos</option>
                      <option value='4000'>4 segundos</option>
                      <option value='5000'>5 segundos</option>
                      <option value='6000'>6 segundos</option>
                  </Select>
              </FormControl>
            }

            <FormControlLabel
              control={
                  <Checkbox
                      checked={data.options.showPager || false}
                      onChange={() => handleChange('options.showPager', !data.options.showPager)}
                      name="showPager"
                      color="primary"
                  />
              }
              label="Exibir paginação nas imagens"
            />

            {data.options.showPager &&
              <>
                <ColorPicker 
                  label='Cor do ponto de paginação' 
                  initialColor={data.options.pagerColor} 
                  onChange={(color) => handleChange('options.pagerColor', color)} 
                />

                <ColorPicker 
                  label='Cor do ponto de paginação ativo' 
                  initialColor={data.options.activePagerColor} 
                  onChange={(color) => handleChange('options.activePagerColor', color)} 
                />
              </>
            }

            {platform === PLATFORMS.TRAY && (
              <>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={_.isNil(data.options.showShipping) ? false : data.options.showShipping}
                            color="primary"
                            onChange={() => handleChange('options.showShipping', !data.options.showShipping)} 
                        />
                    }
                    label="Exibir cálculo de frete"
                />
              </>
            )}
          </CardContent>
        </Card>
    </main>
  );
}