import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ColorPicker from '../../ColorPicker'
import { Card, CardContent } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import { PLATFORMS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function Colors({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const platform = useSelector(state => state.StoreReducer.platform);

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>
            <ColorPicker 
              label='Cor dos títulos' 
              initialColor={data.options.titleColor} 
              onChange={(color) => handleChange('options.titleColor', color)} 
            />

            <ColorPicker 
              label='Cor do preço' 
              initialColor={data.options.priceColor} 
              onChange={(color) => handleChange('options.priceColor', color)} 
            />

            <ColorPicker 
              label='Cor do preço antigo' 
              initialColor={data.options.oldPriceColor} 
              onChange={(color) => handleChange('options.oldPriceColor', color)} 
            />

            {platform === PLATFORMS.TRAY &&
              <ColorPicker 
                label='Cor das opções de parcelamento' 
                initialColor={data.options.installmentsColor} 
                onChange={(color) => handleChange('options.installmentsColor', color)} 
              />
            }

            <ColorPicker 
              label='Cor dos botões' 
              initialColor={data.options.buttonColor} 
              onChange={(color) => handleChange('options.buttonColor', color)} 
            />

            <ColorPicker 
              label='Cor do texto dos botões' 
              initialColor={data.options.buttonTextColor} 
              onChange={(color) => handleChange('options.buttonTextColor', color)} 
            />
          </CardContent>
        </Card>
    </main>
  );
}