import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Previewer from '../../../Previewer/Previewer';
import Tour from '../Tour';
import Header from './Header';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(9),
    position: 'relative'
  },
  tourWrapper: {
    bottom: 0,
    left: 0,
    position: 'absolute'
  }
}));

export default function MainContent({ handleSideMenuToggle }) {
  const classes = useStyles();
  const storeData = useSelector(state => state.UserReducer.user.store); 

  const isWebviewTheme = storeData.activeTheme == 'webview';

  return (
    <main className={classes.content}>
      <Header handleSideMenuToggle={handleSideMenuToggle} />

      <div className={classes.toolbar} />

      {!isWebviewTheme && (
        <div className={classes.tourWrapper}>
          <Tour />
        </div>
      )}

      <Previewer />
    </main>
  );
}
