import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  root: (props) =>({
    alignItems: 'center',
    backgroundColor: props.backgroundColor,
    borderRadius: 23,
    boxShadow: props.options.showIconBackground ? '2px 2px 6px rgba(0, 0, 0, 0.4)' : 'none',
    display: props.showButton ? 'flex' : 'none',
    height: props.isProductCard ? 34 : 42,
    justifyContent: 'center',
    position: 'absolute',
    left: props.marginLeft,
    top: props.marginTop,
    width: props.isProductCard ? 34 : 42,
    zIndex: 1
  }),
  icon: (props) =>({
    color: props.options.iconColor,
    fontSize:  props.isProductCard ? 24 : 30,
    textAlign: 'center',
  })
}));

export default function WishlistButton({screenType}) {
  const props = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'wishlist'));
  const propsCard = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'productCardsSettings'));
  const cardImageSquare = propsCard.options.imageShape === 'square'
  const cardImageDimenFill = propsCard.options.resizeMode === 'fill'

  //Need to modify file
  const data = JSON.parse(JSON.stringify(props));
  const showWishlist = data.showWishlist
  const showWishlistInProductCard = data.options.showIconInProductCard
  const showWishlistInProduct = data.options.showIconInProductScreen
  const showIconBackground = data.options.showIconBackground

  const isProductCard = screenType !== 'ProductScreen'

  const backgroundColor = showIconBackground ? "#FFF" : "transparent" 
  const iconPosition = isProductCard ? data.options.iconPositionInProductCard : data.options.iconPositionInProductScreen
  
  const showButton = () => {
    if(isProductCard){
        return showWishlist && showWishlistInProductCard
    }else{
        return showWishlist && showWishlistInProduct
    }  
  }

  let marginLeft = "0%"
  let marginTop = "0%"

  switch(screenType){

    case 'ProductFullCard':
      marginLeft = iconPosition.includes("left") ? "4%" : "73%"
      marginTop = iconPosition.includes("top") ? cardImageSquare ? "3%": cardImageDimenFill ? "3%" : "17%" : cardImageSquare ? "73%": cardImageDimenFill ? "80%": "66%"
    break

    case 'ProductHalfCard':
      marginLeft = iconPosition.includes("left") ? "4%" : "77%"
      marginTop = iconPosition.includes("top") ? cardImageSquare ? "3%": cardImageDimenFill? "3%": "17%" : cardImageSquare ? "76%": cardImageDimenFill ? "83%" : "66%"
    break

    case 'ProductScreen':
      marginLeft = iconPosition.includes("left") ? "7%" : "82%"
      marginTop = iconPosition.includes("top") ? "7%" : "82%"
    break
  }

  data.isProductCard = isProductCard
  data.iconPosition = iconPosition
  data.backgroundColor = backgroundColor
  data.showButton = showButton()
  data.marginLeft = marginLeft
  data.marginTop = marginTop
  const classes = useStyles({...data});
  

  if(showButton()) {
    return (
      <div className={classes.root}>
        <FontAwesomeIcon icon={['far', 'heart']} className={classes.icon} />
      </div>
    );
  }

  return null;
}