import { store } from "../../index";

export const SCREEN_OPTIONS = [
    { key: 'home', link: 'HomeStack', relatedScreens: 'homeScreen' },
    { key: 'contact', link: 'ContactStack', relatedScreens: 'contactScreen' },
    { key: 'account', link: 'AuthStack', relatedScreens: 'loginScreen' },
    { key: 'cart', link: 'CartStack', relatedScreens: '' },
    { key: 'categories', link: 'CategoriesStack', relatedScreens: 'categoryScreen,productDetailsScreen' },
    { key: 'wishlist', link: 'WishlistStack', relatedScreens: '' },
]

export const SCREEN_LABELS = {
    home: 'Início',
    contact: 'Contatos',
    account: 'Minha conta',
    cart: 'Carrinho',
    categories: 'Categorias',
    wishlist: 'Desejos'
}

export const STACKS_LIST = {
    goToScreen: 'HomeStack',
    goToProduct: 'ProductStack',
    goToCategory: 'CategoryStack',
    goToUrl: 'StandaloneStack',
    goToWebview: 'WebViewStack',
    goToWhatsapp: 'StandaloneStack'
} 

export const FEATURES = {
    CATEGORY_TITLE_COLOR_CHANGE: 'category_title_color_change',
    WISHLIST: 'wishlist',
    SHIPPING_CALCULATOR: 'shipping_calculator',
    STATUS_BAR_FONT_COLOR: 'status_bar_font_color',
    HEADER_VISIBLE: 'header_visible',
    MENU_ITEMS: 'menu_items'
}

export const PLATFORMS = {
    TRAY: 'tray',
    NUVEMSHOP: 'nuvemshop',
    WIX: 'wix',
    WEBVIEW: 'webview'
}

export const STORE_STATUS = {
    ON_TRIAL: "on_trial",
    TRIAL_ENDED: "trial_ended",
    ACTIVE: "active",
    SUSPENDED: "suspended",
    ARCHIVED: "archived"
}

export const NUVEM_PRODUCT_SORT_OPTIONS = [
    { value: 'created-descending', label: 'Mais Novo ao mais Antigo' },
    { value: 'created-ascending', label: 'Mais Antigo ao mais Novo' },
    { value: 'price-ascending', label: 'Preço: Menor ao Maior' },
    { value: 'price-descending', label: 'Preço: Maior ao Menor' },
    { value: 'alpha-ascending', label: 'A - Z' },
    { value: 'alpha-descending', label: 'Z - A' },
    { value: 'best-selling', label: 'Mais Vendidos' },
    { value: 'user', label: 'Ordenação manual', helperText: 'A ordem pela qual os produtos serão exibidos ao selecionar a "Ordenação manual" é definida dentro do painel de controle da sua loja Nuvemshop na seção "Ordem dos produtos"' },
]

export const TRAY_PRODUCT_SORT_OPTIONS = () => {
    let sortOptions = [
        { value: 'created_desc', label: 'Mais novos' },
        { value: 'modified_desc', label: 'Últimos produtos atualizados' },
        { value: 'release_desc', label: 'Lançamento' },
        { value: 'price_asc', label: 'Menor preço' },
        { value: 'price_desc', label: 'Maior preço' },
        { value: 'name_asc', label: 'Nome do produto' },
    ]

    // If the Tray store is ON_TRIAL, we consider it will not have installed the Mobilizei
    // app. So we don't have the at and need to make the requests without it. In this case,
    // the quantity_sold_desc sort option does not work. So we can add it to the sortOptions
    // only if the store is not on trial.
    if (store.getState().UserReducer.user.store.status !== STORE_STATUS.ON_TRIAL) {
        sortOptions.push({ value: 'quantity_sold_desc', label: 'Mais vendidos' });
    }

    return sortOptions;
}

export const WIX_PRODUCT_SORT_OPTIONS = [
    { value: 'createdDate-desc', label: 'Mais Novo ao mais Antigo' },
    { value: 'createdDate-asc', label: 'Mais Antigo ao mais Novo' },
    { value: 'price-asc', label: 'Preço: Menor ao Maior' },
    { value: 'price-desc', label: 'Preço: Maior ao Menor' },
    { value: 'name-asc', label: 'A - Z' },
    { value: 'name-desc', label: 'Z - A' }
]

export const AREA_SECTIONS = (platform) => [
    {
        title: "Imagens",
        items: [
            {
                key: "banner",
                title: "Banner",
                showCardActions: true,
                isVisible: true,
                options: {
                    action: "noAction",
                    wideRatio: 100
                }
            },
            {
                key: "slideshow",
                title: "Banners circulantes",
                showCardActions: true,
                isVisible: true,
                options: {
                    showPager: true,
                    pagerColor: "#000",
                    activePagerColor: "#fff",
                },
                content: [{}]
            },
            {
                key: "imageCarousel",
                title: "Carrossel de imagens",
                showCardActions: true,
                isVisible: true,
                options: {
                    title: 'Título do carrossel',
                    titleColor: '#000',
                    subtitle: 'Subtítulo do carrossel',
                    subtitleColor: '#999',
                    textAlign: 'left',
                    imageShape: 'round',
                },
                content: [{}, {}, {}, {}, {}]
            }
        ]
    },
    {
        title: 'Produtos',
        items: [
            {
                key: "productCarousel",
                title: "Carrossel de produtos",
                showCardActions: true,
                isVisible: true,
                options: {
                    carouselTitle: 'Título do carrossel',
                    carouselTitleColor: '#000',
                    carouselSubtitle: 'Subtítulo do carrossel',
                    carouselSubtitleColor: '#999',
                    textAlign: 'left',
                    numberOfProducts: '6'
                }
            },
            {
                key: "productList",
                title: "Lista de produtos",
                showCardActions: true,
                isVisible: true,
                options: {
                    title: 'Título da lista',
                    titleColor: '#000',
                    subtitle: 'Subtítulo da lista',
                    subtitleColor: '#999',
                    textAlign: 'left',
                    numberOfProducts: '6',
                    defaultSorting: platform === PLATFORMS.TRAY ? 'release_desc' : 'created-descending',
                    defaultView: 'grid',
                    productsOrigin: 'general',
                    showButton: true,
                    buttonText: 'Ver mais'
                }
            }
        ]
    },
    {
        title: 'Categorias',
        items: [
            {
                key: "categoriesCarousel",
                title: "Carrossel de categorias",
                showCardActions: true,
                isVisible: true,
                options: {
                    imageShape: 'round',
                    overlayColor: '#000',
                    titlePosition: 'over',
                    opacity: 50
                },
                content: [
                    { title: 'Categoria' },
                    { title: 'Categoria' },
                    { title: 'Categoria' },
                    { title: 'Categoria' },
                    { title: 'Categoria' }
                ]
            }
        ]
    },
    {
        title: "Texto",
        items: [
            {
                key: "textBlock",
                title: "Bloco de texto",
                showCardActions: true,
                isVisible: true,
                options: {
                    title: 'Título',
                    titleColor: '#000',
                    subtitle: 'Subtítulo',
                    subtitleColor: '#999',
                    textAlign: 'center',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo nulla quam. Donec nec imperdiet lectus. Sed consequat neque eu tempor blandit.',
                    textColor: '#000',
                    showButton: true,
                    buttonText: 'Ver mais',
                    action: 'noAction'
                }
            },
            {
                key: "flashText",
                title: "Faixa de texto",
                showCardActions: true,
                isVisible: true,
                options: {
                    fontSize: "medium",
                    backgroundColor: "#000",
                    fontColor: "#fff",
                    text: 'Seu texto vai aparecer aqui',
                    textAlign: 'center',
                }
            }
        ]
    },
    {
        title: "Outros",
        items: [
            {
                key: 'separator',
                title: 'Divisor',
                showCardActions: true,
                isVisible: true,
                options: {
                    color: '#000',
                }
            },
            {
                key: 'socialNetworks',
                title: 'Redes sociais',
                showCardActions: true,
                isVisible: true,
                options: {
                    title: 'Redes sociais',
                    titleColor: '#000',
                    textAlign: 'left'
                },
                content: [
                    {
                        name: 'Instagram',
                        icon: {
                            name: 'instagram',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://instagram.com/meuperfil'
                    },
                    {
                        name: 'Facebook',
                        icon: {
                            name: 'facebook-f',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://facebook.com/meuperfil'
                    },
                    {
                        name: 'Youtube',
                        icon: {
                            name: 'youtube',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://www.youtube.com/user/meuperfil'
                    },
                    {
                        name: 'TikTok',
                        icon: {
                            name: 'tiktok',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://www.tiktok.com/@meuperfil'
                    },
                    {
                        name: 'Twitter',
                        icon: {
                            name: 'twitter',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://twitter.com/meuperfil'
                    },
                    {
                        name: 'Pinterest',
                        icon: {
                            name: 'pinterest-p',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://pinterest.com/meuperfil'
                    },
                    {
                        name: 'Snapchat',
                        icon: {
                            name: 'snapchat-ghost',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://www.snapchat.com/add/meuperfil'
                    },
                    {
                        name: 'Linkedin',
                        icon: {
                            name: 'linkedin-in',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://linkedin.com/in/meuperfil'
                    },
                    {
                        name: 'Tumblr',
                        icon: {
                            name: 'tumblr',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://meuperfil.tumblr.com/'
                    },
                    {
                        name: 'Vimeo',
                        icon: {
                            name: 'vimeo-v',
                            style: 'fab'
                        },
                        isVisible: true,
                        helperText: 'Adicione o link completo do seu pefil. Ex.: https://vimeo.com/meuperfil'
                    }
                ]
            }
        ]
    }
]