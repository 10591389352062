import { store } from '../../index';
import { createReducer } from '@reduxjs/toolkit'

const themeReducerDefaultState = {
    storeId: '',
    areas: [
        {
            key: 'homeScreen',
            title: 'Home',
            canAddSections: true,
            sections: []
        },
        {
            key: 'categoryScreen',
            title: 'Categorias',
            sections: [
                {
                    key: "categorySettings",
                    title: "Configuração das telas de categorias",
                    showCardActions: false,
                    options: {
                        defaultSorting: "",
                        defaultView: "grid"
                    }
                 }
            ]
        },
        {
            key: 'productDetailsScreen',
            title: 'Produto',
            sections: [
                {
                    key: "productDetailsSettings",
                    title: "Configuração das telas de produtos",
                    showCardActions: false,
                    options: {
                        activePagerColor: "#fff",
                        pagerColor: "#000",
                        showInstallments: true,
                        showOldPrice: true,
                        showPager: true,
                        showQuantitySelector: true,
                        showShipping: false
                    }
                 }
            ]
        },
        {
            key: 'loginScreen',
            title: 'Login',
            sections: [
                {
                    key: "loginScreenSettings",
                    title: "Configuração da tela de login",
                    options: {
                        showLogo: true
                    }
                 }
            ]
        },
        {
            key: 'contactScreen',
            title: 'Contatos',
            sections: [
                {
                    key: "contactScreenSettings",
                    title: "Configuração da tela de contatos",
                    content: []
                 }
            ]
        },
        {
            key: 'cartScreen',
            title: 'Carrinho',
            sections: [
                {
                    key: "cartScreenSettings",
                    title: "Configuração da tela de carrinho",
                    showCardActions: false,
                    options: {
                        showShipping: true
                    }
                 }
            ]
        },
    ],
    settings: [
        {
            key: "header",
            title: "Cabeçalho",
            showCardActions: false,
            options: {
                backgroundColor: "#3f51b5",
                iconColor: "#fff",
                showCartIcon: true,
                showLogo: false,
                textColor: "#fff",
                statusBarTextColor: '#fff'
            }
        },
        {
            key: "drawerMenu",
            title: "Menu lateral",
            showCardActions: false,
            options: {
                backgroundColor: "#3f51b5",
                isVisible: true,
                showCategoriesList: true,
                showLogo: true,
                textColor: "#fff",
            },
            content: [
                {
                    key: "home",
                    relatedScreens: "homeScreen",
                    title: "Início",
                    isVisible: true,
                    action: "goToScreen",
                    link: "HomeStack"
                },
                {
                    key: "categories",
                    relatedScreens: "categoryScreen",
                    title: "Categorias",
                    isVisible: true,
                    action: "goToScreen",
                    link: "CategoriesStack"
                },
                {
                    key: "contact",
                    title: "Contatos",
                    isVisible: true,
                    action: "goToScreen",
                    link: "ContactStack"
                },
                {
                    key: "cart",
                    title: "Carrinho",
                    isVisible: true,
                    action: "goToScreen",
                    link: "CartStack"
                },
                {
                    key: "account",
                    title: "Minha conta",
                    isVisible: true,
                    action: "goToScreen",
                    link: "AuthStack"
                },
            ]
        },
        {
            key: "tabBar",
            title: "Menu inferior",
            showCardActions: false,
            options: {
                activeIconColor: "#000",
                backgroundColor: "#3f51b5",
                iconColor: "#fff",
                isVisible: true
            },
            content: [
                {
                    key: "home",
                    title: "Início",
                    relatedScreens: "homeScreen",
                    isVisible: true,
                    action: "goToScreen",
                    link: "HomeStack"
                },
                {
                    key: "categories",
                    title: "Categorias",
                    relatedScreens: "categoryScreen,productDetailsScreen",
                    isVisible: true,
                    action: "goToScreen",
                    link: "CategoriesStack"
                },
                {
                    key: "contact",
                    title: "Contatos",
                    relatedScreens: "contactScreen",
                    isVisible: true,
                    action: "goToScreen",
                    link: "ContactStack"
                },
                {
                    key: "cart",
                    title: "Carrinho",
                    relatedScreens: "",
                    isVisible: true,
                    action: "goToScreen",
                    link: "CartStack"
                },
                {
                    key: "account",
                    title: "Conta",
                    relatedScreens: "loginScreen",
                    isVisible: true,
                    action: "goToScreen",
                    link: "AuthStack"
                },
            ]
        },
        {
            key: "productCardsSettings",
            title: "Cards de produtos",
            showCardActions: false,
            options: {
                showBorder: true,
                showOldPrice: true,
                showPricesOnSameLine: false,
                showInstallments: true,
                titleLinesNumber: "2",
                textAlign: "left",
                imageShape: "square",
                resizeMode: "fit"
            }
        },
        {
            key: "colors",
            title: "Cores gerais",
            showCardActions: false,
            options: {
                buttonColor: "#00f",
                buttonTextColor: "#fff",
                installmentsColor: "#000",
                oldPriceColor: "#000",
                priceColor: "#000",
                titleColor: "#000"
            }
        },
        {
            key: "whatsAppButton",
            title: "Botão do WhatsApp",
            showCardActions: false,
            hideOnScreens: [         
                "Product",
                "CartStack",
                "ContactStack"
            ],
            options: {
                areaCode: '',
                phoneNumber: '',
                position: 'bottomRight',
                showButton: false
            }
        },
        {
            key: "wishlist",
            title: "Lista de Desejos",
            showWishlist: false,
            options: {
                iconColor: '#07005a',
                iconPositionInProductCard: 'right|bottom',
                iconPositionInProductScreen: 'right|bottom',
                showIconBackground: true,
                showIconInProductCard: true,
                showIconInProductScreen: true
            }
        }
    ]
};

export default createReducer(themeReducerDefaultState, {
    SET_THEME: (state, action) => {
        return {...themeReducerDefaultState, ...action.payload}
    },
    SET_AREA: (state, action) => {
        state.areas[action.areaIndex] = action.data;
    },
    SET_SECTION: (state, action) => {
        if(action.tabIndex  === 0) {
            state.areas[action.areaIndex].sections[action.sectionIndex] = action.data
        } else {
            state.settings[action.sectionIndex] = action.data
        }
    },
    DELETE_SECTION: (state, action) => {
        if(action.tabIndex  === 0) {
            state.areas[action.areaIndex].sections.splice(action.sectionIndex, 1);
        } else {
            state.sections.splice(action.sectionIndex, 1);
        }
    },
    ADD_SECTION_TO_AREA: (state, action) => {
        state.areas[action.areaIndex]['sections'].push(action.data)
    }
})

export const getSelectedArea = (state) => {
    const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;
    return state.ThemeReducer.areas[selectedAreaIndex];
}

export const getSelectedSection = (state) => {
    const selectedAreaIndex = store.getState().NavigationReducer.selectedAreaIndex;
    const selectedTabIndex = store.getState().NavigationReducer.selectedTabIndex;
    const selectedSectionIndex = store.getState().NavigationReducer.selectedSectionIndex;

    // If the selectedTabIndex is 0, we are dealing with a section inside the areas array
    if(selectedTabIndex === 0) {
        return state.ThemeReducer.areas[selectedAreaIndex].sections[selectedSectionIndex];
    }

    // If the selectedTabIndex is 1, we are dealing with a section insede the settings array
    return state.ThemeReducer.settings[selectedSectionIndex];
}