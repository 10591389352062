import _ from 'lodash';
import { FEATURES, SCREEN_OPTIONS } from './constants';
import appInfoService from '../_helpers/appInfoService';

export const getActiveMissingFeatures = (missingFeatures, theme) => {
    if (_.isEmpty(missingFeatures)) {
        return {};
    }

    appInfoService.init();
    let activeMissingFeatures = { android: [], ios: [] };

    // For each feature and platform on the missingFeatures array, check 
    // if it is active on this theme/store or not.
    for (let platform in missingFeatures) {
        missingFeatures[platform].forEach(missingFeature => {
            // We need to check for a missing feature only if the app is
            // published on the corresponding platform
            if (appInfoService.isAppPublished(platform)) {
                if (missingFeature === FEATURES.WISHLIST) {
                    const wishlistSettings = theme.settings.find(s => s.key === 'wishlist');
                    if (wishlistSettings && wishlistSettings.showWishlist) {
                        activeMissingFeatures[platform].push(FEATURES.WISHLIST);
                    }
                }

                if (missingFeature === FEATURES.CATEGORY_TITLE_COLOR_CHANGE) {
                    const categoryScreenArea = theme.areas.find(a => a.key === 'categoryScreen');
                    const categorySettings = _.get(categoryScreenArea, 'sections', []).find(s => s.key === 'categorySettings');

                    let titleBackgroundColor = _.get(categorySettings, 'options.titleBackgroundColor', '#000000');
                    let titleFontColor = _.get(categorySettings, 'options.titleFontColor', '#ffffff');

                    if (titleBackgroundColor !== '#000000' || titleFontColor !== '#ffffff') {
                        activeMissingFeatures[platform].push(FEATURES.CATEGORY_TITLE_COLOR_CHANGE);
                    }
                }

                if (missingFeature === FEATURES.SHIPPING_CALCULATOR) {
                    const productDetailsArea = theme.areas.find(a => a.key === 'productDetailsScreen');
                    const productDetailsSettings = _.get(productDetailsArea, 'sections', []).find(s => s.key === 'productDetailsSettings');
                    const productOptions = _.get(productDetailsSettings, 'options', {});

                    const cartScreenArea = theme.areas.find(a => a.key === 'cartScreen');
                    const cartScreenSettings = _.get(cartScreenArea, 'sections', []).find(s => s.key === 'cartScreenSettings');
                    const cartOptions = _.get(cartScreenSettings, 'options', {});

                    if (productOptions?.showShipping || cartOptions?.showShipping) {
                        activeMissingFeatures[platform].push(FEATURES.SHIPPING_CALCULATOR);
                    }
                }

                if (missingFeature === FEATURES.STATUS_BAR_FONT_COLOR) {
                    const headerSettings = theme.settings.find(s => s.key === 'header');
                    const headerOptions = _.get(headerSettings, 'options', {});
                    
                    if (headerOptions.statusBarTextColor != '#fff') {
                        activeMissingFeatures[platform].push(FEATURES.STATUS_BAR_FONT_COLOR);
                    }
                }

                if (missingFeature === FEATURES.HEADER_VISIBLE) {
                    const headerSettings = theme.settings.find(s => s.key === 'header');
                    const headerOptions = _.get(headerSettings, 'options', {});
                    
                    if (headerOptions.isVisible == false) {
                        activeMissingFeatures[platform].push(FEATURES.HEADER_VISIBLE);
                    }
                }

                if (missingFeature === FEATURES.MENU_ITEMS) {
                    const drawer = theme.settings.find(s => s.key === 'drawerMenu');
                    const drawerContent = _.get(drawer, 'content', []);
                    const tabBar = theme.settings.find(s => s.key === 'tabBar');
                    const tabBarContent = _.get(tabBar, 'content', []);

                    let screenChanged = drawerContent.length != 6 || tabBarContent.length != 6 ? true : false;

                    const actionChanged = drawerContent.find(x => x.action != 'goToScreen') 
                                        || tabBarContent.find(x => x.action != 'goToScreen')

                    for(const item of drawerContent){
                        const defaultScreen = SCREEN_OPTIONS.find(x => x.key == item.key);
                        const defaultScreenKeys = Object.keys(defaultScreen);

                        for(const key of defaultScreenKeys){
                            if(item[key] != defaultScreen[key] && key != 'relatedScreens'){
                                screenChanged = true
                            }
                        }
                    }

                    for(const item of tabBarContent){
                        const defaultScreen = SCREEN_OPTIONS.find(x => x.key == item.key);
                        const defaultScreenKeys = defaultScreen ? Object.keys(defaultScreen) : null

                        if(defaultScreenKeys){
                            for(const key of defaultScreenKeys){
                                if(item[key] != defaultScreen[key] && key != 'relatedScreens'){
                                    screenChanged = true
                                }
                            }
                        }
                    }
                    
                    if (actionChanged || screenChanged) {
                        activeMissingFeatures[platform].push(FEATURES.MENU_ITEMS);
                    }
                }
            }
        });
    }

    // Remove empty properties from activeMissingFeatures object
    activeMissingFeatures = _.omitBy(activeMissingFeatures, _.isEmpty);

    return activeMissingFeatures;
}