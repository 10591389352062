import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Select } from '@material-ui/core';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import { PLATFORMS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  helperText: {
    marginBottom: theme.spacing(3),
    marginTop: -8
  }
}));

export default function ProductCardsSettings({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.StoreReducer.platform);

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={_.isNil(data.options.showBorder) ? false : data.options.showBorder}
                        color="primary"
                        onChange={() => handleChange('options.showBorder', !data.options.showBorder)} 
                    />
                }
                label="Exibir borda"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={_.isNil(data.options.showOldPrice) ? false : data.options.showOldPrice}
                        color="primary"
                        onChange={() => handleChange('options.showOldPrice', !data.options.showOldPrice)} 
                    />
                }
                label="Exibir preço antigo"
            />

            {data.options.showOldPrice &&
              <FormControlLabel
                  control={
                      <Checkbox
                          checked={_.isNil(data.options.showPricesOnSameLine) ? false : data.options.showPricesOnSameLine}
                          color="primary"
                          onChange={() => handleChange('options.showPricesOnSameLine', !data.options.showPricesOnSameLine)} 
                      />
                  }
                  label="Exibir preços na mesma linha"
              />
            }

            {platform === PLATFORMS.TRAY &&
              <FormControlLabel
                control={
                    <Checkbox
                      checked={_.isNil(data.options.showInstallments) ? false : data.options.showInstallments}
                      onChange={() => handleChange('options.showInstallments', !data.options.showInstallments)}
                      color="primary"
                    />
                }
                label="Exibir opções de parcelamento"
              />
            }

            <FormControl fullWidth className={classes.formControl} style={{marginTop: 12}}>
                <InputLabel>Número de linhas disponíveis para o título</InputLabel>
                <Select
                    native
                    value={data.options.titleLinesNumber ||  ''}
                    onChange={(e) => handleChange('options.titleLinesNumber', e.target.value)} 
                    label="Formato da imagem"
                >
                    <option value='1'>1 linha</option>
                    <option value='2'>2 linhas</option>
                    <option value='3'>3 linhas</option>
                    <option value='4'>4 linhas</option>
                    <option value='5'>5 linhas</option>
                </Select>
                <FormHelperText>Caso o título do produto ocupe mais espaço do que o número de linhas selecionado ele será cortado e serão acrescentado reticências no seu final</FormHelperText>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Alinhamento dos textos</InputLabel>
                <Select
                    native
                    value={data.options.textAlign || ''}
                    onChange={(e) => handleChange('options.textAlign', e.target.value)}
                >
                    <option value='left'>Alinhar à esquerda</option>
                    <option value='center'>Centralizar</option>
                    <option value='right'>Alinhar à direita</option>
                </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Formato da imagem</InputLabel>
                <Select
                    native
                    value={data.options.imageShape ||  ''}
                    onChange={(e) => handleChange('options.imageShape', e.target.value)} 
                    label="Formato da imagem"
                >
                    <option value='square'>Quadrado</option>
                    <option value='portrait'>Retrato</option>
                </Select>
            </FormControl>

            <FormControl fullWidth  className={classes.formControl}>
                <InputLabel>Dimensionamento da imagem</InputLabel>
                <Select
                    native
                    value={data.options.resizeMode ||  ''}
                    onChange={(e) => handleChange('options.resizeMode', e.target.value)} 
                    label="Posicão do botão"
                >
                    <option value='fit'>Ajustar</option>
                    <option value='fill'>Preencher</option>
                </Select>
                <FormHelperText>Selecionando <b>Ajustar</b>, a imagem irá se encaixar dentro do formato escolhido mantendo a sua proporção original. Selecionando <b>Preencher</b>, a imagem será redimensionada para ocupar todo o espaço do formato escolhido.</FormHelperText>
            </FormControl>

          </CardContent>
        </Card>
    </main>
  );
}