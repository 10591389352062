const devConfig = {
    MOBILIZEI_API_BASE_URL: "https://api-dev.mobilizei.com.br/v1"
  }
  
  const prodConfig = {
    MOBILIZEI_API_BASE_URL: "https://api.mobilizei.com.br/v1"
  }
  
  const config = process.env.REACT_APP_ENV === 'prod' ? prodConfig : devConfig;
  
  export default config;