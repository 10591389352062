import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ColorPicker from '../../ColorPicker'
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import ImageUploader from '../../ImageUploader';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%'
  },
  helperText: {
    marginTop: -8
  },
  select: {
    width: '100%'
  }
}));

export default function Header({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleUploadSuccess = (uploadData) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData = { ...newData, options: { ...newData.options, logo: uploadData } };
    dispatch(themeActions.setSelectedSection(newData));
  }

  const checkStatusBarTextColor = () => {
    if (!data.options.statusBarTextColor || data.options.statusBarTextColor == "#fff" || data.options.statusBarTextColor == "#fff") {
      return "#fff"
    } else {
      return "#000"
    }
  }

  return (
    <main className={classes.content}>
      <Typography variant="overline" display="block" className={classes.contentTitle}>
        Configurações
      </Typography>

      <Card elevation={1} square>
        <CardContent>

          <FormControlLabel
            control={
              <Checkbox
                checked={_.isNil(data.options.isVisible) 
                  ? handleChange('options.isVisible', true) : data.options.isVisible}
                onChange={() => handleChange('options.isVisible', !data.options.isVisible)}
                color="primary"
              />
            }
            label="Exibir cabeçalho"
          />

          {data.options.isVisible && (
            <>
              <ColorPicker
                label='Cor do texto'
                initialColor={data.options.textColor}
                onChange={(color) => handleChange('options.textColor', color)}
              />

              <ColorPicker
                label='Cor de fundo'
                initialColor={data.options.backgroundColor}
                onChange={(color) => handleChange('options.backgroundColor', color)}
              />

              <ColorPicker
                label='Cor dos ícones'
                initialColor={data.options.iconColor}
                onChange={(color) => handleChange('options.iconColor', color)}
              />

              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Cor dos ícones da barra de status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className={classes.select}
                  value={checkStatusBarTextColor()}
                  onChange={(event) => handleChange('options.statusBarTextColor', event.target.value)}
                >
                  <MenuItem value="#fff">Branco</MenuItem>
                  <MenuItem value="#000">Preto</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={_.isNil(data.options.showCartIcon) ? false : data.options.showCartIcon}
                    onChange={() => handleChange('options.showCartIcon', !data.options.showCartIcon)}
                    color="primary"
                  />
                }
                label="Exibir ícone do carrinho"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={_.isNil(data.options.showLogo) ? false : data.options.showLogo}
                    onChange={() => handleChange('options.showLogo', !data.options.showLogo)}
                    color="primary"
                  />
                }
                label="Exibir logo"
              />
              <FormHelperText className={classes.helperText}>Ao marcar esta opção o título da tela no cabeçalho será substituido por uma imagem</FormHelperText>

              {data.options.showLogo &&
                <>
                  <ImageUploader
                    imageUrl={_.get(data, 'options.logo.url', null)}
                    label='Logo'
                    onUploadSuccess={handleUploadSuccess}
                  />
                  <FormHelperText>Tamanho recomendado: 420 x 300 px</FormHelperText>
                </>
              }
            </>
          )}
        </CardContent>
      </Card>


    </main>
  );
}