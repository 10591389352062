import { store } from '../../../index';
import config from '../../_helpers/config';
import { authWixService } from "./auth.wix.service";

export const apiFetcher = (path, init, attempt = 1, returnHeaders = false) => {
  const at = store.getState().UserReducer.user.store.accessToken;

  let url = `${config.MOBILIZEI_API_BASE_URL}/platforms/wix/api_forward/${path}`;
  let options = init;

  let headers = new Headers();
  headers.append('Authorization', at);
  headers.append('User-Agent', 'Coopers (suporte@coopers.pro)');
  headers.append('Content-Type', 'application/json');
  options['headers'] = headers;
  
  return fetch(url, init).then(response => {
    if (response.status === 204) {
      return;
    }
    return response.json().then(data => {
      if (!response.ok) {
        if ((response.status === 403 || response.status === 401 || response.status === 428) && attempt <= 3) {
          return authWixService.refreshAccessToken()
            .then(storeData => {
              store.dispatch({
                type: "SET_USER_ACCESS_TOKEN",
                payload: storeData.accessToken
              })
              return apiFetcher(path, init, attempt + 1, returnHeaders);
            })
            .catch(() => {
              return;
            });
        }
        const error = data || response.statusText;
        return Promise.reject(error);
      }

      if(returnHeaders) {
        return { data, headers: response.headers }
      } else {
        return data;
      }
    });
  });
};