import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollContainer from 'react-indiana-drag-scroll'

const useStyles = makeStyles(() => ({
  root: props =>({
    cursor: 'grab',
    display: props.isVisible ? 'block' : 'none',
    margin: '10px 0',
    width: '100%'
  }),
  content: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      width: '100%'
  },
  title: props =>({
    alignSelf: 'flex-start',
    color: props.options.titleColor,
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    padding: '10px 5px 0 5px',
    textAlign: props.options.textAlign
  }),
  subtitle: props =>({
    alignSelf: 'flex-start',
    color: props.options.subtitleColor,
    fontSize: 15,
    margin: 0,
    padding: '0 5px',
    textAlign: props.options.textAlign
  }),
  imageWrapper: props =>({
    alignItems: 'center',
    borderRadius: props.options.imageShape === 'round' ? '50%' : 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '4px 4px 0 4px',
    minWidth: 120,
    overflow: 'hidden',
    position: 'relative'
  }),
  image: props =>({
    objectFit: 'cover',
    height: props.options.imageShape === 'rectangle' ? 70 : 120,
    width: props.options.imageShape === 'rectangle' ? 145 : 120,
  })
}));

export default function ImageCarousel({ data }) {
  const classes = useStyles(data);

  return (
    <div className={classes.root}>
        <p className={classes.title}>{data.options.title}</p>
        <p className={classes.subtitle}>{data.options.subtitle}</p>

        <ScrollContainer className={classes.content}>
            {data.content.map((image, index) => {
              return (
                <div className={classes.imageWrapper} key={`ic-img-${index}`}>
                    <img 
                        alt="banner"
                        className={classes.image} 
                        src={image.url ? image.url : require('../../../Editor/_assets/images/image_placeholder_350_350.png')} 
                    />
                </div>
              )
            })}
        </ScrollContainer>
    </div>
  );
}