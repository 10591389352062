import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(() => ({
    root: {
        alignItems: 'center',
        cursor: 'grab',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        padding: '20px 0',
        width: '100%'
    },
    icon: {
        fontSize: 30,
        margin: '0 15px'
    },
    title: {
        fontSize: 12
    },
    content: {
        fontSize: 16
    }
}));

export default function ContactCard({ contact }) {
    const classes = useStyles();

    const getContactIcon = () => {
        switch(contact.contactType) {
            case 'email':
                return <FontAwesomeIcon icon={['fas', 'envelope']} className={classes.icon} />
            case 'phone':
                if(contact.isWhatsapp) {
                  return <FontAwesomeIcon icon={['fab', 'whatsapp']} className={classes.icon} />
                }
                return <FontAwesomeIcon icon={['fas', 'phone-alt']} className={classes.icon} />
            case 'address':
                return <FontAwesomeIcon icon={['fas', 'map-marker-alt']} className={classes.icon} />
            default:
                return null;
        }
    }

    const getContent = () => {
        switch(contact.contactType) {
            case 'email':
                return contact.email;
            case 'phone':
                if(!_.isEmpty(contact.areaCode) || !_.isEmpty(contact.phoneNumber)) {
                    return `(${contact.areaCode || ''}) ${contact.phoneNumber || ''}`
                }
                return '';
            case 'address':
                return (
                    <>
                        <span>
                            {`${contact.address || ''}`} 
                            {!_.isEmpty(contact.addressNumber) ? ` - ${contact.addressNumber}` : ''}
                            {!_.isEmpty(contact.addressComplement) ? `- ${contact.addressComplement}` : ''}
                        </span>
                        <br/>
                        <span>
                            {!_.isEmpty(contact.neighbourhood) ? `${contact.neighbourhood} - ` : ''}
                            {`${contact.city || ''}`}
                            {!_.isEmpty(contact.state) ? ` - ${contact.state}` : ''}
                        </span>
                    </>
                )
            default:
                return null;
        }
    }

    return (
        <div className={classes.root}>
            <div>
                {getContactIcon()}
            </div>
            <div>
                <div className={classes.title}>{contact.title}</div>
                <div className={classes.content}>{getContent()}</div>
            </div>
        </div>
    );
}
