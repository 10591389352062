import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles(() => ({
    container: {
        cursor: 'pointer'
    },
    color: {
        border: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: 2,
        height: 19,
        marginRight: 8,
        width: 38
    },
    swatch: {
        flex: 1,
        flexDirection: 'row',
        padding: '5px',
        display: 'inline-block',
        cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    label: {
        fontSize: '1rem'
    }
}));

export default function ColorPicker({ label, initialColor, onChange }) {
  const classes = useStyles();
  const [color, setColor] = useState(initialColor ? initialColor : '#000');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleChange = (color) => {
    if(onChange) {
        onChange(color.hex);
    }
    setColor(color.hex)
  }

  return (
    <div>
        <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="center"
            onClick={() => setShowColorPicker(!showColorPicker)}
        >
            <div className={classes.color} style={{ backgroundColor: color }} />
            <p className={classes.label}>{label}</p>
        </Grid>

        { showColorPicker ? 
            <div className={classes.popover}>
                <div className={classes.cover} onClick={() => setShowColorPicker(false) }/>
                <ChromePicker color={color} disableAlpha onChange={(color) => handleChange(color)} />
            </div> : null 
        }
    </div>
  );
}