const storeReducerDefaultState = {
    categories: {},
    loadingCategories: true,
    platform: null
};

export default (state = storeReducerDefaultState, action) => {
    switch (action.type) {
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload,
                loadingCategories: false
            };
        case "CLEAR_CATEGORIES":
            return {
                ...state,
                categories: {}
            };       
        case "SET_PLATFORM":
            return {
                ...state,
                platform: action.payload
            };
        default:
            return state;
    }
};
  