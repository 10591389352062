import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: props =>({
        backgroundColor: props.options.buttonColor,
        bottom: 0,
        padding: '0 20px',
        position: 'sticky',
        width: '100%',
        zIndex: 99
    }),
    label: props =>({
        color: props.options.buttonTextColor,
        fontSize: 16,
        margin: 0,
        padding: '15px 0',
        textAlign: 'center'
    }),
}));

export default function BuyButtonArea({ colors }) {
  const classes = useStyles(colors);

  return (
    <div className={classes.root}>
        <p className={classes.label}>
            ADICIONAR AO CARRINHO
        </p>
    </div>
  );
};