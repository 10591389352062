import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        background: '#fff',
        minHeight: 50,
    },
    leftContent: {
        display: 'flex',
        background: '#e2e2e2',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerContent: { 
        justifyContent: 'center', 
        flex: 1,
        padding: 10
    },
    rightContent: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 10,
    },
    shippingTitle: {
        fontSize: 14,
        fontWeight: '600',
        textDecorationLine: 'underline',
        color: '#000'
    },
    shippingText: {
        fontSize: 12,
        color: '#000'
    },
    priceText: props => ({
        fontSize: 16,
        fontWeight: '600',
        color: props.options.priceColor
    }),
    fakeCheckbox: {
        border: `1px solid #fff`,
        height: 15,
        width: 15,
        borderRadius: 2,
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        fontSize: 20,
    }
}));

const ShippingCard = ({ data, colors, selectedOption, onSelect }) => {

    const classes = useStyles(colors);

    return (
        <div className={classes.container}>
            <div className={classes.leftContent}>
                {selectedOption 
                    ? <FontAwesomeIcon icon={['fas', 'check']} className={classes.icon} />
                    : <div className={classes.fakeCheckbox} />
                }
            </div>
            <div className={classes.centerContent}>
                <div className={classes.shippingTitle}>
                    {data.name}
                </div>
                <div className={classes.shippingText}>
                    {data.information}
                </div>
            </div>
            <div className={classes.rightContent}>
                <p className={classes.priceText}>
                    {data.displayPrice}
                </p>
            </div>
        </div>
    )
}

export default ShippingCard;