import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { themeActions } from '../../../../_actions/theme.actions';
import DragAndDropProvider from '../../../../_helpers/DragAndDropProvider';
import DraggableElement from '../../../../_helpers/DraggableElement';
import ContactAccordion from './ContactAccordion';
import update from 'immutability-helper';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  button: {
    marginBottom: 30,
    marginTop: 30,
  }
}));

export default function ContactScreenSettings({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addContact = () => {
    let newData = JSON.parse(JSON.stringify(data));
    newData['content'].push({ contactType: "email" })
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleContactAccordionMove = (dragIndex, hoverIndex) => {
    let contactsCopy = JSON.parse(JSON.stringify(data.content));
    const dragSection = contactsCopy[dragIndex];

    contactsCopy =
      update(contactsCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })

    let newData = { ...data, content: contactsCopy }

    dispatch(themeActions.setSelectedSection(newData));
  }

  const deleteContact = (contactIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData['content'].splice(contactIndex, 1);
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChange = (property, value, contactIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, `content[${contactIndex}][${property}]`, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Conteúdo
        </Typography>
        
        <div>
          <DragAndDropProvider>
            {data.content.map((contact, index) => (
              <DraggableElement hoverIndex={index} key={`dc-${index}`} onMove={handleContactAccordionMove}>
                <ContactAccordion 
                  contact={contact}
                  contactIndex={index} 
                  key={`ca-${index}`} 
                  onChange={handleChange}
                  onDelete={() => deleteContact(index)}
                />
              </DraggableElement>
            ))}
          </DragAndDropProvider>
        </div>

        <Grid container justify="center">
          <Button
            color="default"
            className={classes.button}
            onClick={() => addContact()}
            startIcon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
            variant="contained"
          >
            Adicionar novo contato
          </Button>
        </Grid>
    </main>
  );
}