import { store } from "../../../index";
import { authTrayService } from "./auth.service";
import { STORE_STATUS } from "../../_helpers/constants"
import config from '../../_helpers/config'

export const apiFetcher = (path, init, attempt = 1, returnHeaders = false) => {
  const at = store.getState().UserReducer.user.store.accessToken;
  const storeData = store.getState().UserReducer.user.store;
  const storeId = storeData.id;

  let url = `${config.MOBILIZEI_API_BASE_URL}/platforms/tray/api_forward/${storeId}${path}`;

  // If the Tray store is ON_TRIAL, we consider it will not have installed the Mobilizei
  // app. So we don't have the at and need to make the requests without it.
  if (storeData.status !== STORE_STATUS.ON_TRIAL) {
    url = url.includes("?")
      ? url + "&access_token=" + at
      : url + "?access_token=" + at;
  }

  return fetch(url, init).then(response => {
    if (response.status === 204) {
      return;
    }
    return response.json().then(data => {
      if (!response.ok) {
        if (response.status === 401 && attempt <= 3) {
          // handle 401 error
          return authTrayService.refreshAccessToken()
            .then(at => {
              store.dispatch({
                type: "SET_USER_ACCESS_TOKEN",
                payload: at
              })
              return apiFetcher(path, init, attempt + 1);
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }
        const error = data || response.statusText;
        return Promise.reject(error);
      }

      if (returnHeaders) {
        return { data, headers: response.headers }
      } else {
        return data;
      }
    })
  })
    .catch(error => {
      if (attempt <= 3) {
        return apiFetcher(path, init, attempt + 1);
      }

      return Promise.reject(error);
    });
};