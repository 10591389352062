import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActions } from '../_actions/navigation.actions';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Crop75Icon from '@material-ui/icons/Crop75';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#fff',
        paddingBottom: 15,
        paddingTop: 15
    },
    border: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12);'
    },
    dragIcon: {
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing'
        }
    },
    icon: {
        fontSize: '1.5rem',
        textAlign: 'center'
    },
    actionIcon: {
        fontSize: 20
    },
    materialIcon: {
      fontSize: '2rem',
      textAlign: 'center'
    }
}));

export default function SectionCard({ cardRef, dragIconRef, first, section, sectionIndex, onToogleCardVisibility }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getSectionIcon = () => {
    switch(section.key) {
        case 'banner':
            return <FontAwesomeIcon icon={['fas', 'image']} className={classes.icon} fixedWidth />;
        case 'categoriesCarousel':
            return <ViewColumnIcon className={classes.materialIcon} />
        case 'categorySettings':
            return <FontAwesomeIcon icon={['fas', 'cog']} className={classes.icon} fixedWidth />;
        case 'colors':
            return <FontAwesomeIcon icon={['fas', 'palette']} className={classes.icon} fixedWidth />;
        case 'contactScreenSettings':
            return <FontAwesomeIcon icon={['fas', 'cog']} className={classes.icon} fixedWidth />;
        case 'drawerMenu':
            return <FontAwesomeIcon icon={['fas', 'bars']} className={classes.icon} fixedWidth />;
        case 'header':
            return <Crop75Icon />;
        case 'imageCarousel':
            return <SettingsEthernetIcon className={classes.materialIcon} />
        case 'loginScreenSettings':
            return <FontAwesomeIcon icon={['fas', 'cog']} className={classes.icon} fixedWidth />;
        case 'productCardsSettings':
            return <DashboardIcon className={classes.materialIcon} />
        case 'productCarousel':
            return <ViewCarouselIcon className={classes.materialIcon} />
        case 'productDetailsSettings':
            return <FontAwesomeIcon icon={['fas', 'cog']} className={classes.icon} fixedWidth />;
        case 'productList':
            return <FontAwesomeIcon icon={['fas', 'tags']} className={classes.icon} fixedWidth />;
        case 'separator':
            return <FontAwesomeIcon icon={['fas', 'divide']} className={classes.icon} fixedWidth />;
        case 'slideshow':
            return <FontAwesomeIcon icon={['fas', 'images']} className={classes.icon} fixedWidth />;
        case 'socialNetworks':
            return <FontAwesomeIcon icon={['fas', 'thumbs-up']} className={classes.icon} fixedWidth />;
        case 'tabBar':
            return <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className={classes.icon} fixedWidth />;
        case 'textBlock':
            return <FontAwesomeIcon icon={['fas', 'align-left']} className={classes.icon} fixedWidth />;
        case 'flashText':
            return <FontAwesomeIcon icon={['fas', 'font']} className={classes.icon} fixedWidth />;
        case 'whatsAppButton':
            return <FontAwesomeIcon icon={['fab', 'whatsapp']} className={classes.icon} fixedWidth />;
        case 'wishlist':
            return <FontAwesomeIcon icon={['fas', 'heart']} className={classes.icon} fixedWidth />;
        case 'cartScreenSettings':
            return <FontAwesomeIcon icon={['fas', 'cog']} className={classes.icon} fixedWidth />;
        default:
            return null;
    }
  }

  return (
    <ListItem 
        button 
        classes={{ root: classes.root, divider: first ? classes.border : null }}
        divider
        onClick={() => dispatch(navigationActions.selectSection(sectionIndex))}
        ref={cardRef}
    >
        <ListItemIcon>
            {getSectionIcon()}
        </ListItemIcon>
        <ListItemText primary={section.title} />
        {section.showCardActions &&
            <ListItemSecondaryAction>
                <IconButton edge="end" onClick={onToogleCardVisibility}>
                    { section.isVisible ? 
                    <FontAwesomeIcon icon={['fas', 'eye']} className={classes.actionIcon} fixedWidth /> : 
                    <FontAwesomeIcon icon={['fas', 'eye-slash']} className={classes.actionIcon} fixedWidth /> 
                    }
                </IconButton>
                <IconButton edge="end" className={classes.dragIcon} ref={dragIconRef}>
                    <FontAwesomeIcon icon={['fas', 'grip-vertical']} className={classes.actionIcon} fixedWidth />
                </IconButton>
            </ListItemSecondaryAction>
        }
    </ListItem>
  );
}
