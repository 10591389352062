import React from "react";
import _, { isEmpty } from 'lodash';
import { makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(() => ({
  card: {
    margin: '10px 10px',
    padding: '0px 15px',
    background: '#fff'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    borderRadius: 3,
    shadowColor: "#ccc",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  imageContainer: {
    width: "30%",
  },
  iconContainer: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: '1px solid #eee',
    width: 30,
    height: 30,
    position: "relative",
    top: 8,
    left: -7,
    borderRadius: 25,
    shadowColor: "#ccc",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1
  },
  icon: {
    color: "#909090",
    fontSize: 10
  },
  image: {
    border: '1px solid #ccc',
    width: 80,
    height: 80,
    resizeMethod: "resize",
    resizeMode: "contain",
    padding: 10
  },
  quantitySelectorContainer: {
    display: 'flex',
    border: '1px solid #ccc',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    width: 90,
    height: 30,
    borderRadius: 4
  },
  minusButtonDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "30%",
    height: 30,
    borderRight: '1px solid #ccc',
    backgroundColor: "#ededed"
  },
  plusButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "30%",
    height: 30,
    borderLeft: '1px solid #ccc',
  },
  quantity: {
    width: "40%"
  },
  quantityText: {
    color: "#909090",
    fontSize: 12,
    textAlign: "center",
  },
  quantitySelectorText: {
    color: "#909090",
    textAlign: "center",
  },
  infoContainer: {
    marginTop: 10,
    width: "65%",
  },
  productTitle: props => ({
    fontSize: 15,
    color: props.options.titleColor
  }),
  price: props => ({
    fontSize: 16,
    textAlign: "right",
    color: props.options.priceColor
  }),
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2px 0',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const CartItem = ({ colors, product }) => {

    const classes = useStyles(colors);

    if(!product){
      return <div></div>
    }

    return (
      <div className={classes.card}>
        <div className={classes.itemContainer}>
          <div className={classes.imageContainer}>
            <div className={classes.iconContainer}>
              <FontAwesomeIcon icon={['fas', 'trash']} className={classes.icon} />
            </div>

            {!isEmpty(product) && !isEmpty(product?.images) 
              ? <img 
                src={product?.images[0].imageUrl}
                className={classes.image}
              />
              : <img 
              src={require('../../../Editor/_assets/images/image_placeholder_350_350.png')}
                className={classes.image}
              />
            }
          </div>
          
          <div className={classes.infoContainer}>
            <p className={classes.productTitle}>
              {product.name}
            </p>
          </div>
        </div>
        <div className={classes.bottom}>
          <div className={classes.quantitySelectorContainer}>
            <div className={classes.minusButtonDisabled}>
              <p className={classes.quantitySelectorText}>-</p>
            </div>
            <div className={classes.quantity}>
              <p className={classes.quantityText}>{1}</p>
            </div>
            <div className={classes.plusButton}>
              <p className={classes.quantitySelectorText}>+</p>
            </div>
          </div>
          <p className={classes.price}>
              {product.displayPrice}
          </p>
        </div>
      </div>
    );
}

export default CartItem;