import { store } from "../../../index";
import _ from "lodash";

const mapWixCategory = category => {
  let mappedCategory = {
    id: category.id,
    name: _.get(category, 'name', ''),
    parentId: '',
    breadcrumb: _.get(category, 'name', ''),
    subcategories: []
  }

  return mappedCategory;
}

const sortCategories = (categories) => {

  const categoryOrder = _.get(store.getState().SettingsReducer, 'generalSettings.categoryOrder', 'asc');

  switch (categoryOrder) {
    // Sort subcategories by name
    case 'asc':
      categories.sort((a, b) => { return a.name.localeCompare(b.name) });
      break;

    // Sort subcategories by name
    case 'desc':
      categories.sort((a, b) => { return b.name.localeCompare(a.name) });
      break;

    default:
      // Nothing
      break;
  }

  return categories;
}

export const mapWixCategories = (categories, categoryIdsToHide = []) => {
  let mappedCategories = categories.reduce((result, category) => {
    if (!categoryIdsToHide.includes(category.id)) {
      result.push(mapWixCategory(category));
    }

    return result;
  }, []);

  return sortCategories(mappedCategories);
};

export const mapWixProduct = product => {
  let mappedProduct = {};

  mappedProduct["id"] = product.id;
  mappedProduct["name"] = _.get(product, 'name', '');
  mappedProduct["brand"] = product.brand;
  mappedProduct["description"] = _.get(product, 'description', '').replace(/[\n\r]/g, "");
  mappedProduct["url"] = product.productPageUrl.base;
  mappedProduct["manageVariants"] = product.manageVariants;

  mappedProduct["additionalInfo"] = product.additionalInfoSections;

  const hasWixPromotinalPrice = product.discount.type === "NONE" ? false : true;

  // Price variables to help setting other properties. We are always going
  // to have at least one variant, so we get the price from the first one.
  const productPriceData = product.priceData;

  const wixPrice = isNaN(parseFloat(productPriceData.price))
    ? 0
    : parseFloat(productPriceData.price);
  const wixPromotionalPrice = isNaN(parseFloat(productPriceData.discountedPrice))
    ? 0
    : parseFloat(productPriceData.discountedPrice);

  // Set product prices
  mappedProduct["price"] = hasWixPromotinalPrice ? wixPromotionalPrice : wixPrice;
  mappedProduct["oldPrice"] = hasWixPromotinalPrice ? wixPrice : 0;

  // Set product display prices
  const productPriceDataFormatted = productPriceData.formatted;

  mappedProduct["displayPrice"] = hasWixPromotinalPrice
    ? productPriceDataFormatted.discountedPrice
    : productPriceDataFormatted.price
  mappedProduct["displayOldPrice"] = productPriceDataFormatted.price

  // Set the discount percentage
  if (product.discount.type === 'PERCENT') {
    mappedProduct["discountPercentage"] = product.discount.value;
  } else if (product.discount.type === 'AMOUNT') {
    mappedProduct["discountPercentage"] = ((100 * product.discount.value) / wixPrice).toFixed(0)
  }

  // Set product info booleans
  mappedProduct["hasPromotionalPrice"] = hasWixPromotinalPrice;
  mappedProduct["hasVariant"] = product.variants && product.variants.length > 0;

  // Set if we can show this product price
  const showPricesOnlyToLoggedUsers = _.get(store.getState().SettingsReducer, 'generalSettings.showPricesOnlyToLoggedUsers', false);
  mappedProduct["canShowPrice"] = () =>
    // If the showPricesOnlyToLoggedUsers is false, when can show the price
    !showPricesOnlyToLoggedUsers ||
    // OR if the showPricesOnlyToLoggedUsers is true and we have a logged user, we can show the price as well
    (showPricesOnlyToLoggedUsers && !!store.getState().CustomersReducer.loggedUserId);

  // Set the product stock (the value only exists when the trackInventory option is true in Wix)
  const stockTrack = product.stock.trackInventory;
  mappedProduct["stock"] = stockTrack ? product.stock.quantity : 999

  // Check if product is customizable
  mappedProduct["isCustomizable"] =
    product.customTextFields && product.customTextFields.length > 0
      ? true
      : false;

  // If product is customizable, get the customizable fields
  // Wix have only customizable text fields
  if (mappedProduct["isCustomizable"]) {
    mappedProduct["customizableFields"] = product.customTextFields.reduce(
      (result, element) => {
        const customTextField = {
          Name: element.title,
          Type: 'text',
          Value: 0,
          DisplayValue: "R$ 0,00",
          Mandatory: element.mandatory
        };

        result.push(customTextField);
        return result;
      },
      []
    );
  }

  // Map product images and videos to array
  const productMedia = product.media;

  mappedProduct["images"] =
    productMedia.items && productMedia.items.length > 0
      ? productMedia.items.reduce((result, element) => {
        if (element.mediaType === 'image') {
          result.push({
            imageUrl: element.image.url
          })
        }

        if (element.mediaType === 'video') {
          mappedProduct["videoUrl"] = element.video.files.url
          result.push({
            videoThumbnail: true,
            imageUrl: element.thumbnail.url
          })
        }

        return result
      }, [])
      : [];

  return mappedProduct;
};

const getImagesFromChoices = (product, choices) => {
  let imagesArray = []

  for(const item of Object.entries(choices)){
    const attributeName = item[0];
    const value = item[1];

    product.productOptions.map((item) => {
      if(item.name === attributeName){
        const choice = item.choices.find(x => x.description === value);

        if(choice && choice?.media?.items){
          const images = choice?.media?.items?.length > 0
            ? choice?.media?.items.reduce((result, element) => {
              if(element.mediaType === 'image'){
                result.push({
                  imageUrl: element.image.url
                })
              }
    
              if(element.mediaType === 'video'){
                result.push({
                  videoThumbnail: true,
                  imageUrl: element.thumbnail.url
                })
              }
              
              return result
            }, [])
            : []

          imagesArray = imagesArray.concat(images)
        }
      }
    })
  }

  return imagesArray
}

export const mapWixVariants = product => {
  let mappedVariants = [];
  const indexByAvailableVariant = _.get(store.getState().SettingsReducer, 'generalSettings.indexByAvailableVariant', false);

  const visibleVariants = product.variants.filter(item => item.variant.visible);

  visibleVariants.forEach(item => {
    const variant = item.variant;

    // Wix price variables to help setting other properties
    const wixPrice = isNaN(parseFloat(variant.priceData.price))
      ? 0
      : parseFloat(variant.priceData.price);
    const wixPromotionalPrice = isNaN(parseFloat(variant.priceData.discountedPrice))
      ? 0
      : parseFloat(variant.priceData.discountedPrice);

    const hasWixPromotinalPrice = wixPromotionalPrice < wixPrice ? true : false;

    // Set product prices
    const price = hasWixPromotinalPrice ? wixPromotionalPrice : wixPrice;
    const oldPrice = hasWixPromotinalPrice ? wixPrice : 0;

    // Set the display price
    const variantPriceDataFormatted = variant.priceData.formatted;

    const displayPrice = hasWixPromotinalPrice
      ? variantPriceDataFormatted.discountedPrice
      : variantPriceDataFormatted.price
    const displayOldPrice = variantPriceDataFormatted.price

    // Build the sku list
    let skuList = {}

    if (product.manageVariants) {
      for (const productOption of product.productOptions) {
        const attributeName = productOption.name

        if (skuList[attributeName] === null) {
          skuList[attributeName] = "";
        }
        skuList[attributeName] = item.choices[attributeName]
      }
    }

    // Set the variant stock (the value only exists when the trackQuantity option is true in Wix)
    const stockTrack = item.stock.trackQuantity;
    const stock = stockTrack ? item.stock.quantity : 999

    let isAvailable = false

    if (stockTrack && !isNaN(stock)) {
      isAvailable = stock > 0
    } else {
      isAvailable = item.stock.inStock
    }

    // Wix doesn't have variant-specific images, so this maps the product images and videos to array
    const productMedia = product.media;

    let images = []

    if (product.manageVariants) {
      images = getImagesFromChoices(product, item.choices);
    }

    // If the variant does not have an image, set the product images
    // as the variant images
    if (_.isEmpty(images)) {
      images =
        productMedia.items && productMedia.items.length > 0
          ? productMedia.items.reduce((result, element) => {
            if (element.mediaType === 'image') {
              result.push({
                imageUrl: element.image.url
              })
            }

            if (element.mediaType === 'video') {
              result.push({
                VideoThumbnail: true,
                imageUrl: element.thumbnail.url
              })
            }

            return result
          }, [])
          : [];
    }

    // if indexByAvaliableVariant is enable, variant only can be added if available
    const addVariant = indexByAvailableVariant ? isAvailable : true

    if (addVariant) {
      mappedVariants.push({
        Sku: skuList,
        Price: price,
        DisplayPrice: displayPrice,
        OldPrice: oldPrice,
        DisplayOldPrice: displayOldPrice,
        HasPromotionalPrice: hasWixPromotinalPrice,
        Id: item.id,
        Images: images,
        IsAvailable: isAvailable,
        Stock: stock,
        StockTrack: stockTrack
      });
    }
  });

  return mappedVariants;
}

// In Wix, when product.manageVariants is false, product.variants only has one variation, 
// even if it has size or color options, and does not have a specific ID for each variation.
// So, we map it differently, transforming product.variants into an array with all variations,
// with different IDs created by us.
// This works because, to add a product with false product.manageVariants,
// it is not necessary to enter the variation ID, so there is no problem in creating an ID to facilitate handling.
const mapWixNotManagedVariants = (product, mappedVariants) => {
  if (mappedVariants.length === 0) return []

  const baseVariant = mappedVariants[0]

  // Generate all choices combinations
  const combinations = generateChoicesCombinations(product.productOptions);

  // Create the variants array
  const variants = combinations.map(combination => {
    // Variant ID
    const choiceId = combination.map(item => Object.values(item)[0]).join('-');
    const id = baseVariant.Id + '-' + choiceId

    const choices = combination.reduce((acc, c) => {
      const [key, value] = Object.entries(c)[0];
      acc[key] = value;
      return acc;
    }, {});

    let variantImages = getImagesFromChoices(product, choices)

    return {
      ...baseVariant,
      Id: id,
      Sku: choices,
      Images: !_.isEmpty(variantImages) ? variantImages : baseVariant.Images
    };
  });

  return variants
}

const generateChoicesCombinations = (optionsList, prefix = []) => {
  if (optionsList.length === 0) {
    return [prefix];
  }

  const [currentOption, ...restOptions] = optionsList;
  return currentOption.choices.flatMap(choice =>
    generateChoicesCombinations(restOptions, [...prefix, { [currentOption.name]: choice.description }])
  );
};

export const mapWixVariantOptions = (product, hasDifferentVariantPrices) => {
  let mappedVariantOptions = {};

  product.variants.forEach(item => {
    const includePrice = hasDifferentVariantPrices
    const variantDisplayPrice = item.variant.priceData.formatted.discountedPrice;

    for (const productOption of product.productOptions) {
      const attributeName = productOption.name;

      if (_.isEmpty(attributeName)) {
        continue;
      }

      if (mappedVariantOptions[attributeName] === undefined) {
        let options = []

        productOption.choices.map((item) => {
          if (item.visible) {
            if (includePrice) {
              options.push({
                value: item.description,
                price: variantDisplayPrice
              })
            } else {
              options.push({
                value: item.description
              })
            }
          }
        });

        mappedVariantOptions[attributeName] = options
      }
    }
  })

  // // Sort variant options by name
  // for (const key in mappedVariantOptions) {
  //   mappedVariantOptions[key].sort((a,b) => { return a.value.localeCompare(b.value) });
  // }

  return mappedVariantOptions;
}

export const mapWixProductWithVariants = (product) => {
  let mappedProduct = mapWixProduct(product);
  let mappedVariants = mapWixVariants(product);


  if (!mappedProduct.manageVariants) {
    mappedVariants = mapWixNotManagedVariants(product, mappedVariants)
  }

  // Set product variants
  mappedProduct["variants"] = mappedVariants;

  // Indicates if these product variants have different 
  // prices between any of them
  mappedProduct["hasDifferentVariantPrices"] = false;

  let previousVariantPrice = null;
  mappedVariants.forEach(variant => {
    // Set the cheapest variant price as the mappedProduct
    // price and displayPrice
    if (variant.IsAvailable) {
      if (mappedProduct["price"] > variant.Price || mappedProduct["price"] === 0) {
        mappedProduct["price"] = variant.Price;
        mappedProduct["displayPrice"] = variant.DisplayPrice;
      }
    }

    // Check if the variants price are diferent
    if (!!previousVariantPrice && previousVariantPrice !== variant.Price) {
      mappedProduct["hasDifferentVariantPrices"] = true;
    }

    // Set the current variant price as previousVariantPrice
    // to the next loop
    previousVariantPrice = variant.Price;
  });

  // Set product visibility
  mappedProduct["isVisible"] = product.visible;

  // Set product availability
  mappedProduct["isAvailable"] = mappedProduct["variants"].some(variant => {
    return variant.IsAvailable;
  });

  // Set variants options
  mappedProduct["variantOptions"] = mapWixVariantOptions(product, mappedProduct["hasDifferentVariantPrices"]);

  return mappedProduct;
}

export const mapWixProductArrayWithVariants = (products) => {

  const hideUnavailableProducts = _.get(store.getState().SettingsReducer, 'generalSettings.hideUnavailableProducts', false);
  let mappedProducts = products.map(product => mapWixProductWithVariants(product))
  mappedProducts = mappedProducts.filter(product => product.isVisible);
  if (hideUnavailableProducts) {
    mappedProducts = mappedProducts.filter(product => product.stock.inventoryStatus === 'IN_STOCK');
  }
  return mappedProducts;
}