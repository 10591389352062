import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import { previewerNavigationActions } from '../../Editor/_actions/previewerNavigation.actions';
import { Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  drawerContent: props =>({
    backgroundColor: props?.options?.backgroundColor,
    height: '100%',
    overflowY: 'scroll',
    padding: '0 8px 30px 8px',
    position: 'absolute',
    top: 20,
    width: 300,
    zIndex: 100
  }),
  overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      height: 1000,
      position: 'absolute',
      top: 20,
      width: 1000,
      zIndex: 99
  },
  logo: {
    alignSelf: 'center',
    height: 'auto',
    marginBottom: 5,
    marginTop: 30,
    width: 168
  },
  icon: props =>({
    color: props?.options?.textColor,
    fontSize: 18,
    marginRight: 15
  }),
  title: props =>({
    color: props?.options?.textColor,
  }),
  divider: props =>({
    backgroundColor: props?.options?.textColor,
    margin: '0 15px'
  })
}));

export default function DrawerMenu() {
  const data = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'drawerMenu'));

  const classes = useStyles(data);
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector(state => state.PreviewerNavigationReducer.isDrawerOpen);
  const categories = useSelector(state => state.StoreReducer.categories);

  if(_.isNil(data) || _.isNil(data?.options)){
    return <></>
  }

  return (
    <div>
        <Slide direction="right" in={isDrawerOpen} mountOnEnter unmountOnExit>
            <div className={classes.drawerContent}>

              {data.options.showLogo &&
                <Grid container justify="center">
                  <img 
                    alt="logo" 
                    className={classes.logo} 
                    src={_.get(data, 'options.logo.url', require('../../Editor/_assets/images/image_placeholder_350_350.png'))}
                  />
                </Grid>
              }
  
              <List>
                {data.content.map((menuItem, index) => (
                    <ListItem key={`pdmi-${index}`} style={{ display: menuItem.isVisible ? 'flex' : 'none' }}>
                      {menuItem.icon && (
                        <FontAwesomeIcon icon={[menuItem.icon.style, menuItem.icon.name]} className={classes.icon} fixedWidth />
                      )}
                      <ListItemText primary={menuItem.title} className={classes.title} />
                    </ListItem>
                ))}
              </List>

              {data.options.showCategoriesList &&
                <>
                  <Divider className={classes.divider} />
                  
                  <List>
                    {!_.isEmpty(categories) &&
                      categories.map(category => {
                        return (
                          <ListItem key={`cat-${category.id}`}>
                            <ListItemText primary={category.name} className={classes.title} />
                          </ListItem>
                        )
                      })}
                  </List>
                </>
              }
            </div>
        </Slide>

        <Fade in={isDrawerOpen} mountOnEnter unmountOnExit>
            <div className={classes.overlay} onClick={() => dispatch(previewerNavigationActions.setDrawerState(false))}></div>
        </Fade>
    </div>
  );
}