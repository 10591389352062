import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteSectionButton from '../../../DeleteSectionButton';
import Configurations from './Configurations';
import Content from './Content';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  }
}));

export default function CategoriesCarousel({ data, showDeleteButton }) {
  const classes = useStyles();

  return (
    <main className={classes.content}>
        <Configurations data={data} />
        <Content data={data} />
        {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}