import React from "react";
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { PLATFORMS } from "../../../Editor/_helpers/constants";

const useStyles = makeStyles(() => ({
  container: props =>({
    textAlign: props.textAlign
  }),
  pricesWrapperSameLine: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  oldPrice: props =>({
    color: props.options.oldPriceColor,
    fontSize: 12,
    height: 20,
    margin: 0,
    textDecorationLine: "line-through"
  }),
  price: props =>({
    color: props.options.priceColor,
    fontSize: 16,
    margin: 0
  }),
  paymentOption: props =>({
    color: props.options.installmentsColor,
    fontSize: 10,
    margin: 0
  }),
}));

export default function PriceArea(props) {
  const classes = useStyles({...props.settings, ...props.colors});
  const platform = useSelector(state => state.StoreReducer.platform);

  return (
    <div className={classes.container}>

      <div className={props.settings.showPricesOnSameLine && props.settings.showOldPrice ? classes.pricesWrapperSameLine : null }>
        {/* Old price */}
        {props.settings.showOldPrice && 
          <p className={classes.oldPrice}>
            {props.hasPromotionalPrice ? props.displayOldPrice : ''}
          </p>
        }

        {/* Price */}
        <p className={classes.price}>
          {props.displayPrice}
        </p>
      </div>

      {/* Installments */}
      {platform === PLATFORMS.TRAY && props.settings.showInstallments && (
        <p className={classes.paymentOption}>
            {props.paymentOption}
        </p>
      )}

    </div>
  );
};