import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: props =>({
    backgroundColor: props.options.backgroundColor,
    display: props.isVisible ? 'block' : 'none',
    width: '100%'
  }),
  text: props =>({
    color: props.options.fontColor,
    fontSize: props.options.fontSize,
    margin: 0,
    padding: 8,
    textAlign: props.options.textAlign
  })
}));

export default function FlashText({ data }) {
  const fontSize = () => {
    switch(data.fontSize) {
      case 'small':
        return 12;
      case 'medium':
        return 14;
      case 'large':
        return 16;
      default:
        return 12;
    }
  }

  const classes = useStyles({...data, fontSize: fontSize()});

  return (
    <div className={classes.root}>
      <p className={classes.text}>{data.options.text}</p>
    </div>
  );
}