import { store } from '../../index';
import  { PLATFORMS } from '../_helpers/constants';
import { productsService as nps } from '../_services/nuvemshop/products.service';
import { productsService as tps} from '../_services/tray/products.service';
import { productsService as wps } from '../_services/wix/products.wix.service';
import { mapNuvemProduct, mapNuvemProductArrayWithVariants } from '../_helpers/mappers/nuvemshop';
import { mapTrayProduct, mapTrayProductArray } from '../_helpers/mappers/tray';
import _ from 'lodash';
import { mapWixProduct, mapWixProductArrayWithVariants } from '../_helpers/mappers/wix';

export const productsActions = {
    getProducts,
    getProductById,
    searchProducts
};

function getProducts(filters) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.getProducts;
        mapperMethod = mapNuvemProductArrayWithVariants;
    } else if(platform === PLATFORMS.TRAY) {
        queryMethod = tps.getProducts;
        mapperMethod = mapTrayProductArray;
    } else {
        queryMethod = wps.getProducts;
        mapperMethod = mapWixProductArrayWithVariants;
    }

    return () => {
        return queryMethod(filters).then(response => {
            let data = null;
            if(platform === PLATFORMS.NUVEMSHOP) { 
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.headers.get('x-total-count')
                }
            } else if(platform === PLATFORMS.TRAY) {
                data = {
                    products: mapperMethod(response.Products),
                    totalCount: _.get(response, 'paging.total', 0)
                }
            } else {
                data = {
                    products: mapperMethod(response.data.products),
                    totalCount: _.get(response, 'data.totalResults', 0)
                }
            }
            
            return data;
        })
    };
}

function getProductById(productId) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.getProductById;
        mapperMethod = mapNuvemProduct;
    } else if(platform === PLATFORMS.TRAY) {
        queryMethod = tps.getProductById;
        mapperMethod = mapTrayProduct;
    } else {
        queryMethod = wps.getProductById;
        mapperMethod = mapWixProduct;
    }

    return () => {
        return queryMethod(productId).then(response => {
            const data = PLATFORMS.WIX 
                ? mapperMethod(response.product)
                : mapperMethod(response)

            return data
        })
    };
}

function searchProducts(filters, query) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;
    if(platform === PLATFORMS.NUVEMSHOP) {
        queryMethod = nps.searchProducts;
        mapperMethod = mapNuvemProductArrayWithVariants;
    } else if(platform === PLATFORMS.TRAY){
        queryMethod = tps.searchProducts;
        mapperMethod = mapTrayProductArray;
    } else {
        queryMethod = wps.getProducts;
        mapperMethod = mapWixProductArrayWithVariants;
    }

    return () => {
        return queryMethod(filters, query).then(response => {
            let data = null;
            if(platform === PLATFORMS.NUVEMSHOP) { 
                data = {
                    products: mapperMethod(response.data),
                    totalCount: response.headers.get('x-total-count')
                }
            } else if(platform === PLATFORMS.TRAY) {
                data = {
                    products: mapperMethod(response.Products),
                    totalCount: _.get(response, 'paging.total', 0)
                }
            } else {
                data = {
                    products: mapperMethod(response.data.products),
                    totalCount: _.get(response, 'data.totalResults', 0)
                }
            }
            
            return data;
        })
    };
}