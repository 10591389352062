import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, InputLabel, Select, Typography } from '@material-ui/core';
import ColorPicker from '../../ColorPicker';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import { PLATFORMS } from '../../../_helpers/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function CartScreenSettings({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const platform = useSelector(state => state.StoreReducer.platform);

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>

       
        
        <Card elevation={1} square>
          <CardContent>
            {platform == PLATFORMS.TRAY && (
              <>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={_.isNil(data.options.showShipping) ? false : data.options.showShipping}
                            color="primary"
                            onChange={() => handleChange('options.showShipping', !data.options.showShipping)} 
                        />
                    }
                    label="Exibir cálculo de frete"
                />
              </>
            )}
          </CardContent>
        </Card>
    </main>
  );
}