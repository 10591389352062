import axios from "axios";
import { store } from "../../../index";
import _ from 'lodash';
import config from '../../_helpers/config'
import { mobilizeiUsersService } from "../mobilizei/users.service";

export const authTrayService = {
  refreshAccessToken
};

function refreshAccessToken() {
    const storeId = store.getState().UserReducer.user.store.id;

    return axios
        .post(
            `${config.MOBILIZEI_API_BASE_URL}/platforms/tray/auth/refresh`,
            { storeId }
        )
        .then(() => {
            return mobilizeiUsersService.getSelf().then(userData => {
                return _.get(userData, 'store.accessToken', '');
            })
        })
}